div.dataTables_filter input {
    border: transparent;
    background-color: transparent;
    border-bottom: 1px solid $charcoal-500;
    color: $black;
    @include font-size(14px);
    font-weight: 200;
    line-height: calculateRem(18px);
    vertical-align: bottom;
    &:focus {
        outline: none;
    }
}

a.dt-button {
	border: 0 !important;
	background: transparent !important;
	margin: 0 !important;
	color: $charcoal-600 !important;
}

.table-secondary th,
.table-secondary td {
	color: $white !important;
	font-weight: 700 !important;
	background-color: $charcoal-600 !important;
	@include font-size(12px);
	border-color: #dee2e6 !important;
	padding: 8px 10px !important;

	&.table-header-checkbox {
		width: 50px;
		padding-left: 0.4rem !important;
	}
}

.table-light th,
.table-light td {
	font-weight: 700 !important;
	@include font-size(12px);
	padding: 8px 10px !important;
	background-color: #a1a3a5;
}

td img { /*To prevent smushing of button images in tables*/
	max-width: none;
}

.dataTables_wrapper table.dataTable thead th, 
.dataTables_wrapper table.dataTable thead td,
.dataTables_wrapper table.dataTable.no-footer {
	border-bottom: solid 1px #dee2e6;
	border-top: none;
}

.dataTables_wrapper table.dataTable, 
.dataTables_wrapper table.dataTable thead td,
.dataTables_wrapper table.dataTable.no-footer
.dataTables_wrapper table.dataTable thead th, 
.dataTables_wrapper table.dataTable thead td,
.dataTables_wrapper table.dataTable.no-footer {
	box-sizing: border-box !important;
}

.dataTables_wrapper table.dataTable tfoot .table-secondary td {
	border-color: #DCDEE1;
}

.datatable-action-dropdown {
	width: 150px;
}

.datatable-action a {
	padding: 0.25rem 0.75rem;
}

.dataTables_wrapper .dataTable tr:hover .datatable-actions {
	opacity: 1;
}

.dataTables_wrapper .dataTable tbody tr:hover {
	background-color: #E6E6E6 !important;
}

.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dt-buttons {
	float: right !important;
	text-align: right !important;
}

/* ============ Grid styles ============ */
table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	/* Required to push footer below footer of tables */
	margin-bottom: 15px;
}

th, td {
	padding: 5px;
}

thead th, thead td, tfoot th, tfoot td {
	padding: 0.1em;
}

table.grid thead th, table.baregrid thead th {
	cursor: pointer;
}

table.grid thead th.sort-no, table.baregrid thead th.sort-no {
	cursor: auto;
}

table.grid-select tbody tr {
	cursor: pointer;
}

table.grid-select tbody tr:hover {
	background-color: #0F0;
}

td.right {
	text-align: right;
}

td.left {
	text-align: left;
}

table.subgrid, table.grid, table.baregrid {
	vertical-align: top;
	border-collapse: collapse;
	border-right-style: solid;
	border-left-style: solid;
	border-bottom-style: solid;
	border-top-style: solid;
	border-right-width: 1px;
	border-left-width: 1px;
	border-bottom-width: 1px;
	border-top-width: 1px;
	border-right-color: #666;
	border-left-color: #666;
	border-bottom-color: #666;
	border-top-color: #666;
	width: 100%;
}

table.subgrid tr.active, table.grid tr.active, table.baregrid tr.active
{
	background-color: #FF9 !important;
}

.activeOnHover:hover {
	background-color: #FF9 !important;
}

thead tr, tfoot tr {
	padding: 0.2em;
	text-align: left;
	background-color: $charcoal-600;
	background-repeat: repeat-x;
	color: #fff;
	font-weight: bold;
	font-style: normal;
	border-collapse: collapse;
	border-right-style: solid;
	border-left-style: solid;
	border-bottom-style: solid;
	border-top-style: solid;
	border-right-width: 1px;
	border-left-width: 1px;
	border-bottom-width: 1px;
	border-top-width: 1px;
	border-right-color: $charcoal-600;
	border-left-color: $charcoal-600;
	border-bottom-color: $charcoal-600;
	border-top-color: $charcoal-600;
}

tr.no-style {
	background: none;

	.table-borderless & {
		border: none;
	}

	th {
		vertical-align: middle;
		font-weight: 600;
	}

	color: initial;
}

.mbdatatable-top {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> .dataTables_filter {
		margin-left: auto;
	}

	.mbdatatable-title {
		margin-right: auto;

		h2 {
			margin-bottom: 0px;
		}
	}

	.mbdatatable-filter {
		order: 3;

		label {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;

		.mbdatatable-title {
			margin-bottom: 0.5rem;
		}
	}
}


.mbdatatable-top .dataTables_info {
	margin-bottom: 0;
}

.mbdatatable-bottom {
	overflow: auto;
	padding: 0 4px 4px 4px;
}

.mbdatatable-bottom .dataTables_length {
	margin-top: 10px;
}

.mbdatatable-bottom .dataTables_info {
	clear: none !important;
	margin: 0 0 0 8px;
}

.padetails thead tr {
	padding: 0.2em;
	text-align: left;
	background-color: #f4f4f4;
	background-repeat: repeat-x;
	color: #000;
	font-weight: bold;
	font-style: normal;
	border: none;
}

tbody td {
	text-align: left;
}

tbody tr.rowexpand {
	background-color: #FFFF64;
}

/* ========= Calendar Table Styles ========= */
table.calendar td {
	border: 1px solid black;
	font-size: 0.625em;
	font-weight: bold;
	line-height: 150%;
}

table.calendar th.heading {
	width: 1em;
	text-transform: uppercase;
	text-align: center;
	font-size: 0.625em;
	font-weight: bold;
	line-height: 110%;
	padding: 0 5px 0 0;
}

table.calendar td a {
	text-decoration: none;
}

table.calendar span.right {
	float: right;
}

table.white-grey tbody tr:nth-child(even) {
	background-color: #ddd;
}

/* jquery datatables processing bar */
.dataTables_wrapper .dataTables_processing {
	top: 0;
	left: 0;
	margin-left: 0;
	margin-top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.8;
	font-weight: bold;
	font-size: 1.2em;
}

.datatable-layer-block {
	position: absolute;
	top: 0;
	width: 100%;
	bottom:0;
	background: rgba(255,255,255,0.3);
	z-index: 1;
	display: block;
}

.dataTables_wrapper .dataTable .table_group_summary, 
.dataTables_wrapper .dataTable .table_group_summary:hover {
	background-color: #888 !important;
	color: $white !important;
}

table .ui-state-highlight {
	background: $yellow-light-3 !important;
}



/* CUSTOM CSS to allow collumn visibility options to show over flyout and modals
----------------------------------------------------------*/
body.flyout-open {
	.dt-button-collection {
		z-index: 70202 !important;
	}
	.dt-button-background {
		z-index: 70201 !important;
	}
}


/* CUSTOM CSS to compare items table
----------------------------------------------------------*/

table.details-compare {
    border-collapse: collapse;
    width:100%
}
table.details-compare td, table.details-compare th{
	border: 1px solid black;
	width:20%;
}
table.image-order-compare {
    width:100%
}
table.image-order-compare td, table.image-order-compare th {
	width: 20%;
}
table.image-order-compare td img{
	max-width:160px!important;
	max-height: 200px!important;
}
table.image-order-compare .item-comparison-image-wrapper{
	margin-left: auto;
    margin-right: auto;
    width: 50%;
}

/* Inventory Datatable "Processing" Loader
----------------------------------------------------------*/

#inventory .dataTables_wrapper .dataTables_processing {
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    font-weight: bold;
	font-size: 1.2em;
	z-index: 1000; 
}

/* Add Items Inventory DataTable "Processing" Loader
----------------------------------------------------------*/

#addItemsGrid_processing,
#myItemGrid_processing {
	margin-top: -7px !important;
}


/* Datatable responsive
----------------------------------------------------------*/

tr.child ul.dtr-details {
	width: 100%;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, 
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
	font-family: "Material Icons" !important;
	content: "navigate_next" !important; //dropdown arrow
	background-color: $charcoal-600 !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, 
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
	font-family: "Material Icons" !important;
	content: "expand_more" !important; //dropup arrow
	background-color: $charcoal-750 !important;
}

/* Datatables Sort replacement Images */

table.dataTable thead {

	.sorting_asc {
		background-image: url('/resources/images/dt_sort_asc_white.png') !important;
	}

	.sorting_desc {
		background-image: url('/resources/images/dt_sort_desc_white.png') !important;
	}
}

.mb-table-header-cell {
	white-space: nowrap;
}

.mb-table-footer.table-secondary th {
	@include font-size(14px);
}

.mb-table-row-header th,
.mb-table-row-header td {
	color: $white !important;
	background: $charcoal-500 !important;
}

.mb-table-row-with-input {
	height: 45px;
}

.nutrition-facts-table .nutrition-facts-row td {
	border-top: inherit;
}

#CouponsTable {
	.mb-coupon-details-link {
		@include font-size(14px);
	}
}

.overflow-options-cell {
	width: 50px;
}

.mb-overflow-btn-toggle {
	&::after {
		display: none;
	}

	td & {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.toggle-text-container:not(.text-truncate) {
	overflow-wrap: anywhere;
}