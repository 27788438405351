.insights-budget-overlap {
    position: absolute;
    width: 76%;
    top: 38px;
    height: 145px;
    z-index: 2;
    border: 2px solid;

    &.budget-overlap-small {
        @extend .insights-budget-overlap;
        width: 68%;
    }
}

.insights-date-picker-container {
    cursor: pointer;
}

.insights-date-picker {
    display: none;
    position: absolute;
    bottom: 30px;
}

.chart-side-tab {
    @include font-size(12px);
}

#foodBudgetWarningMessage {
    font-size: 0.8rem;
    color: $red;
    font-weight: bold;
}

#nonFoodBudgetWarningMessage {
    font-size: 0.8rem;
    color: $green;
    font-weight: bold;
}

#insightsIntroContainer {
    margin: -20px -15px;

    h2 {
        font-size: 1.6em;
    }
    h3 {
        font-size: 1.3em;
        margin-bottom: 0;
    }
    .insights-bg {
        background: url(https://cdn.martinbros.com/content/insights-background.jpg);
        background-size: cover;
        background-position: center center;
    }
    .insights-bg-center {
        color: $white;
    }
}

#foodPPDComparisonTableBody {
    tr {
        td:nth-child(even) {
            border-right: solid 1px #dee2e6;;
        }

        td:nth-child(-n+2) {
            border-right: none !important;
        }
    }
}

#foodPPDComparisonTableHead {
    td:nth-child(even) {
        border-right: solid 1px #dee2e6;;
    }

    td:nth-child(-n+2) {
        border-right: none !important;
    }
}


#foodPPDComparisonTableMonthHead {
    td {
        border-right: solid 1px #dee2e6;
        color: white;
    }

    td:nth-child(-n+1) {
        border-right: none !important;
    }
}

.chart-container {
    min-height: 375px;
}

.chart-loading {
    position: relative;

    &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 0.5);
        z-index: 1;
        top: 0;
        content: "Loading chart data...";
        display: flex;
        justify-content: center;
        align-items: center;
        color: $charcoal-400;
        @include font-size(18px);
        font-weight: 500;
    }
}