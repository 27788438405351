/* ============ Tool Tip Style ========= */
.qtip, .qtip {
	position: absolute;
	left: -28000px;
	top: -28000px;
	display: none;
	max-width: 280px;
	min-width: 50px;
	font-size: 10.5px;
	line-height: 12px;
	direction: ltr;
}

.qtip-content {
	position: relative;
	padding: 5px 9px;
	overflow: hidden;
	text-align: left;
	word-wrap: break-word;
}

.qtip-titlebar {
	position: relative;
	padding: 5px 35px 5px 10px;
	overflow: hidden;
	border-width: 0 0 1px;
	font-weight: bold;
}

.qtip-titlebar+.qtip-content {
	border-top-width: 0 !important;
}
/*! Add shadows to your tooltips in: FF3+, Chrome 2+, Opera 10.6+, IE5.5+, Safari 2+ */
.ui-tooltip-shadow {
	-webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.ui-tooltip-shadow .ui-tooltip-titlebar, .ui-tooltip-shadow .ui-tooltip-content
	{
	filter: progid:DXImageTransform.Microsoft.Shadow(Color='gray', Direction=135,
		Strength=3);
	-ms-filter:
		"progid:DXImageTransform.Microsoft.Shadow(Color='gray', Direction=135, Strength=3)";
	_margin-bottom: -3px; /* IE6 */
	margin-bottom: -3px; /* IE7 */
}

.ui-widget .ui-multiselect-filter input {
	font-family: Verdana, Arial, sans-serif;
	font-size: 14px;
}

.qtip-close {
	position: absolute;
	right: -9px;
	top: -9px;
	cursor: pointer;
	outline: medium none;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
}

.qtip-titlebar .qtip-close {
	right: 4px;
	top: 50%;
	margin-top: -9px;
}

* html .qtip-titlebar .qtip-close {
	top: 16px;
} /* IE fix */
.qtip-titlebar .ui-icon, .qtip-icon .ui-icon {
	display: block;
	text-indent: -1000em;
	direction: ltr;
	vertical-align: middle;
}

.qtip-icon, .qtip-icon .ui-icon {
	border-radius: 3px;
	text-decoration: none;
}

.qtip-icon .ui-icon {
	width: 18px;
	height: 14px;
	text-align: center;
	text-indent: 0;
	font: normal bold 10px/13px Arial, Helvetica, sans-serif;
	color: inherit;
	background: transparent none no-repeat -100em -100em;
}

.qtip-default {
	border-width: 1px;
	border-style: solid;
	border-color: #F1D031;
	font-size: 0.75em;
	line-height: 150%;
	background-color: #FFFFA3;
	color: #555;
}

.qtip-default .qtip-titlebar {
	background-color: #FFEF93;
}

.qtip-default .qtip-icon {
	border-color: #CCC;
	background: #F1F1F1;
	color: #777;
}

.qtip-default .qtip-titlebar .qtip-close {
	border-color: #AAA;
	color: #111;
}