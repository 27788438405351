.mb-stepper {

    .mb-stepper-step {
        position: relative;

        .stepper-circle {
            display: inline-block;
            content: " ";
            width: 32px;
            height: 32px;
            border-radius: 50%;
            color: $white;
            background: $blue;
            vertical-align: middle;
            text-align: center;
            line-height: 30px;

            em.material-icons {
                font-size: 20px;
                text-align: center;
                vertical-align: middle;
            }
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:not(:last-child):after {
            position: absolute;
            top: 2.4rem;
            left: 0.95rem;
            width: 1px;
            height: calc(100% - 30px);
            content: "";
            background-color: $gray;
        }

        &.step-complete {
            padding-bottom: 16px;

            .step-content {
                display: none;
            }
        }
    }

    .mb-stepper-step > span {
        vertical-align: middle;
    }

    .mb-stepper-step .step-content {
        margin-left: 54px;
        margin-top: 8px;
        padding-bottom: 16px;
    }

}