.laborleft {
	float: none;
	width: 95%;
	display: block;
}

.laborright {
	float: none;
	width: 95%;
	margin-top: 10px;
	margin-left: 0px;
	display: block;
}

.PricePH {
	margin: 0 !important;
}