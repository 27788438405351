.drag-drop-container {
    border: solid 1px $charcoal-300;

    .drag-drop-title {
        background: $charcoal-600;
        color: $white;
        @include font-size(13px);
        padding: 8px 10px;
        margin: 0;
        font-weight: 500;
        border-bottom: solid 2px $charcoal-300;
    }

    .mb-draggable-item {
        background: $white;
        border-bottom: solid 1px $charcoal-300;
        padding: 8px 0;
        &:nth-child(odd) {
			background-color: $surface-dark;
		}

    }

    .mb-drop-container {
        min-height: 100px;

        &:empty {
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: "Drag Items Here";
                color: $charcoal-300;
                @include font-size(18px);
                font-weight: 500;
            }
        }

        &.mb-droppable {
            background: $yellow-light-4 !important;

            .mb-draggable-item {
                background: transparent !important;
            }
        }

        &.mb-droppable-current {
            background: $yellow-light-3 !important;

            .mb-draggable-item {
                background: transparent !important;
            }
        }
    }
}