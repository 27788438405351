/* ==== Styles For Search In OE ==== */
.closeOutFlag {
	color: $white;
	background: rgb(38, 146, 199);
	font-size: 0.7em;
}

.cutFlag {
	background-color: $blue;
}

.piFlag {
	background-color: $blue;
	text-decoration: none;
}

.contractFlag {
	background-color: $blue-dark-1;
}

.inOrderFlag {
	color: $green !important;
}

.oetrend {
	font-size: 0.8em;
}

.oecoupon a {
    background-color: $yellow-dark-3;
	color: $white;
	padding: 2px;
}

.oe_on_menu {
	background-color: $blue-light-1;
	color: $white;
	position: relative;
}

.itemFlag,
.oeflag {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 0;
    @include font-size(12px);
    font-weight: 400;
    text-align: center;
    color: $white;
    white-space: nowrap;
    line-height: initial !important;

    &.featuredFlag,
    &.reviewedFlag  {
        background-color: $charcoal-700;
        color: #fff;
    }

    &.virtualFlag,
    &.offDayFlag {
        background-color: $purple;
    }

    &.subbedItemFlag {
        background-color: $purple-dark-4;
    }

    &.specialFlag {
        background-color: $teal;
    }

    &.breakItemFlag {
        background-color: $blue;
    }

    &.divertedFoodFlag {
        background-color: $blue-dark-2;
    }

    &.usdaFlag {
        background-color: $blue-dark-3;
    }

    &.contractFlag,
    &.onCurrentMenuFlag {
        background-color: $blue-dark-4;
    }

    &.autoAllocateFlag {
        background-color: $blue-light-1;
    }
    
    &.closeOutFlag {
        background-color: $indigo;
    }

    &.marketBasketFlag {
        background-color: $indigo-dark;
    }
    
    &.cutFlag,
    &.earlyReleaseFlag {
        background-color: $magenta;
    }
    
    &.inactiveFlag, 
    &.outofstockFlag {
        background-color: $red-dark-1;
    }
    
    &.couponFlag {
        background-color: $mb-gold-2;
        color: $black !important;
    }

    &.preferredFlag,
    &.iddsiFlag {
        background-color: $green-dark-2;
    }

    &.noiFlag {
        background-color: $green-dark-3;
    }

    &.nonMBItemFlag {
        background-color: $cyan;
    }

    &.mfrDiscontinuedFlag {
        background-color: #49549c;
    }

    &.menuFlag {
        background-color: $blue-light-1;
        cursor: pointer;
    }

    &.subProhibitedFlag {
        background-color: $orange;
    }
}



.couponFlag-itemSearch {
    background-color: $mb-gold-2;
    color: $black;
    position: relative;
}

.couponFlag-itemSearch-2 {
    background-color: $mb-gold-2;
    color: $black;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 0 0 40px 0;
}

.gs1ReviewStatusFlag {
    border:1px solid $yellow-dark-1;
    background-color: $yellow-light-2;
    color: $charcoal-700;
    line-height: 18px;
    @include font-size(14px);
}

.gs1DeleteStatusFlag {
    border:1px solid $red-light-1;
    background-color: $red-light-1;
    color: $white;
    line-height: 18px;
    @include font-size(14px);
}

.itemSearchCoupon {
    color: $black;
    
    &:hover {
        color: $black;
        text-decoration: none;
        cursor: pointer;
    }
}

%flagPseudoStyle {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 12px;
} 

.flag-wrapper {
    flex-wrap: wrap;
    
    &.left {
        padding-right: 12px;
        
        .itemSearchFlag {
            &:not(:first-child) {
                &::after {
                    @extend %flagPseudoStyle;
                    transform: skew(-25deg);
                    right: -6px;
                }

                .itemFlag {
                    padding-left: 18px;
                }
            }

            &:first-child {
                &::after {
                    @extend %flagPseudoStyle;
                    transform: skew(-25deg);
                    right: -6px;
                }

                .itemFlag {
                    padding-left: 18px;
                }
            }
        }
    }

    &.right {
        padding-left: 12px;
        
        .itemSearchFlag {
            &:not(:last-child) {
                
                &::before {
                    @extend %flagPseudoStyle;
                    transform: skew(25deg);
                    left: -6px;
                }

                .itemFlag {
                    padding-right: 18px;

                    &.popover-flag {
                        padding-right: 12px;
                    }
                }
            }

            &:last-child {
                &::before {
                    @extend %flagPseudoStyle;
                    transform: skew(25deg);
                    left: -6px;
                }

                .itemFlag {
                    padding-right: 12px;
                }
            }
        }
    }

    .itemSearchFlag {
        display: flex;
        position: relative;

        &.featuredFlagWrapper::before,
        &.featuredFlagWrapper::after,
        &.reviewedFlagWrapper::before {
            background: $charcoal-700;
        }

        &.couponFlagWrapper::before {
            background: $mb-gold-2;
        }

        &.contractFlagWrapper::before,
        &.onCurrentMenuFlagWrapper::before,
        &.onCurrentMenuFlagWrapper::after {
            background: $blue-dark-4;
        }

        &.specialFlagWrapper::before{
            background: $teal; 
        }
    
        &.virtualFlagWrapper::before,
        &.offDayFlagWrapper::before {
            background: $purple;
        }
    
        &.marketBasketFlagWrapper::before {
            background: $indigo-dark;
        }
    
        &.noiFlagWrapper::before {
            background: $green-dark-3;
        }
    
        &.preferredFlagWrapper::before,
        &.iddsiFlagWrapper::before {
            background: $green-dark-2;
        }
    
        &.cutFlagWrapper::before,
        &.earlyReleaseFlagWrapper::before {
            background: $magenta;
        }
        
        &.subbedItemFlagWrapper::before {
            background: $purple-dark-4;
        }
    
        &.menuFlagWrapper::before,
        &.autoAllocateFlagWrapper::before
        {
            background: $blue-light-1;
        }

        &.divertedFoodFlagWrapper::before {
            background: $blue-dark-2;
        }

        &.usdaFlagWrapper::before {
            background: $blue-dark-3;
        }

        &.nonMBItemFlagWrapper::before {
            background: $cyan;
        }

        &.inactiveFlagWrapper::before {
            background-color: $red-dark-1;
        }
        &.subProhibitedFlagWrapper::before {
            background-color: $orange;
        }


    }
}

.wide-tooltip .popover {
    max-width: 350px;
}

.oeflag.couponFlag > a {
    color: $black !important;
}