#printArchive {
	position: absolute;
	z-index: 1;
	right: 252px;
}

#printArchive {
	position: absolute;
	z-index: 1;
	right: 252px;
}

.archive-icon {
	color: #999999;
	padding: 0 8px;
	cursor: pointer;
}


.inventory-analysis-row {
	font-size: 0.75rem;
}

.inventory-analysis-footer {
	border-top: 1px solid black;
	font-weight: bold;
}

#analysisTooltip {
	position: relative;
	margin-left: 70px;
	margin-top: -150px;
	width: 80px;
}

.analysis-chart-container {
	width: 238px;
	height: 238px;
	margin: 0 auto;
}

.analysis-tooltip-line {
	text-align: center;
	width: 80px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.no-inv-data-message-container {
	min-height: 300px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
}

input.inventory-input {
	display: inline-block;
	width: 50px;
	max-height: 28px;
	margin-bottom: 4px;
}

select.inventory-select {
	display: inline-block;
	width: 88px;
	max-height: 30px;
	margin-bottom: 4px;
}