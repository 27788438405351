/* Datepicker Component Start */

.ui-datepicker {
	
	.ui-state-default {
		border: none !important;
	}
	
	.ui-state-active {
		background-color: $blue-dark-2 !important;
	}
}

.ui-datepicker-header {
	background-color: $blue-dark-2 !important;
	color: $white !important;
	
	a {
		background-color: $white !important;
	}
}

.date-picker-toggle-options {
	display: flex;

	@include media-breakpoint-up(md) {
		display: none;
	}

	@include media-breakpoint-down(lg) {
		// uses pre-react modal, legacy
		.mb-modal-wrapper .modal-body & {
			display: flex;
		}
	}

	button {
		flex: 1;
	}
}

.mb-date-picker {
	display: none; 
	position: absolute; 
	z-index: 100;
	user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;

	.mb-overlay & {
		z-index: 70200;
	}
	
	@include media-breakpoint-down(sm) {
		left: 0px;
		right: 0px;
	}

	&.datepicker-dropup {
		@include media-breakpoint-up(md) {
			top: -45px;
			z-index: $headerZIndex + 1 !important; // above tertiary nav

			.modal-body & {
				top: inherit !important;
			}
		}
		
	}

	&.datepicker-dropleft {
		right: 0;
	}

	
	.ui-datepicker-header {
		background-color: $blue-dark-2;
		color: $white;

		a {
			background-color: $white;	
		}
	}
	
	table {
		thead {
			tr {
				color: $charcoal-700 !important;
				border: none !important;
				background-color: transparent !important;			
			}
		}
		
		a {
			background-color: transparent !important;
			border: none !important;
			text-align: center !important;
		}
	}
	
	.ui-datepicker-inline {
		width: 45em !important;

		.modal-body & {
			width: 43em !important;
		}
	}


	.hide-current-day .ui-datepicker-current-day.ui-datepicker-days-cell-over a.ui-state-active {
		color: inherit !important;
		background: inherit !important;
		background-color: inherit !important;
	}
	
	.ui-datepicker-today a {
		background: $yellow-light-2 !important;
		background-color: $yellow-light-2 !important;
		border: solid 1px $yellow !important;
	}

	.dateRangeStartEnd a,
	.dateRangeStartEnd {
		background-color: $blue-dark-2 !important;
		color: $white !important;
	}
	

	.dateInRange a,
	.dateInRange {
		background-color: $blue-light-2 !important;
		color: $white !important;
	}

	
	&.calendar-wide {
		background: #fff; 
		
		@include media-breakpoint-down(sm) { 
			.date-option-formatted {
				padding-bottom: 60px;
				border: 1px solid #c5c5c5;
			}
		}

		@include media-breakpoint-up(md) { 
			.date-option-formatted,
			.start-end-date-picker  {
				display: block !important;
			}
		}

		@include media-breakpoint-down(lg) { 
			.date-option-formatted,
			.start-end-date-picker  {

				.mb-modal-wrapper .modal-body & {
					&.d-md-block.d-none {
						display: none !important;
					}
				}

			}
		}

	}

	.start-end-date-picker .ui-datepicker-multi-2 {
		padding-bottom: 50px;
	}
	
	
	&.calendar-wide .ui-datepicker-group {
		width: 40% !important;

		@include media-breakpoint-down(sm) {
			width: 100% !important;
			padding-bottom: 30px;
		}
	}

	.date-picker-btn-container {
		position: absolute;
		text-align: right;
		width: 100%;

		@include media-breakpoint-up(md) {
			bottom: 15px;
		}

		@include media-breakpoint-down(md) {
			bottom: 5px;
		}
	}
	
	.date-option {
		padding: 5px;
		display: block;
		text-decoration: none;
		color: $black;
		border-bottom: solid 1px $light-gray;
	
		&.selected {
			background-color: $blue;
			color: $white;
		}
	
		&:hover {
			background-color: $blue;
			color: $white;
		}
	}
	
	ul {
		padding: 0;
		margin: 0 4px 0 0;
		list-style-type: none;
		@include media-breakpoint-up(md) {
			float: left;
			border-right: 1px solid $light-gray;
		}
	}

	@include media-breakpoint-down(sm) {
		ul {
			width: 100%;
		}
		
		.ui-datepicker-inline {
			width: auto !important;
		}
		
		.ui-datepicker-group {
			width: 100% !important;
		}
	}

	@include media-breakpoint-down(lg) {

		.mb-modal-wrapper .modal-body & {
			left: 0px;
			right: 0px;

			&.datepicker-dropup {
				top: inherit !important;
			}

			ul {
				width: 100%;
			}
			
			.ui-datepicker-inline {
				width: auto !important;
			}
			
			.ui-datepicker-group {
				width: 100% !important;
			}
		}

	}


}

.mbdatatable-bottom .dataTables_info::before {
	content: " | ";
}

.delivery-date-picker {
	display: none;
	position: absolute;
	z-index: 100;
	
	thead {
		tr {
			background-color: transparent;
			color: $charcoal-400;
			border: none;
			padding: 0;
			margin: 0;
		}

		th {
			padding: 0;
			margin: 0;
			font-size: 0.8em;
		}
	}

	td {
		background: transparent;

		a, span {
			background: transparent;
			text-align: center;
		}

		&.ui-datepicker-today {
			a, span {
				border: 1px solid $mb-gold-2 !important;
			}
		}

		&.ui-datepicker-current-day {
			a, span {
				color: #fff;
			}
		}

		&.notScheduledDay {
			span {
				color: #333 !important;
			}
		}
	}
}

.purchase-history-calendar {
	& .ui-datepicker {
		@include media-breakpoint-up(md) {
			margin: auto;
		}
	}
	
	& thead {
		& tr {
			background-color: transparent;
			color: $charcoal-400;
			border: none;
			padding: 0;
			margin: 0;
		}

		& th {
			padding: 0;
			margin: 0;
			font-size: 0.8em;
		}
	}

	& tbody tr, tbody tr a, tbody tr span {
		background-color: white !important;
		text-align: center;
		font-size: 0.9em;
	}

	& .ui-datepicker-today, .ui-datepicker-today a {
		background-color: white !important;
		border: 1px solid $blue-dark-2;
	}

	& .current-month {
		opacity: 1 !important;
	}

	& .purchase-history-day-count {
		text-align: center;
		width: 15px;
		height: 15px;
		font-size: 12px;
		line-height: 15px;
		margin: auto;
		visibility: hidden;
		vertical-align: middle;
		background-color: $charcoal-700;
		color: white;
		border-radius: 100%;

		&.has-data {
			visibility: visible;
		}
	}
}

/* Datepicker Component End */
.calendar-right {
	right: 0;
}

.mb-delivery-datepicker-btn {
	@extend .btn-sm;
	display: flex;
	gap: 0.5rem;
	align-items: center;
	color: $body-color;
	padding: 0;
	text-decoration: none;
	line-height: 1.1;

	&:focus,
	&:hover {
		text-decoration: none;
		color: $body-color;
	}
}

.mb-modal .modal-dialog {
	container: modal-dialog / inline-size;
}

.mb-start-end-date-picker-container {

	position: relative;

	.mb-start-end-date-picker-calendar {
		display: flex;

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	.mb-start-end-date-picker {
		background-color: $white;
		display: none; 
		position: absolute; 
		z-index: 100;
		user-select: none;
		-ms-user-select: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
	
		.mb-overlay & {
			z-index: 70200;
		}
		
		@include media-breakpoint-down(sm) {
			left: 0px;
			right: 0px;
		}

		&.calendar-wide {
			max-width: 635px;
			background: $white;
	
			@include media-breakpoint-down(md) { 
				width: 100%;
				max-width: 100%;
				.mb-start-end-date-picker {
					display: block;
				}

				.mb-date-option-formatted {
					padding-bottom: 60px;
					border: 1px solid #c5c5c5;
				}
			}
			@include media-breakpoint-up(md) {
				.mb-date-option-formatted {
					width: 130px;
					display: block;
				}
			}

			@include media-breakpoint-down(md) {
				.mb-date-option-formatted {
					width: 100%;
				}
			}
		}

		.mb-date-options {
			position: relative;

			@include media-breakpoint-up(lg) {
				display: flex;
			}

			.mb-date-option-formatted {
				@include media-breakpoint-up(lg) {
					display: block !important;
				}

				ul {
					padding: 0;
					margin: 0 4px 0 0;
					list-style-type: none;
					@include media-breakpoint-up(lg) {
						border-right: 1px solid $light-gray;
					}

					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
			}

			.mb-date-picker-toggle-options {
				display: none;

				button {
					flex: 1;
				}

				@include media-breakpoint-down(md) {
					display: flex;

					// uses pre-react modal, legacy
					.mb-modal-wrapper .modal-body & {
						display: flex;
					}
				}
			}
			
			@include media-breakpoint-down(md) {

				&.mb-selected-toggle-calendar {
					.mb-start-end-date-picker-calendar {
						display: flex;
						flex-direction: column;
					}

					.mb-date-option-formatted {
						display: none;
					}
				}

				&.mb-selected-toggle-ranges {
					.mb-start-end-date-picker-calendar {
						display: none;
					}

					.mb-date-option-formatted {
						display: block;
					}
				}
			}
		}

		.mb-date-picker-btn-container {
			position: absolute;
			text-align: right;
			right: 10px;
	
			@include media-breakpoint-up(md) {
				bottom: 15px;
			}
	
			@include media-breakpoint-down(md) {
				bottom: 5px;
			}
		}
	}

	.mb-date-option-container {
		border: 1px solid #c5c5c5;

		.mb-date-option {
			padding: 5px;
			width: 100%;
			border-radius: 0;
			display: block;
			text-decoration: none;
			color: $black;
			border-bottom: solid 1px $light-gray;
		
			&.selected {
				background-color: $blue;
				color: $white;
			}
		
			&:hover {
				background-color: $blue;
				color: $white;
			}
		}

		@include media-breakpoint-down(md) {
			position: relative;
	
			&.mb-datepicker-dropup .mb-start-end-date-picker {
				flex-direction: column;
			}
		}

	}

	.mb-datepicker-dropup {
		bottom: 25px !important;
		top: initial;
		z-index: $headerZIndex + 1 !important; // above tertiary nav
	}

	.mb-datepicker-dropleft {
		right: 0;
	}
	
	.mb-date-picker-with-buttons {
		padding-bottom: 65px;
	}

	.dateInRange a,
	.dateInRange {
		background-color: $blue-light-2 !important;
		color: $white !important;
	}

	.dateRangeStartEnd a,
	.dateRangeStartEnd {
		background-color: $blue-dark-2 !important;
		color: $white !important;
	}
	
	@container modal-dialog (width <= 500px) {
		.mb-modal-body & .mb-start-end-date-picker {
			
			&.mb-datepicker-dropup {
				bottom: initial !important;
			}

			.mb-date-options,
			.mb-date-picker-with-buttons {
				flex-direction: column;
			}
		}
	}
}

.purchase-history-display {
	.rdtDay {
		p {
			line-height: normal;
		}

		@include font-size(12px);
		background: $white !important;
		cursor: default !important;

		&:hover {
			background: $white !important;
		}
	}
}


.date-picker-position-fixed {
	.rdtPicker {
		position: fixed !important;
	}
}

.drop-content .rdtPicker {
	position: initial !important;
}