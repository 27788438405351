@-webkit-keyframes rotation {
	0% 		{ -webkit-transform: rotate(0deg); }
	50% 	{ -webkit-transform: rotate(180deg); }
	100% 	{ -webkit-transform: rotate(360deg); }
}

@keyframes rotation {
	0% 		{ transform: rotate(0deg); }
	50% 	{ transform: rotate(180deg); }
	100% 	{ transform: rotate(360deg); }
}

/* =========== Media Queries ============= */

/* Imported from screen.css */ 

/* ========== Tableless Resonsive Columns ========= */
.onecol {
	width: 100%;
}

.onecol .label, 
.onecol .labelWide
 {
	font-weight: bold;
	float: left;
	font-size: 0.875em;
	padding-bottom: 3px;
	clear: left;
	line-height: 12px;
	text-align: left;
	color: black;
}

.onecol .label:not(:empty) {
	width: 15%;
}

.onecol .labelWide {
	width: 25%; 
}

#displayOpstionsForm .onecol .label {
	width: 30%;
}

.onecol .label span {
	font-weight: normal;
}

.onecol .contents, .onecol .contentsNarrow {
	text-align: left;
	padding-bottom: 3px;
	display: block;
}

.onecol .contents {
	width: 85%;
}

.onecol .contentsNarrow {
	width: 75%;
}

#displayOpstionsForm .onecol .contents {
	width: 70%;
}

.twocol {
	float: left;
	width: 50%;
}

.threecol {
	float: left;
	width: 33%;
	padding-right: 10px;
}

.fourcol {
	float: none;
	width: 100%;
	list-style-type: none;
}

.fourcol .label {
	float: left;
	font-weight: bold;
	font-size: 0.875em;
	padding-bottom: 3px;
	padding-right: 5px;
}

.onethird {
	float: none;
	width: 100%;
}

.twothirds {
	float: none;
	width: 100%;
}

/* End screen.css import */
	

/* Upwards of Mobile Landscape Size  */
@media only screen and (min-width: 480px) {
	.wrapper {
		width: 100%;
		min-height: 100%;
		margin: 0 auto;
		margin-bottom: -48px;
		position: relative;
	}

	#footer_phone_mobile {
		display: inline-block;
		margin: 0;
		padding: 0 8px;
		position: relative;
		top: -2px;
		border-left: 1px solid $surface-darker;
	}
	#chat_online {
		display: block;
		width: 95%;
		margin: 10px auto 0;
		padding: 4px 20px;
		border-radius: 2px;
		@include font-size(12px);
	}

	li.menuhelp img {
		vertical-align:middle;
		text-align: right;
	}
	#menu_content_container {
	    background-color: $black;
	    color: $white;
	    width: 100%;
	    text-align: center;
	    min-height: 22px;
	    position: relative;
	
        ul {
            padding: 0;
            margin: 0;

            li {
                background-color: $black;
                display: inline;
                padding: 0 3px;
                border-left: 1px solid $white;
                margin: 0;
				@include font-size(10px);
                font-weight: bold;

                a {
                    background-color: $black;
                    text-decoration: none;
                    @include font-size(10px);
                    font-weight: bold;
                    color: $white;
                    
                    &:hover {
                        color: $mb-gold-1;
                    }
                }

                &.curpage a {
                    color: $mb-gold-1;
                }

                &:first-child {
                    border-left: none;
                }
            }
        }
    }

	.menu_content {
	    display: none;
    }
    
	.fourcol {
		width: 23%;
		padding-right: 10px;
	}
}


@media (min-width: 992px) {
	.wrapper {
		padding-top: 103px !important;
	}

	#mainView {
		padding-top: 20px;
		padding-bottom: 70px;
	}

	#resultsContainer {
		margin-top: -20px;
	}

	#menu-dropdown-search-icon {
		position: absolute;
	}

	.itemsearch.main-right__itemsearch .sortingContainer .sortBlock {
		right: -55px;
	}


}

@media (min-width: 992px) and (max-width: 1320px) {

	#mainView {

		.itemsearch:not(.main-right__itemsearch) {
			#itemSearchSearchTerm {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	
		.itemsearch:not(.main-left__itemsearch):not(.main-right__itemsearch) {
			.cartQtyBtn {
				width: 32px;
				height: 32px;
			}
			#resultsContainer .itemContainer .itemContainer__image-block {
				width: 64px;
			}
			.itemContainer .itemContainer__image {
				width: 64px !important;
				height: 64px !important;
			}

			.itemContainer .itemContainer__left,
			.itemContainer .itemContainer__right {
				padding: 8px;
			}

			.itemContainer .itemContainer__right {
				display: block;
			}

			.sortingContainer .sortBlock {
				select {
					width: 90px;
				}
			}

			#footerpaging {
				display: block;
				
				.resultPaging-left {
					border-bottom: 1px solid $charcoal-300;
					text-align: center;
				}

				.resultPaging-right {
					text-align: center;
				}
			}

			.item-purchase-wrapper {
				flex-direction: column;

				.itemContainer__right:first-child {
					order: 1;
					align-self: flex-end;
				}
			}
		}

		.home-wrap {

			.banner-icon {
				font-size: 18px;
			}
	
			.banner-card-title {
				font-size: 15px;
			}
	
			.banner-card-count {
				font-size: 30px;
			}
	
		}
	}


}

@media (max-width: 991.98px) {
	body header {
		height: auto;
	}

	footer {
		display: none;
	}

	body.panel-flyout-open {
		overflow: hidden;
	}

	#mainView {
		padding-top: 20px;
        padding-bottom: 70px;
        
		
		.itemsearch .itemsearch__heading {
			padding: 9px 10px;
		}
	}

	.navbar .navbar-toggler:not(.collapsed) {
		color: rgba(0,0,0,0.6);
	}

	.item-details-wrap .item-details-flyout-qty {
		bottom: 0px !important;
	}


	.mb-search {
		padding: 15px;
		max-width: none;
        display: flex;
        
        .nav-search {
  		    text-align: center;
			@include font-size(24px);          
        }

        .flexwrap {
            width: 80% !important;
        }
	}


	#navbar {
		float: right;
		background: $charcoal-900;
	}

	#accountDropdown > li {
		position: relative;
    }

    .wrapper {
		padding-top: 56px !important;
	}

	nav.left-panel {
		top: 111px;
	}

	.navbar.tabs_navbar,
	.navbar.tabs-navbar {
		top: 56px;

		&.page-navbar {
			top: 0;
		}
	}



	#navbar .dropdown-menu {
        width: auto;
        
    } 
    
    #mobilenav {

        .main-nav {

            &.show .nav {
                display: block;
            
                &:first-child {
                    border-bottom: solid 1px rgba(255,255,255,0.1);
                }
			}
		}

		.dropdown-menu {
			width: 100%;
			border: none;

			&.show {
				background: $charcoal-900;
				margin-top: 0;
			}
		}
	
		.dropdown-menu > li > a,
		.dropdown-menu > li > button,
		.dropdown-item.subnav-dropdown-a {
			color: $charcoal-300 !important;
			padding: 8px 12px !important;
		}
		
	
		.dropdown-menu > li > a,
		.dropdown-item.subnav-dropdown-a:focus,
		.dropdown-item.subnav-dropdown-a:active,
		.dropdown-item.subnav-dropdown-a:hover {
			background-color: $charcoal-800;
		}
	
		.dropdown-menu .active-dropdown,
		.dropdown-menu .dropdown-item:focus,
		.dropdown-menu .dropdown-item:hover {
			background: $charcoal-700;
		}
	
		a.active-yellow,
		.dropdown.show a.dropdown-toggle {
			border: none !important;
			background: none !important;
		}
	
		.nav-link {

			border-bottom: none !important;

			&:hover {
				border-bottom: none !important;
				background: rgba(255,255,255,0.05);
			}
			
		}
	
    }

	#footerpaging {
		display: block !important;
        text-align: center;
		
		.resultPaging-right {
			text-align: center;
		}
	}

	#resultsContainer {
		margin-top: -16px; 
		margin-bottom: 40px; 
	}

	.listViewToggle,
	.compare-items-toggle {
		display: none;
	}

	.navbar > .nav.menu {
		position: absolute;
		right: 15px;
		width: 250px;
		flex: 0;
		top: 30px;
	}

	.flexwrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.left-panel-toggle.centered,
	.right-panel-toggle.centered {
		height: 69px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#downloadBtn {
		display: none;
	}
	
	.sidebar_left {
		float: none;
		width: 100%;
        margin-bottom: 1rem;
        
        input[type="text"], 
        select {
            width: initial;
        }

        ul li {
            display: inline-block;
        }

	}

	.account-search-mobile {
		width: 100%;
	}

	.dropdown-search-icon {
		position: absolute;
		bottom: 5px;
		right: 5px;
	}

	#cartBadgeNavMobile.badge-blue {
		position: absolute;
		top: -3px;
		right: 7px;
		padding: 3px 7px;
		font-size: 60%;
		border-radius: 50%;
    }
    
    #filterSidebar.collapsed .applyFilters {
		position: relative;
		display: none;
	}

	#filterSidebar:not(.collapsed) {
		position: fixed;
		top: 20px;
		left: 0;
		z-index: 70200;
		width: 100%;
        height: 100%;
        
        .left-panel-menu__filter {
            top: 0%;
            bottom: 0%;
            height: 100%;
            overflow-y: scroll !important;
        }

        #resetBtnMobile {
            display: block;
            margin-right: 32px;
        }

        .applyFilters {
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 75px;
            z-index: 1;
            justify-content: center;
            align-items: center;
            background-color: $surface-darker;
        }

	}

	.ui-multiselect-menu {
		z-index: 70201;
	}

	#mainView .left-panel-menu__filter i#refinementFilterIcon {
		position: absolute;
		top: 8px;
		right: 10px;
	}

	#mainView .left-panel-menu__filter > li:last-child {
		padding-bottom: 116px;
	}

	.left-panel-toggle .filter-close {
		display: block;
	}

	.chev-right {
		display: none;
	}

	#resetBtn {
		display: none;
	}

	#cart {
		display: none;
	}


	#orderReviewContent {
		min-width: 0 !important;
	}
	
	#browse_catalog_image_container {
		width: 95%;
        background-position: left;
        
        h1 {
            @include font-size(30px);
        }
    }

	.dishmag_col {
		flex: 0 0 300px;
		min-height: 300px;
	} 

	.cartQtyInput {
		@include font-size(16px);
	}

	.itemsearch.main-left__itemsearch.main-right__itemsearch #resultsContainer #searchResults, 
	.itemsearch.main-left__itemsearch.main-right__itemsearch #resultsContainer #pagingWrap {
		width: calc(100% + 55px) !important;
	}

	body.mobile-view .itemsearch.main-right__itemsearch #resultsContainer #searchResults, 
	body.mobile-view .itemsearch.main-right__itemsearch #resultsContainer #pagingWrap {
		width: 100% !important;
	}

	body.mobile-view #filterSidebar:not(.collapsed) #resetBtnMobile {
		margin-right: 0px;
	}

	#pendingOrders.savedCartsPO .buttons-colvis {
		display: none;
	}

	.sortingContainer .sortBlock {
		select {
			width: 80px;
		}
	}
	
	.menu-nav,
	.subpage-menu-nav {
		background-color: $gray;

		.nav-link-button {
			width: 100%;
			text-align: left;
		}

		.active,
		.active:hover,
        .active-black,
        .active-black:hover {
            border: none !important;
            background: $charcoal-500 !important;
        }

        .nav-link,
        .nav-link:hover {
			color: $charcoal-900 !important;
            border-bottom: none !important;
        }

        .nav-link:hover {
            background: rgba(0,0,0,0.1) !important;
        }
	}

}

/* Beyond All Mobile Sizes, Tablet Portrait etc. */
@media only screen and (min-width: 768px) {
	#menu_content_container ul li {
		@include font-size(12px);
	    display: inline;
	    border-left: 1px solid $white;
	    padding: 0 5px;
        margin: 0;
        
        a {
            @include font-size(10px);
        }
    }
    
	#menu li a {
	    padding: 7px 6px;
	    text-decoration: none;
	    @include font-size(10px); 
	    font-weight: bold;
	}
	
	#mainView {
		.toolsleft {
			width: 62%; 
			margin-right: 3%; 
		}	
		.toolsright {
			float: right; 
			width: 35%;
		}
		.menuright {
		padding: 10px 0 10px 275px;
		width: auto;
		}
	
		.tools_intro_left {
			float: left;
			width: 50%;
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 20px; 
		}

		.tools_intro_right {
			width: 50%;
			border-left: 1px solid $gray;
		}
		.homeleft {
			float: left; 
			width: 40%;
			margin-top: 10px;
		}
		.menuleft {
			float: left; 
			width: auto;
			margin: 10px 0 5px;
		}

		.homeright {
			float: left; 
			width: 60%; 
			padding-top: 10px;
		}
		.laborleft {
			float: left;
			width: 40%;
			display: table-cell;
		}

		.laborright {
			float: left;
			width: 55%;
			margin-left: 10px;
			display: table-cell;
		}
	}

	.onethird {
		float: left;
		width: 33%;
		padding-right: 10px;
	}
	.twothirds {
		float: left;
		width: 66%;
		padding-right: 10px;
	}

	#footer_phone_mobile {
		display: none;
	}
	#footer_phone {
		font-weight: 400;
		color: $mb-gold-1;
		display: block;
		@include font-size(18px);
		line-height: calculateRem(18px);
		float: right;
		margin: 13px 0 0;
		display: block;
	}
	#idPageView {
		.two_column_450_left {
			float: left;
			max-width: 450px;
			margin-bottom: 0;
		}
		.two_column_450_right {
			margin-left: 475px;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
		.two_column_350_left {
			float: left;
			max-width: 350px;
			margin-bottom: 0;
		}
		.two_column_350_right {
			margin-left: 375px;
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}

	.tags_list {
		margin: 15px 0 20px;

		.resource_tags li {
			display: block;
	
			a {
				display: block; margin: 10px 0 0; font-size: 14px; border-radius: 4px;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	#header_profile_menubar {
		display: none;
    }
    
    #resultsContainer {

        .itemContainer {
            margin-right: -25px !important;
            margin-left: -14px !important;
            border-left: none !important;
            border-right: none !important;

            > table > tbody > tr > td {
                width: 100%;
                display: block;

                &:first-child {
                    text-align: center;
                }
            }
        }

	}

	#pagingWrap {
		padding: 0;

		#footerpaging {
		margin-left: -5px;
		margin-right: -15px;
		border-right: 0;
		}
	}	

	.item-list .descCol {
		width: inherit;
	}
	
	.orderSearchItemsContainer {
		width: 100%;
    }
    
    #orderSearchItems {

        thead {
            display: none;
        }

        th, td {
            display: block;
        }

        td {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

	
	#orderEdit .submit-button {
		margin-top: 10px;
	}
	
	.mobileInline {
		display: inline;
	}
	
	.mobileVisible {
		display: initial;
	}
	
	.largeScreenVisible {
		display: none;
	}
	
	#oeFooter {
		display: block;
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 10px 0;
		box-sizing: border-box;
		text-align: center;
		background: $charcoal-400;
        z-index: 1000;
        
        button {
            color: $white;
			@include font-size(14px);
            width: 120px;
            padding: 4px 0;

            &:first-child {
                background-color: #1FAB45;
            }

            &:last-child,
            &:nth-child(2) {
                background-color: #757575;
            }
        }
	}

	.top-nav {
		background-color: $charcoal-900;
	}

	.main-nav {
		background-color: $charcoal-900;
	}

	.modal .item-list-details {
		margin-left: 110px;
    }

	.threecol {
		float: none;
		width: 100%;
	}

	.zero-gutters .row {
		margin-right: 0;
		margin-left: 0;
		
		> [class*="col-"] {
			padding-right: 0;
			padding-left: 0;

		}
	
	}

	.border-left {
		border-left: none !important;
	}

	#mainView {

		.sortingContainer .sortBlock {
			top: 47px;
		}
	}

}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
	#mainView {
		.sortingContainer .sortBlock {
			top: 38px;
			right: 17px;
			
			select {
				width: inherit;
			}
		}
	}
}

@media only screen and (min-width: 520px) {
	.slider_caption {
		/* set positioning in RS theme CSS file (.rsABlock) */
		background-color: rgba(50, 50, 50, 0.75);
		padding: 10px 8px 0px 15px;
		max-width: 100%;
		width: 100%;
		font-size: 0.75em;
		bottom: 0;

		h2 + p {
			text-decoration: underline !important;
		}

		h2 {
			font-size: 1.6em;
			margin-bottom: 12px;
		}
	}

}

@media only screen and (min-width: 520px) and (max-width: 768px) {
	.orderSearchItemsContainer, .orderItemsContainer {
		float: none;
		width: 100%;
	}
	
	.orderItemsContainer {
		display: block;
	}
}

@media only screen and (min-width: 520px) and (max-width: 991.98px) {
	.navbar.tabs_navbar {
		top: 56px !important;
	}
}

	
/* Beyond standard 960 */
@media only screen and (min-width: 960px) {
	#mainView {
		.invmgmtleft {
			width: 20%; 
			float: left; 
		}
			
		.invmgmtright {
			width: 79%; 
			float: right;
		}
	}
	


	.menuleft h1 img {
		width: 300px;
	}

	.menuright {
        padding: 10px 0 10px 325px;
	}
	
    
	table .optional {
		display: table-cell;
	}
}

@media (max-width: 1400px) {
	.product-row .desc-holder {
		float:right;
		width:75%;
		margin-bottom:20px;
	}
}

@media (max-width: 570px) {
    .long-content.modal .modal-dialog.modal-xl {
        max-height: 98%;
    }
}

@media (max-width: 820px) {
	.product-row .desc-holder {
		float:right;
		width:60%;
		margin-bottom:20px;
	}

	.ui-dialog {
		width: calc(100% - 10px) !important;
		left: 10px;
	}

}

@media (max-width: 520px) {
	.product-row .desc-holder {
		float:right;
		width:50%;
		margin-bottom:20px;
	}
	
	.orderItemsContainer {
		width: 100%;
		min-width: 250px;
	}
	
	#mobilenav .main-logo {
		display: none;
	}

	.mobile-logo {
		display: block !important;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	button#mobile-menu-toggler {
		padding: 10px 12px;
	}
}

@media (max-width: 420px) {
	.product-row .desc-holder {
		float:right;
		width:auto;
		margin-bottom:20px;
	}
}

@media only screen and (min-width: 935px) {

	#homeNews {
		margin-left: 0;
	}

	#home_dishblog {
		padding-top: 30px;
		margin-left: 240px;
	}
	#home_whatshot {
		margin: 25px 0;
	}
	.kidsActivityList {
		columns: 1
	}
}

@media screen and (max-width: 1024px){
    .itemSearchResultsButtons,
    .orderSearchItemsContainer,
    .orderItemsContainer {
		width: 100%;
	}

}

@media only screen and (min-width: 1240px) {

	.home-wrap .container,
	.home-wrap .container-fluid.mw-lg {
		max-width: 1335px;
	}

	#home_slider {
		max-width: 700px;
	}

	.kidsActivityList {
		columns: 2
	}
}

@media only screen and (min-width: 1440px) {
	.kidsActivityList {
		columns: 3
	}
}