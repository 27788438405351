// Raise the z-index if the flyout appears after a modal in the DOM
.mb-modal-wrapper ~ .mb-flyout-wrapper {
	.mb-flyout-backdrop {
		z-index: $mbModalContainerZIndex + 10;
	}

	.mb-flyout {
		z-index: $mbModalContainerZIndex + 10;
	}
}

.mb-flyout {
	position: fixed;
	top: 0;
	right:0;
	bottom:0px;
	background: $white;
	z-index: 72200;
	transition: 500ms ease;
	-webkit-transform: translate(100%);
	-ms-transform: translate(100%);
    transform: translate(100%);
    max-width: 992px;
    width: 100%;
    
    &.open-flyout {
        -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
        transform: translate(0%);

        .mb-flyout-close {
            display: flex;
        }
    }
	
    .mb-flyout-header {
		display: flex;
		justify-content: space-between;
        padding: 10px;
		color: $white;
		background-color: $blue;
		
        a {
			color: $white;
            font-size: 20px;
            display:block;
        }
    }
	
    .mb-flyout-title {
		font-size: 24px;
    }
	
}

.mb-flyout-content,
.mb-flyout-container {
	.flyout-overflow,
	.flyout-overflow-tabs,
	.flyout-overflow-tabs-footer,
	.mb-flyout-overflow-tabs-footer,
	.flyout-overflow-footer,
	.manage-list-group {
		position: relative;
		overflow: auto;
	}

	.flyout-overflow {
		height: calc(100vh - 56px);
	}

	.flyout-overflow-tabs {
		height: calc(100vh - 103px);
	}

	.flyout-overflow-tabs-footer {
		height: calc(100vh - 174px);

		.item-details-wrap & {
			height: calc(100vh - 71px) !important;
		}
	}

	.mb-flyout-overflow-tabs-footer {
		height: calc(100vh - 181px);

		.item-details-wrap & {
			height: calc(100vh - 71px) !important;
		}
	}

	.flyout-overflow-footer {
		height: calc(100vh - 112px);
	}

	.manage-list-group {
		height: calc(100vh - 181px);
	}
}

.offcanvas-body .manage-list-group {
	height: calc(100vh - 144px);
}

#mbFlyoutCloseBtn.close-button {
	display: flex;
	align-items: center;
}

// Flyout sub nav (tabs)
.mb-flyout-subnav {
	@extend .container-fluid;
	background-color: $surface-dark;
	border-bottom: solid 1px $light-gray;

	.mb-flyout-subnav-nav {
		@extend .navbar;
		padding-top: 0.5rem;
		box-shadow: none;
		align-items: flex-end;
	
		.mb-flyout-subnav-nav-tabs {
			@extend .nav, .navbar-nav;
			flex-direction: row;
	
			li.nav-item {
				margin-right: 0.5rem;
			
				> a {
					padding: 5px 10px;
				}
			}
		}
	}
}

#mbGlobalFlyoutContainer {

	#mbFlyoutContent {
		position: relative;
		height: 100vh;
	}
}

/* ----- Saved Carts Flyout ----- */

#savedCartsContent {
	width: 90%;
	margin: auto;
}

.ui-dialog.flyout-dialog-dialog {
	z-index: 72001 !important;
}


/* ----- Item Details Flyout Content ----- */

.item-details-flyout-qty,
.flyout-footer {
	z-index: 1;
	position: fixed;
	bottom: 0;
	width: 100%;
	left:0;
	background: $surface-darker;
	padding: 10px;
	border-top: solid 1px $charcoal-500;
	min-height: 71px;
}

.item-details-wrap {
	padding-top: 1px;
	padding-bottom: 71px;
}

.item-details-wrap,
.mb-flyout-content  {

	.product-detail {
		color: $black;
	}

	.item-price-text {
		font-weight: bold;
		@include font-size(18px);
	}


	.details-tab#additionalInfo,
	.details-tab#relatedItems,
	.details-tab#myDetails {
		padding-top: 16px;
	}

	.details-tab#relatedItems {
		#subItemWrapper:empty,
		#associatedItemsWrapper:empty,
		#similarItemsWrapper:empty {
			margin-bottom: 0 !important;
		}
	}

	.details-tab#myDetails {
		.content-drop-group:first-child {
			padding-top: 0 !important;
		}
	}
}

/* ----- Flyout Management ----- */
	
.manage-add-group {
	background: $surface-dark;
	border-bottom: solid 1px $charcoal-300;
	padding: 0.5rem;

	label {
		font-weight: 500;
	}

	.manage-add-action {

		display: flex;
		
		input.manage-add-name {
			margin-right: 0.25rem;
		}

		button.manage-add-btn {
			white-space: nowrap;
		}
	}
}

.manage-list-group {
	list-style: none;
	margin: 0;
	padding: 0.5rem;

	li {

		display: flex;
		gap: 0.25rem;
		align-items : center;

		em.material-icons,
		em.material-symbols-outlined {
			color: $charcoal-600;
			@include font-size(20px, true);
		}

		a.manage-name-link,
		.manage-item-text {
			width: 100%;
			display: block;
			color: $charcoal-975;
			width: 100%;
			padding: 0.5rem;
		}

		a.manage-remove-link,
		a.manage-import-link,
		a.manage-copy-link {
			vertical-align: bottom;
			padding: 0.5rem;
		}

		&:nth-child(odd) {
			background-color: $surface-dark;
		}
	}
}

.manage-help-text {
	padding: 0.5rem;
	margin-top: 0.25rem;
	font-weight: 300;

}

/* ----- MEDIA QUERIES ----- */ 

@media (max-width: 1092px) {
	.mb-flyout:not(.short-flyout) {
		max-width: calc(100% - 100px);
	}
}

@include media-breakpoint-down(md) {
	.item-details-wrap {

		.item-details-flyout-qty {
			position: fixed;
		}
	
		.modal-item-content {
			height: calc(100vh - 180px);
			overflow: auto;
		}

		.gallery-thumb {
			max-height: 38px;
			overflow: hidden;
		}

		.slick-dots {
			display: none !important;
		}

		.navbar .nav li.nav-item > a {
			padding: 5px;
		}
	}

	.mb-flyout {
		max-width: calc(100% - 100px) !important;

		.item-flyout-wrap {

			.modal-item-content {
				height: calc(100% - 220px);
			}

		}

		.mb-flyout-title {
			@include font-size(20px);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

@include media-breakpoint-down(sm) {

	.item-details-wrap,
	.mb-flyout-content {

		.action-buttons-col {
			max-width: 100%;
		}

	}
}

@include media-breakpoint-down(xs) {
	.mb-flyout-content {
		position: relative;
	}

	.mb-flyout {
		max-width: 100% !important;

		.mb-flyout-title {
			@include font-size(18px);
			margin-right: 40px;
		}
	}

	.mb-flyout-close {
		left: auto !important;
		right: 10px !important;
		top: 10px !important;
		width: 30px !important;
		height: 30px !important;
		background: none !important;
	}

	.mb-flyout-close em.material-icons {
		color: $white;
	}
}

/* ------- REACT MIGRATION OFFCANVAS FLYOUT ------ */

#mbFlyout.mb-flyout-container {
	width: 100%;

	&.mb-flyout-lg {
		max-width: 992px;
		
		@include media-breakpoint-down(lg) {
				max-width: calc(100% - 100px);
		}
	
		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}
	
	&.mb-flyout-sm {
			max-width: 400px;
	}

	+ .mb-modal-backdrop {
		z-index: 82100;
	}

	.flyout-content {
		padding: 1rem 0;
	}

	@include media-breakpoint-down(md) {
		.tabs-navbar {
			top: 0;
		}
	}

	&.mb-flyout-with-header {
		.offcanvas-body {
			padding: 0;
		}
	}

	.mb-flyout-scrollable-container {
		display: flex;
		height: calc(100vh - 56px);
		flex-direction: column;
	}

	.mb-flyout-footer {
		background: $surface-darker;
		padding: 10px;
		border-top: solid 1px $charcoal-500;
		min-height: 71px;
	}
}
