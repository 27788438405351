.finance-positive {
	color: $green;
}

.finance-even {
	color: $black;
}

.finance-negative {
	color: $red;
}

.order-status-row {
	padding: 0.25rem 0.5rem;
	@include font-size(17px);
	font-weight: 500;
	color: $white;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 100%;
	line-height: inherit;

	&.status-edit {
		background-color: $blue;
	}

	&.status-locked {
		background-color: $charcoal-500;
	}

	&.status-unsubmitted {
		color: $charcoal-900;
		background-color: $yellow-light-2;
	}

	&:hover {
		opacity: 0.9;
	}
}

.order-status-content-detail {

	.order-card-detail {
		color: $charcoal-700;
	}

	.order-detail-heading {
		@include font-size(17px);
		@extend .fw-500;
		margin-bottom: 0;
	}

	.order-detail-count {
		@include font-size(28px);
		@extend .fw-700;
	}

	.order-detail-case-count {
		@include font-size(20px);
		@extend .fw-600;
	}

	.order-detail-meta-content {
		@include font-size(12px);
	}
}

.order-status-message {
	text-align: center;
	@include font-size(18px);
	margin-top: 2.5rem;

	.order-status-item-count {
		@include font-size(45px);
		font-weight: bold;

	}
}


.order-title {
	@include font-size(17px);
	font-weight: 500;

	.order-title-item a {
		white-space: nowrap;
	}
}

.ordered-items-container {

	.ordered-items-thumb {
		flex: 0 0 100px;

		.img-thumb-wrap {
			overflow: hidden;
			text-align: center;
			
			> img {
				width: 100%;
			}
		}

		@include media-breakpoint-down(xs) {
			margin: auto;
			flex: 0 0 125px;
		}
	}

	.ordered-items-qty {
		@include font-size(48px);
		font-weight: 600;
		min-width: 70px;

		@include media-breakpoint-down(xs) {
			flex: 1;
			text-align: right !important;
			margin-right: 8px;
		}
	}

	.ordered-items-detail {
		@include media-breakpoint-down(xs) {
			flex: initial;
		}
	}

	.ordered-items-name {
		a, span {
			@include media-breakpoint-down(xs) {
				display: block;
			}
		}
	}
	
	.ordered-items-edit {
		display: flex;
		height: 100%;
		max-width: 30px;
		background: $blue;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:hover {
			opacity: 0.9;
		}
	}

	.ordered-items-non-edit {
		display: flex;
		height: 100%;
		max-width: 30px;
		background-color: $charcoal-500;
		justify-content: center;
		align-items: center;
	}

	.order-item-row {
		align-items: center;
	}

	.ordered-items-qty-wrap {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}

	.item-group-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		
		@include media-breakpoint-down(xs) {
			justify-content: flex-start;
		}

		.item-group-subtotal,
		.item-group-cases {
			text-align: center;

			@include media-breakpoint-down(xs) {
				text-align: left;
			}
		}

		.item-group-subtotal {
			min-width: 80px;

			@include media-breakpoint-down(xs) {
				text-align: left;
				margin-right: 1rem;
			}
		}

		.item-group-cases {
			min-width: 70px;
		}
	}
}

.progress-steps {

	ul {
		margin:0;
		padding: 0;
		list-style: none;
		display: inline-table;
		white-space: nowrap;
		width: 100%;
		max-width: 300px;

		li {
			display: inline-block;
			width: 25%;

			span {
				display: block;
				float: left;
				height: 25px;
				background-color: $light-gray;
				text-align: center;
				padding: 0px 0px 0 10px;
				position: relative;
				margin: 0 2px 0 0;
				width: calc(100% - 2px);
				text-decoration: none;

				&:after {
					content: "";
					border-top: 13px solid transparent;
					border-bottom: 13px solid transparent;
					border-left: 10px solid $light-gray;
					position: absolute;
					right: -10px;
					top: 0;
					z-index: 1;
				}

				&:before {
					content: "";
					border-top: 13px solid transparent;
					border-bottom: 13px solid transparent;
					border-left: 10px solid $white;
					position: absolute;
					left: 0;
					top: 0;
				}
			}

			&:first-child span {
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;

				&:before {
					display: none;
				}
			}

			&:last-child span {
				border-top-right-radius: 25px;
				border-bottom-right-radius: 25px;

				&:after {
					display: none;
				}
			}

			&.current-step span {
				background-color: $green-dark-2;

				&:after {
					border-left-color: $green-dark-2;
				}
			}

			&.completed-step span {
				background-color: $green-light-2;

				&:after {
					border-left-color: $green-light-2;
				}
			}

		}
	}

}

.progress-list ul {
	margin: 0;
	padding: 0;
	list-style: none;
	@include font-size(13px);

	li {
		color: $charcoal-600;
		margin-bottom: 3px;
		font-weight: 500;
		line-height: 1.1;

		&:before {
			display: inline-block;
			content: "";
			width: 10px;
			font-family: "Material Icons";
			@include font-size(13px);
			margin-right: 5px;
			vertical-align: text-top;
		}

		&.completed-step {
			color: $black;

			&:before {
				content: "check";
				color: $black;
			}
		}

		&.current-step {
			font-weight: 700;
			color: $green-dark-2;
			line-height: 1.2;

			&:before {
				content: "check";
				color: $green-dark-2;
			}
		}
	}
}

.order-status-delivered {
	background: $surface-dark;
	min-width: 190px;
	position: relative;

	.delivered-done {
		background: $white;
		border-radius: 50%;
		color: $green;
		position: absolute;
		top: -5px;
		right:-5px;
	}
}


.order-status-update {
	color: $charcoal-500;
	@include font-size(12px);
}

/* ------------ Invoice Details Start -------------- */


.invoice-detail-data {
    
    label {
        font-weight: 600;
        margin-bottom: 0;
    }
  
    p {
        margin-bottom: 5px;
    }
}
  
.invoice-contact {
    min-width: 300px;
}

/* ------------ Invoice Details End -------------- */


/* OE */ 


.usagetotal {
	font-size: 12px;
	font-weight: bold;
	color: $red;
}

.oelastorderdate {
	font-size: 0.8em;
}

.ogaddnew {
	text-decoration: none;
	font-weight: bold;
}

.ogaddnew img {
	vertical-align: middle;
	margin-right: 3px;
}

.optional {
	display: none;
}

.itemAdditionalInfo {
	font-size: 0.8em;
}

#invoice-content #payment-dates-container,
#invoice-content #history-dates-container {
	display: inline-block;
}

#invoice-content p {
	margin: 0;
	padding: 0 5px 0 0;
	display: inline-block;
	line-height: 32px;
	font-size: 16px;

	label {
		font-weight: 600;
		margin-right: 4px;
	}
}

.filter-table-options {
	margin-bottom: 16px;
}

.filter-table-options input[type=text] {
	line-height: 24px;
}

.filter-table-options .search-icon {
	position: relative;
	left: -24px;
}

#date-range-container .button {
	background-color: transparent !important;
}

.payment-invoice-link {
	margin-right: 4px;
	display: inline-block;
}

#oeCustomerSpecialMsg {
	float: right;
	color: $red;
	font-size: 20px;
	font-weight: bold;
}


/* ------------ Order Guide -------------- */

.selectOGWrapper {
	
	label {
		@include font-size(12px);
		
		@include media-breakpoint-down(md) {
			display: inline;
		}
	}
}

@include media-breakpoint-down(sm) {
	.order-table {
		display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

/* ------------ Order Management Item List -------------- */

.calculation-container {
	font-weight: 600;

	.calculation-row {
		display: flex;
		margin: 0.25rem 0;

		.calculation-result {
			flex: 1;
			max-width: 75%;
			padding-top: 0.25rem;
		}

		&:before {
			content: " ";
			width: 15px;
		}

		&.subtracted:before {
			content: "-";
		}

		&.added:before {
			content: "+";
		}

		sup {
			margin-left: 5px;
			align-self: center;
		}

		span {
			font-weight: normal;
		}
	}
}

.exception-indicator {
	width: 25px;

	&.wide {
		width: 55px;
	}
}

.order-status {
	display: flex;
	align-items: center;
	white-space: nowrap;
	@at-root button#{&} {
		@extend .button-xsmall;
	}
}

.order-status-icon-offset {
	margin-left: 29px;
}
