.price-list {

    > li {
        display: inline-block;
        position: relative;
    }

    .price-list__price {
        vertical-align: middle;
        margin-right: 4px;
    }
}

.qtyPicker-list {
    white-space: nowrap;
}

.cartQtyBtn {
    background: linear-gradient(180deg, #FAFAFB 0%, #F3F4F5 100%);
    color: $charcoal-975;
    padding: 0;
    margin-right: -4px;
    box-shadow: none !important;
    vertical-align: top;
    border-right: transparent;
    width: 32px;
    height: 32px;

    .counter-lg & {
        width: 40px;
        height: 40px;
    }

    &:last-child, 
    &.counterIncrement,
    &.increaseCartQtyBtn {
        margin-right: 1px;
        border-left: transparent;
        border-right: 1px solid $gray;
    }
}

.cartQtyInput {
    height: 32px;
    width: 40px !important;
    border: 1px solid $gray;
    border-radius: 0;
    text-align: center;
    margin-right: -4px;
    vertical-align: middle;

    .counter-lg & {
        height: 40px;
    }

    &.cartQtyInput--focused {
        border: 2px solid $black;
        outline: none !important;
        @include font-size(20px);
        font-weight: 600;
    }

    &::placeholder {
        @include font-size(12px, true);
    }
}

.quantity-status {
    display: none;
    position: absolute;
    padding: 4px 16px;
    top: -34px;
    width: 46px;
    left: 50%;
    margin-left: -23px;
    background-color: $gray;
    color: $white;
    border-radius: 4px;
    text-align: center;
    z-index: 2;

    &:before {
        content: '';
        top: 100%;
        left: 19px;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $gray;
    }

    &.quantity-status-success {
        background-color: $green;
        border-color: $green;

        &:before {
            border-top-color: $green;
        }
    }

    &.quantity-status-fail {
        background-color: $red;
        border-color: $red;

        &:before {
            border-top-color: $red;
        }
    }
}