.home-wrap {

    .home-box h2 {
        font-size: 20px;
        border-bottom: 1px solid #ccc;
        margin: 0;
    }
    

    #home_dishmag {
        background-repeat: no-repeat;
        background-size:cover;
        height: 100%;
    }

    .rsMinW .rsBullet span {
        display: inline-block !important;
        width: 10px;
        height: 10px;
    }

    .rsNav.rsBullets {
        right: 10px;
    }

    .home-banner {
        position:relative;
        overflow: hidden;
        margin-right: -15px;
        margin-left: -15px;
        margin-top: -70px;
        padding-bottom: 40px;

        &.banner-1:before {
            background-image: url(/resources/images/homebanner-1.jpg);
        }
        
        &.banner-2:before {
            background-image: url(/resources/images/homebanner-2.jpg);
        }
        
        &.banner-3:before {
            background-image: url(/resources/images/homebanner-3.jpg);
        }

        nav > a {
            color: $white;
            font-weight: 400;
            @include font-size(13px);

            &:hover {
                text-decoration: underline;
            }
        }
        
        h2 {
            @include font-size(28px);
            font-weight: 300;
        }

        .card {
            border: solid 2px transparent;
            border-radius: 0.25rem;
            position: relative;
            color: $white;
            height: 100%;

            > a,
            &.cursor-hand {
                color: $white;
                text-decoration: none;
                display: block;
            }
            &.card-active-cart:hover {
                border-color: $blue-light-2;
            }
            &.card.card-orders:hover {
                border-color: $purple-light-2;
            }
            &.card-notifications:hover {
                border-color: $orange-light-2;
            }
            &.card-invoices:hover {
                border-color: $green-light-2;
            }
            &.card-disabled {
                opacity: 0.75;
            }
        }

        .card-body {
            padding: 0.5rem 1rem;
            min-height: 85px;
        }

        .notification-icon {
            position: absolute;
            color: $white;
            right: -10px;
            top: -10px;
            border-radius: 50%;
            padding: 4px;
            z-index: 1;
            background: $red;
        }

        .banner-icon-circle {
            border: solid 1px rgba(255,255,255,0.8);
            border-radius: 50%;
            padding: 8px;
        }

        .banner-card-count {
            margin-left: auto;
            @include font-size(36px);
        }
        
        .banner-card-title {
            @include font-size(18px);
        }
        
        > .container-fluid {
            position: relative;
            padding-top: 70px;
        }

        &:before {
            background-size: cover;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .home-slider {
        border-radius: 3px;
        position: relative;

        &.hotitem-carousel-container {
            min-height: 600px;

            .home-slider-title { 
                @include font-size(16px);
            }
        }
    
        .z-depth-1-half:hover {
            box-shadow: 0 5px 11px 0 rgba(0,0,0,.1),0 4px 15px 0 rgba(0,0,0,.1) !important;
        }
        
        .hs-featured-image-wrapper {
            background-repeat: no-repeat;
            background-size: cover;
            height: 150px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }

        .hs-featured-image-link {
            display: block;
            height: 100%;
            color: $black;
        }

        .hs-featured-image > a {
            height: 100%;
            display: block;
        }
        
        .carousel-control-prev,
        .carousel-control-next {
            width: 36px;

            .hotitem-carousel-container & {
                max-height: 400px;
            }
        }

        &.hotitem-carousel-container {
            .carousel-control-prev,
            .carousel-control-next {
                max-height: 400px;
            }
        }

        .home-slider-title {
            @include font-size(18px);
            line-height: 20px;
            font-weight: 400;
        }
        
        .home-slider-meta {
            @include font-size(12px);
        }
        
        a.hs-featured-image-link:hover  {
            text-decoration: none;

            .dishBlog-title {
                text-decoration: underline;
            }
        }
    }

    .modal  {

        .itemContainer {
            border-bottom: 1px solid $gray;
            justify-content: space-between;
        }
    
        .substituted-item > .itemContainer {
            border: none;
        }
        
        .substituted-item {
            background-color: $blue-light-4;
            border: solid 1px $blue-light-3;
        }
        
        thead td {
            font-weight: 600;
        }
        
        .itemContainer__image {
            width: 90px;
            height: 90px;
        }
        
        .itemContainer__image-block {
            width: 90px;
        }
    }

    .btn-edit-slider {
        position: absolute;
        top: 3px;
        right: 0;
        background-color: #fff;
        padding: 1px;
        margin-right: 20px;
        z-index: 2;
    }

    #homeSliderCarouselContainer {
        height: 392px;

        .carousel-inner {
            height: 100%;

            .carousel-item {
                background-repeat: no-repeat;
                background-position: center;
                height: 100%;

                img {
                    margin-right: auto;
                    margin-left: auto;
                    display: block;
        
                    &.mb-image-cursor {
                        cursor: pointer;
                    }
                }

            }
        }

        .carousel-indicators {
            margin: 0;
            padding: 0;
        }

        .carousel-caption {
            background-color: rgba(50, 50, 50, 0.75);
            padding: 10px 8px 0px 15px;
            font-size: .8em;
            width: 55%;
            text-align: left;
            left: 0;
            bottom: 25px;

            @include media-breakpoint-down(md) {
                width: 75%;
            }

            a {
                color: $white;
                text-decoration: underline;
            }

            h5 {
                font-size: 1.3em;
                margin-bottom: 5px;      
            }
        }
    }

    #homeNews {
        padding: 12px 12px 0;

        h3 {
            font-size: 1.0em;
            margin: 6px 0 0;
        }
        
        p {
            margin: 0;
            font-size: 11px;
        }
        
        ul {
            margin: 0;
        }
    }
    

    
    #home_dishmag {
        height: 392px;
        border: solid 1px #ddd;

        img {
            width: 100%;
            display: block;
            cursor: pointer;
        }

        #dishImgLink {
            height: 100%;
            width: 100%;
        }
    }

    .homepage-events-col {
        @include media-breakpoint-up(lg) {
            flex: 0 0 20% !important;
        }
    }

}