/* ============ Form Styles ============== */
.txtBox {
	border: 1px solid #CCCCCC;
	color: #5A5655;
	font: 13px Arial, Helvetica, sans-serif;
	padding: 2px;
	width: 300px;
}

.txtBox2 {
	border: 1px solid #CCCCCC;
	color: #5A5655;
	font: 13px Arial, Helvetica, sans-serif;
	padding: 2px;
	width: 90%;
}

.selectBox {
	border: 1px solid #CCCCCC;
	color: #5A5655;
	font: 13px Arial, Helvetica, sans-serif;
	padding: 1px;
	width: 150px;
}

.selectBox2 {
	border: 1px solid #CCCCCC;
	color: #5A5655;
	font: 13px Arial, Helvetica, sans-serif;
	padding: 1px;
	width:90%;
}

.txtBoxShort {
	border: 1px solid #CCCCCC;
	color: #5A5655;
	font: 13px Arial, Helvetica, sans-serif;
	padding: 4px;
	width: 48px;
}

.txtBox:focus, .txtBoxShort:focus {
	border: 1px solid #EA8511;
}

.menuanalysis .formlabel {
	float: left;
	width: 50%;
	text-align: right;
	padding: 0 2px 6px 0;
	font-weight: bold;
}

.recipe .formlabel {
	clear:both;
	font-weight: bold;
}

.field-label {
	margin-bottom: 0.5rem;
	font-weight: 500;
}

.mb-label {
	@extend .fw-600;
	margin: 0;

	&:after {
		display: inline-block;
		content: ":";
		letter-spacing: 0.5rem;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 0.5rem;
	}
}

.mb-label-group,
.mb-inline-label-group {
	> label,
	> .label-group-label {
		@extend .fw-600;
		margin: 0;

		&:after {
			display: inline-block;
			content: ":";
			letter-spacing: 0.5rem;
		}

		&.check-box-label {
			&:after {
				content: ''
			}
		}
	}


	input[type]:not([type=radio]):not([type=checkbox]),
	select:not(.ui-datepicker-year), // datepicker select gets overridden without this
	textarea {
		@extend .form-control;
		@extend .form-control-sm;
	}

	select.ui-datepicker-year {
		width: inherit !important;
	}

	&.required-field,
	.required-field & {

		label,
		span.label-group-label {
			padding-right: 10px;
			position: relative;
			margin-right: 10px;

			&:before {
				color: $red-dark-1;
				font-weight: bold;
				content: '*';
				@include font-size(13px);
				padding-right: 2px;
				position : absolute;
				right : 0;
			}

			&:after {
				letter-spacing: initial;
			}
		}
	}

	.mb-plaintext:not(.toggle-input-text-container) {
		padding-right: 0;
		padding-left: 0;
	}

	.error-message,
	.error-message-use-any {
		flex-basis: 100%;
	}
}

.mb-label-group {
	> label,
	> .label-group-label {
		margin-bottom: 0.5rem;
		display: inline-block;
		white-space: nowrap;
	}
}

.mb-inline-label-group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	label,
	.label-group-label {
		margin-bottom: 0;
		white-space: nowrap;

		+ div,
		+ span,
		+ input,
		+ select,
		+ textarea {
			flex: 1;
			min-width: 0;
			@include media-breakpoint-down(sm) {
				margin-bottom: 0.5rem;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 0.5rem;
		}
	}

	&.align-items-start .mb-plaintext {
		padding-top: 0;
	}

	.error-message,
	.error-message-use-any {
		flex-basis: 100%;
	}

	@include media-breakpoint-down(sm) {
		display: block;
	}

	&.disabled,
	&.disabled a {
		color: $charcoal-500;
		cursor: default;
	}

	.has-toggle-edit & {
		.w-50px {
			width: 50px !important;
		}
		
		.w-75px {
			width: 75px !important;

		}
		
		.w-100px {
			width: 100px !important;
		}
		
		.w-150px {
			width: 150px !important;
		}
		
		.w-200px {
			width: 200px !important;
		}
		
		.w-250px {
			width: 250px !important;
		}
		
		.w-300px {
			width: 300px !important;
		}
	}

	&.inline-text-wrap {
		display: inline-block !important;
		
		label {
			display: inline-block;
	
			~ * {
				display: inline;
			}
		}
	}

	a {
		&:hover {
			label, span {
				text-decoration: underline;
				cursor: pointer;
				&:after {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}

	&.no-wrap {
		display: flex;
		flex-wrap: nowrap;

		label {
			margin-bottom: 0;

			~ * {
				margin-bottom: 0;
			}
		}

		
	}
}

.mb-label-data-row {
	margin-bottom: 0.15rem;

	&:last-child {
		margin-bottom: 0;
	}

	.mb-label-group,
	.mb-inline-label-group {
		margin-right: 0.5rem;
		display: inline-flex;

		label,
		.label-group-label {
			margin-right: 0;
			margin-bottom: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.mb-inline-label-group label ~ *,
	.mb-inline-label-group .label-group-label ~ * {
		margin-bottom: 0;
	}
}

.mb-label-data-col {
	.mb-label-group,
	.mb-inline-label-group {
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.mb-label-group label,
	.mb-label-group .label-group-label {
		margin-bottom: 0.15rem;
	}

	.mb-inline-label-group label,
	.mb-inline-label-group .label-group-label,
	.mb-inline-label-group label ~ *,
	.mb-inline-label-group .label-group-label ~ * {
		margin-bottom: 0;
	}
}

.mb-label-form-col {
	.mb-label-group,
	.mb-inline-label-group {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.mb-plaintext {
	@extend .form-control-plaintext;
	@extend .form-control-sm;
	min-height: calc(1.8125rem + 1px);

	.truncate-expanded & {
		height: auto !important;
	}

	> a {
		min-height: calc(1.8125rem - 0.5rem);

		.fs-lg & {
			line-height: 20px;
			@extend .fs-lg;
		}

		.fs-md & {
			line-height: 18px;
			@extend .fs-md;
		}
	}

	table & {
		height: initial;
	}
}

.mb-form-control {
	@extend .form-control;
	@extend .form-control-sm;
}

.form-control {
	&:read-only,
	&:disabled {
		border-color: $charcoal-300;
	}
}

.input-group,
.rdt {
	.form-control {
		@extend .form-control-sm;
	}
}

.col .field-label,
[class*='col-'] .field-label {
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.required-field {
	.field-label::after,
	.mb-label::after {
		color: $red-dark-1;
		font-weight: bold;
		content: '*';
		display: inline-block;
		margin-left: 5px;
		font-size: 13px;
	}
}

/* ========= Multiselect Styling ========= */
.ui-multiselect {
	padding: 4px 0 3px 4px;
	text-align: left;
	font-size: 12px;
}

.ui-multiselect span.ui-icon {
	float: right;
}

.ui-multiselect span {
	font-size: 0.875em;
}

.ui-multiselect-single .ui-multiselect-checkboxes input {
	position: absolute !important;
	top: auto !important;
	left: -9999px;
}

.ui-multiselect-single .ui-multiselect-checkboxes label {
	padding: 5px !important;
}

.ui-multiselect-header {
	margin-bottom: 5px;
	padding: 5px 0 5px 4px;
}

.ui-multiselect-header ul {
	font-size: 0.9em
}

.ui-multiselect-header ul li {
	float: left;
	padding: 0 10px 0 0
}

.ui-multiselect-header a {
	text-decoration: none
}

.ui-multiselect-header a:hover {
	text-decoration: underline
}

.ui-multiselect-header span.ui-icon {
	float: left
}

.ui-multiselect-header li.ui-multiselect-close {
	float: right;
	text-align: right;
	padding-right: 0
}

.ui-multiselect-menu {
	display: none;
	padding: 3px;
	position: absolute;
	z-index: 2000;
}

.ui-multiselect-menu {
	z-index : 85000 !important;
}

.mce-menu {
	z-index: 70200 !important;
}

.mce-container-tooltip {
	z-index: 80100 !important;
}

.ui-multiselect-checkboxes {
	position: relative /* fixes bug in IE6/7 */;
	overflow-y: scroll
}

.ui-multiselect-checkboxes label {
	cursor: default;
	display: block;
	border: 1px solid transparent;
	padding: 3px 1px
}

.ui-multiselect-checkboxes label input {
	position: relative;
	top: 1px
}

.ui-multiselect-checkboxes li {
	clear: both;
	font-size: 0.9em;
	padding-right: 3px
}

.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label {
	text-align: center;
	font-weight: bold;
	border-bottom: 1px solid
}

.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label a {
	display: block;
	padding: 3px;
	margin: 1px 0;
	text-decoration: none
}

.ui-multiselect-hasfilter ul {
	position: relative;
	top: 2px
}

.ui-multiselect-filter {
	float: left;
	margin-right: 10px;
	font-size: 0.6875em;
}

.ui-multiselect-filter input {
	width: 100px;
	font-size: 0.625em;
	margin-left: 5px;
	height: 15px;
	padding: 2px;
	border: 1px solid #292929;
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
}

.ui-multiselect.ttresident {
	padding: 0;
	margin: 0;
	float: right;
}


/* Forms - Imported from screen.css
---------------------------------------------------------------*/
input.readonly {
	background-color: #EEE;
	border: solid 1px #AAA;
	color: #AAA;
}

input[type=text].short, select.short, textarea.short {
	width: 64px;
}

input[type=text].normal, select.normal, textarea.normal {
	width: 128px;
}

input[type=text].long, select.long, textarea.long {
	width: 256px;
}

input[type=number].number-input-no-spinner::-webkit-outer-spin-button,
input[type=number].number-input-no-spinner::-webkit-inner-spin-button {
	-webkit-appearance : none;
}

input[type=number].number-input-no-spinner {
	-moz-appearance : textfield !important;
}

input.spacy, select.spacy, textarea.spacy {
	padding: 2px 4px;
}

input[readonly] {
	background-color: #EEE;
	border: solid 1px #AAA;
	color: $charcoal-800;
}

div.field {
	padding: 2px 0;
}

div.field label {
	display: inline-block;
	font-weight: bold;
	min-width: 120px;
	text-align: right;
}

div.field label:after {
	content: ':';
}

.mb-checkbox {
	position: relative;
    padding: 0;

    input[type=checkbox] {
        position: absolute;
		left: 0;
		opacity: 0;
        &:checked {
            + label:before {
                background-color: $blue;
                border-color: $blue;
                color: $white;
            }
        }
		&:not(:checked):focus,
		&:focus {
			+ label:before {
				box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 2px 4px rgba(13, 138, 238, .2);
			}
		}
        &:disabled {
            + label {
                cursor: not-allowed;

                &:before {
                    transform: scale(1);
                    border-color: $charcoal-400;
                    background-color: $charcoal-200;
                }
            }
        }
        &:checked:disabled {
            + label:before {
                cursor: not-allowed;
                transform: scale(1);
                background-color: $charcoal-400;
                border-color: $charcoal-400;
            }
        }
        + label {
            display: block;
            margin: 0.2em !important;
            cursor: pointer;
            padding: 0.2em;
            font-weight: 400;
            padding-left: 25px;
			position: relative;
			min-height: 20px;
            &:active:before {
                transform: scale(0);
            }
            &:before {
                font-family: "fontawesome";
                content: "\f00c";
                border: 0.1em solid $charcoal-500;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;
                padding: 2px;
                vertical-align: bottom;
                color: transparent;
                transition: .2s;
                line-height: 15px;
                @include font-size(14px);
                position: absolute;
				left: 0;       
				background: $white;     
			}

			.material-icons,
			.material-symbols-outlined {
				line-height: 20px;
				font-size : 1.25rem;
			}
			
			&.small {
				@include font-size(12px);
				margin-bottom: 0;
				padding-bottom: 0;
				padding-left: 20px;

				&:before {
					width: 15px;
					height: 15px;
					line-height: 9px;
					@include font-size(12px);
					padding: 2px 1px 2px 0.5px;
				}
			}
        }
    }
    li {
        list-style: none;
    }
}


.mb-radio {
	position: relative;
    padding: 0;
	list-style: none;

    input[type=radio] {
		position: absolute;
		left: 0;
		opacity: 0;
		
		+ label {
            display: block;
            margin: 0.2em !important;
            cursor: pointer;
			padding: 0.2em;
            font-weight: 400;
            padding-left: 25px;
			position: relative;
			min-height: 20px;

            &:active:before {
                transform: scale(0);
			}
			
			&:before {
				font-family: "fontawesome";
				content: "\f111";
				background: $white;
				display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;
                padding: 2px;
                vertical-align: bottom;
                color: transparent;
                transition: .2s;
                line-height: 15px;
                font-size: 14px;
                position: absolute;
				left: 0; 
				border: solid 1px $charcoal-400;
				border-radius: 50%;
				text-align: center;
			}

		}

        &:checked {
            + label:before {
				color: $blue;
            }
        }
		&:not(:checked):focus,
		&:focus {
			+ label:before {
				box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 2px 4px rgba(13, 138, 238, .2);
			}
		}
        &:disabled {
            + label {
                cursor: not-allowed;

                &:before {
                    transform: scale(1);
                    border-color: $charcoal-400;
                    background-color: $charcoal-200;
                }
            }
        }
        &:checked:disabled {
			+ label:before {
				color: $charcoal-500;
			}
		}
    }
    li {
        list-style: none;
    }
}

.mb-datatable-search {
	input[type=text] {
		border : none; 
		border-bottom : 1px solid #9DA1A3;	
	}

	.search-icon {
		position: absolute;
		top: 10px;
		right: 3px;
		color: $charcoal-500;
		font-size: 21px;
		cursor: pointer;
	}
}

.form-control.hasDatepicker,
.form-control.date,
.hasDatepicker .form-control {
	position: relative;
	background-position: calc(100% - 5px) center;
	background-repeat: no-repeat;
	background-image: url(/resources/images/calendar_icon_24px.png);

	&.form-control-sm {
		background-image: url(/resources/images/calendar_icon_20px.png);
	}

	&:disabled,
	&:read-only {
		cursor: pointer;
	}

	&:read-only {
		background-color: $white;
	}
}

.hasDatepicker .form-control {
	background-image: url(/resources/images/calendar_icon_20px.png);
}

.input-icon {
	position: absolute;
	top : 4px;
	right: 4px;
}

.tool-tip-indicator {
	top: -35px;
	left: 50%;
	margin-left: -15px;
	font-size: 20px;
	text-align: center;
	border : 1px solid $gray;
	border-radius: 4px;
	background-color: $gray;
	width: 30px;
	position: absolute;
	display: none;
	z-index: 9001;

	&.tool-tip-indicator-left {
		left: 10px;
		margin-left: 0;
	}

	&.checkbox-indicator {
		left: 30px;
	}

	* {
		margin-bottom: 3px;
	}

	&:before {
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $gray;
		content: '';
		top : 100%;
		position: absolute;
		left: 50%; 
		margin-left: -6px;

	}

	&.success {
		background-color: $green;
		color: #fff;
		border-color: $green;
		&:before {
			border-top-color: $green;
		}
	}

	&.error {
		background-color: $red;
		color: #fff;
		border-color: $red;
		&:before {
			border-top-color: $red;
		}
	}
}

.error-message,
.error-message-use-any {
	display: none;
	color : $red;
	font-size: small;
}

.invalid-input {
	input, select, textarea, .mb-plaintext {
		border-color: $red !important;
		border-width: 1px;
	}

	.mb-checkbox:not(.checkbox-list) input[type=checkbox] + label {
		color: $red;
		
		&:before {
			border-color: $red !important;
		}
	}

	.field-label, 
	label, 
	.label-group-label {
		color: $red !important;
	}

	.error-message {
		display: block !important;
	}
}

.mce-notification {
	z-index : 83000 !important;
}


// Fix Select element width in Analysis tabs, etc

table.align-middle-cells {
	td, th {
		vertical-align: inherit !important;
	}

	td {
		text-align: right;
		padding-right: 10px;
		padding-left: 10px;
	}
}

.has-cell-edit {
	max-width: 0;
}

.contains-edit {
	border-bottom: dotted 2px $charcoal-400;
}

.input-button {
	cursor: pointer;
	padding: 0 0.25rem;
}

.mb-select-dropdown {
	.mb-select-button {
		display: flex;
		width: 100%;
		background: $white;
		border: solid 1px $charcoal-400;
		text-align: left;
		align-items: center;
		@extend .btn;
		@extend .btn-sm;
	}

	.dropdown-menu {
		background: $white;
		width: 100%;
		min-width: 200px;
	}

	.mb-select-option-truncate {
		flex: 1;
	}

	.mb-select-button {

		.mb-select-option-text {
			flex: 1;
			display: flex;
			align-items: center;
			white-space: nowrap;
			max-width: calc(100% - 10px);
		}

		.mb-select-option-truncate {
			display: inline-block;
			@extend .text-truncate;
		}
	}

	.dropdown-item {
		padding: 0.5rem;
		white-space: initial;
		display: flex;
		align-items: center;

		&:hover {
			background: $surface-dark;
		}
	}

	.mb-select-option-icon {
		margin-left: auto;
		padding-right: 0.5rem;
		padding-left: 0.5rem;

		.material-icons {
			line-height: inherit;
			font-size: 0.9rem !important;
		}
	}
}

.mb-input-group {
	padding: 0 0.35rem !important; 
}

.mb-label-link {
	color: $blue;

	&:hover {
		text-decoration: underline;
	}
}