/* Recipe Advanced Search */
.ui-multiselect.recipesearch {
	width: 175px !important;
}

#recipenalysis, #nutritionanalysis {
	width: 175px;
}

.advancedrecipesearch {
	margin-bottom: 15px;
}

.createnew {
	min-width: 50px;
	margin: 0 35px 0 0;
	font-weight: normal;
	padding: 4px 10px;
	background: #ccc;
	color: #333;
	font-size: 14px;
	border-radius: 5px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
}

.createnew:hover {
	background: #666;
	color: white;
}

.ui-dialog #recipetabs li a {
	background-color: #E0E0E0;
}

.ui-dialog #recipetabs li a:hover {
	background-color: #fff;
	padding: 8px 8px;
}

.ui-dialog #recipetabs li.active a {
	background-color: #fff;
	padding: 8px 8px 9px 8px;
}

#recipetabs_content_container, #platetabs_content_container {
	border-top: 1px solid #ccc;
	border-top: none;
	padding: 10px 0;
}

.recipetab_content {
	display: none;
}

/* culinary recipes css
----------------------------------------------------------*/
.sidebar_box {
    padding: 12px;
    margin-bottom: 20px;
    font-weight: bold;
    color: gray;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}
.sidebar_box p a {
    color: silver;
}
.sidebar_box p {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 8px;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 8px;
}
.sidebar_box h2 {
    font-size: 18px;
    margin-bottom: 6px;
    color: gray;
    font-weight: normal;
    font-style: normal;
}
.search_field {
    font-size: 16px;
    padding: 4px;
    border: 1px solid #ccc;
    width: 100%;
}
.search_button {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    background: #ccc;
    color: #333;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    border: none;
    margin-top: 5px;
}
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.recipe-item-image {
    display: block;
    height: 150px;
    width: 100%;
    border: 1px solid white;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
        border: 1px solid #0000CC;
    }

    img {
        border: none;
        margin: 0 auto;
        overflow: hidden;
    }
}

.recipe-item-title {
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    a {
        color: inherit;

        &:hover {
            color: #0000CC;
            text-decoration: underline;
        }
    }
}

.recipe-item-lower-section {
    position: relative;
    height: 25px;
    padding-top: 5px;
}

.recipe-item-keywords {
    color: silver;
    display: block;
    font-size: 11px;
    margin-right: 50px;
    line-height: 20px;
}

.recipe-item-pin-button {
    text-align: right;
    width: 40px;
    position: absolute;
    right: 0;
    top: 5px;
}

.recipe-detail-image {
    width: auto;
    max-width: 100%;
    max-height: 350px;

    @include media-breakpoint-down(sm) {
        max-height: 275px;
        margin-bottom: 16px;
    }
}

.recipe-detail-info {
    h2 {
        font-size: 24px;
        margin-bottom: 4px;
        line-height: 28px;
    }

    h3 {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 700;
    }

    h4 {
        color: gray;
        font-size: 12px;
        font-weight: bold;
    }

    h5 {
        color: gray;
        font-size: 9px;
    }
}

#recipesList .child .material-icons {
    display: none;
}