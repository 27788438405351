/* When comman delimited between items are needed */
.comma-sep span:after {
	content: ", ";
}

.comma-sep span:last-child:after {
	content: "";
}


.text-linethrough {
	text-decoration: line-through;
	color: $red-dark-1;
}
// Surface Elevation

.elevation {
	&-1 {
	box-shadow: 0 1px 1px 0 rgba(0,0,0,0.07), 0 2px 1px -1px rgba(0,0,0,0.06), 0 1px 3px 0 rgba(0,0,0,0.1);
	}
	&-2 {
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.07), 0 3px 1px -2px rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.1);
	}
	&-3 {
		box-shadow: 0 3px 4px 0 rgba(0,0,0,0.07), 0 3px 3px -2px rgba(0,0,0,0.06), 0 1px 8px 0 rgba(0,0,0,0.1);
	}
	&-4 {
		box-shadow: 0 4px 5px 0 rgba(0,0,0,0.07), 0 1px 10px 0 rgba(0,0,0,0.06), 0 2px 4px -1px rgba(0,0,0,0.1);
	}
	&-5 {
		box-shadow: 0 5px 7.5px 0 rgba(0,0,0,0.07), 0 1px 14px 0 rgba(0,0,0,0.06), 0 2px 5px -1px rgba(0,0,0,0.1);
	}
	&-6 {
		box-shadow: 0 6px 10px 0 rgba(0,0,0,0.07), 0 1px 18px 0 rgba(0,0,0,0.06), 0 3px 5px -1px rgba(0,0,0,0.1);
	}
	&-8 {
		box-shadow: 0 8px 10px 1px rgba(0,0,0,0.07), 0 3px 14px 2px rgba(0,0,0,0.06), 0 5px 5px -3px rgba(0,0,0,0.1);
	}
	&-12 {
		box-shadow: 0 12px 17px 2px rgba(0,0,0,0.07), 0 5px 22px 4px rgba(0,0,0,0.06), 0 7px 8px -4px rgba(0,0,0,0.1);
	}
	&-16 {
		box-shadow: 0 16px 24px 2px rgba(0,0,0,0.07), 0 6px 30px 5px rgba(0,0,0,0.06), 0 8px 10px -5px rgba(0,0,0,0.1);
	}
	&-24 {
		box-shadow: 0 24px 38px 3px rgba(0,0,0,0.07), 0 9px 46px 8px rgba(0,0,0,0.06), 0 11px 15px -7px rgba(0,0,0,0.1);
	}
}


.alert {
	color: $black;
	border: none;
	border-radius: 3px;

	.alert-icon {
		padding: 10px;
		line-height: 26px;

		.notification-icon {
			transform: translateY(15%);
			@include font-size(24px);
		}

		.alert-body {
			@include font-size(16px);
		}
	}

	.alert-title {

		&.alert-flex {
			display: flex;
			align-items: center;
		}

		a,
		button {
			color: $black;
			text-decoration: underline;
			margin-left: 0.25rem;
		}

		button {
			@include font-size(13px);
			line-height: 1.3rem;
		}
	}
}

.alert-priority-1 {
	background: $red-light-3;

	.alert-icon {
		background: $red-dark-2;
		color: $white;
	}
}

.alert-priority-2 {
	background: $orange-light-2;

	.alert-icon {
		background: $orange;
	}
}

.alert-priority-3 {
	background: $yellow-light-3;

	.alert-icon {
		background: $mb-gold-2;
	}
}

.alert-priority-4 {
	background: $blue-light-3;

	.alert-icon {
		background: $blue;
		color: $white;
	}
}

.alert-success {
	background-color: $green-light-3;

	.alert-icon {
		background: $green;
		color: $white;
	}
}

.opaque {
	opacity: 0.7;
}

.d-list-item {
	display: list-item !important;
}

.m-neg {
	margin-right: -15px;
	margin-left: -15px;
}

.mt-neg {
	margin-top: -15px;
}

.mr-neg {
	margin-right: -15px;
}

.ml-neg {
	margin-left: -15px;
}

.fw-300 {
	font-weight: 300 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}


.fw-700 {
	font-weight: 700 !important;
}

.fw-800 {
	font-weight: 800 !important;
}

.mw-960 {
	max-width: 960px !important;
}

.fs-xs {
	@include font-size(11px, true);
}

.fs-sm {
	@include font-size(13px, true);
}

.fs-md {
	@include font-size(16px, true);
}

.fs-md-lg {
	@include font-size(18px, true);
}

.fs-lg {
	@include font-size(20px, true);
}

.fs-xl {
	@include font-size(24px, true);
}

.fs-2xl {
	@include font-size(28px, true);
}

.gap-half {
	gap: 0.5rem;
}

.gap-1 {
	gap: 1rem;
}

.cursor-hand {
	cursor: pointer;
}

.d-flex1 {
    -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;         /* OLD - Firefox 19- */
    -webkit-flex: 1;          /* Chrome */
    -ms-flex: 1;              /* IE 10 */
    flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  }

.left-rad {
	border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.right-rad {
	border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.full-rad {
	border-radius: 3px;
}

.border-2x {
	border-width: 2px !important;
}

.p-t-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.lh-default {
	line-height: 1.2;
}

/* Utilities */ 

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.upcaret {
	display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-bottom: 4px dashed;
    border-bottom: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.align-self-center {
	align-self: center !important;
}

[role=button] {
    cursor: pointer;
}

.errorInput {
	outline: none;
	border: 1px solid $red !important;
}

.requiredInput {
	background-color: #FFFAE5 !important;
	border: 1px solid $gray !important;
}

.limited-quantity {
	color: $orange;
}

.hiddenNotImportant {
	display: none;
}

.defaultAnchor, .defaultAnchor:visited {
	color: $black;
}

.border-gold {
	padding-bottom: 4px;
	border-bottom: 2px solid $mb-gold-2;
}

.separator {
	width: 15%;
	height: 2px;
	background-color: $gray;
	margin-top: 10px;
	margin-bottom: 10px;
}

.odd {
	background-color: $surface-light-grey !important;
}

.even {
	background-color: $white !important;
}

.striped-rows li:nth-child(odd),
.striped-rows div:nth-child(odd) {
	background-color: $surface-light-grey;
}


.dataTables_wrapper .dataTable tbody tr.dt-active-row,
.dataTables_wrapper .dataTable tbody tr.dt-active-row:hover {
	background-color: $green-light-3 !important;
}

.pointerMouse {
	cursor: pointer;
}

.requiredFieldLbl::after {
	content: ' *';
	color: $red;
}

.hidden {
	display: none;
}

a.button_medium {
	font-weight: normal;
	padding: 8px 10px 7px;
	background: $gray;
	color: $black;
	font-size: 15px;
	border-radius: 5px;
	text-transform: uppercase;
	text-align: center;
}
.z-depth-1-half {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.08),0 4px 15px 0 rgba(0,0,0,.05) !important;
}

.clear {
	clear: both;
}

.clearfix:after {
	content: " "; /* Older browser do not support empty content */
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}

.right {
	float: right;
}

.pull-left {
	float: left;
}

.button-bar {
	margin-bottom: 15px;
}

img[src=nullpx] {
	display: none;
}

.req {
	color: $red
}

/* Color of invalid field */
.has-error {
	color: $red !important;
}
/* Color of invalid field */
.has-input-error {
	border-color: $red !important;
}

.zero-gutters {

	padding-right: 0;
	padding-left: 0;

	.row {
		margin-right: 0;
		margin-left: 0;

		> .col {
			padding-right: 0;
			padding-left: 0;
		}

		> [class*="col-"]:first-child {
			padding-left: 0;
		}

		> [class*="col-"]:last-child {
			padding-right: 0;
		}
	}
}

.centered-content-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ws-nowrap {
	white-space: nowrap !important;
}

.ws-initial {
	white-space: initial !important;
}

// Icon Misc Styles 

.filter-icon {
	@include font-size(20px);
}

.showHideHelper[aria-expanded=false]:before {
	content: "Show ";
}

.showHideHelper[aria-expanded=true]:before {
	content: "Hide ";
}

// Success / Fail update classes 

.update-toggle {
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}
.success-update,
.dataTables_wrapper .dataTable tbody tr.success-update:hover {
	background-color: $green-light-3 !important;
}

.fail-update,
.danger-update {
	background-color: $red-light-3 !important;
}

.diet-match-highlight {
	background-color: $green-highlight !important;
}

// Icon Helper 

.delete-icon {
	color: $charcoal-600;
}

body.modal-open .tooltip {
	z-index: 84100;
}

// Misc Width Helpers

.w-50px {
	max-width: 50px;
	min-width: 50px;
	width: 100% !important;
}

.w-75px {
	max-width: 75px;
	min-width: 75px;
	width: 100% !important;
}

.w-100px {
	max-width: 100px;
	min-width: 100px;
	width: 100% !important;
}

.w-125px {
	max-width: 125px;
	min-width: 125px;
	width: 100% !important;
}

.w-150px {
	max-width: 150px;
	min-width: 150px;
	width: 100% !important;
}

.w-200px {
	max-width: 200px;
	min-width: 200px;
	width: 100% !important;
}

.w-250px {
	max-width: 250px;
	min-width: 250px;
	width: 100% !important;
}

.w-md-250px {
	@include media-breakpoint-up(md) {
		max-width: 250px;
		min-width: 250px;
		width: 100% !important;
	}
}

.w-300px {
	max-width: 300px;
	min-width: 300px;
	width: 100% !important;
}

.mw-300px {
	max-width: 300px;
}

.w-20pct {
	width: 20% !important;
}

.w-25pct {
	width: 25% !important;
}

.h-115px {
	max-height: 115px;
}

select.w-150px,
select.w-150px + .ui-widget {
	width: 150px !important;
}

select.w-200px,
select.w-200px + .ui-widget {
	width: 200px !important;
}

select.w-250px,
select.w-250px + .ui-widget {
	width: 250px !important;
}

.flex-basis-100 {
	flex-basis: 100%;
}

.flex-basis-md-100 {
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

@include media-breakpoint-up(md) {
	.ui-autocomplete.autocomplete-w-300 {
		width: 300px !important
	}
}


// img {
// 	max-width: 100%;
// }

// Makes element a non target for pointer events
.pointer-events-none {
	pointer-events: none !important;
}

.notransition {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
  }

.hide-horizontal-simplebar {
	.simplebar-track.horizontal {
		display : none !important;
	}
}

.no-link-style {
	text-decoration: none;

	&:hover {
		text-decoration: none !important;
	}
}

.interactive-element-link {
	color: $primary-color;
	cursor: pointer;
}

.check-icon {
	color: $primary-color;
	font-weight: bold;
	display: inline-block;
}

.row-link tbody tr {
	cursor: pointer;

	&:hover {
		background: $light-gray;
	}
}

.disable-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255,255,255, 0.7);
	z-index: 1000
}

.show-more-container {
	flex: initial !important;
	order: 1;
}

.show-less-container {
	flex-basis: 100% !important;
	text-align: right !important;
}

.toggle-link-section {
	display: inline-flex;
	align-items: center;
	color: $charcoal-700;

	&:hover {
		color: $charcoal-700;
		text-decoration: none;

		span:first-child {
			text-decoration: underline;
		}
	}
	
	&:before {
		font-family: 'fontawesome';
		content: "\f0da";
		margin-right: 5px;
		display: inline-block;
		width: 8px;
		color: $blue;
	}

	&.open:before {
		content: "\f0d7";
	}



}

.interactive-image {
	cursor: pointer;
}

// Added for consistent use of Show More / Less link
.show-more-container {

	a.toggle-truncated-text:before {
		content: "Show more";
	}
}

.show-less-container {

	a.toggle-truncated-text:before {
		content: "Show less";
	}
}

a.toggle-truncated-text {
	@include font-size(11px);
}

// Psuedo element Flag helpers

.has-perm-price-flag:after {
	position: absolute;
	top: 0;
	content: "*";
}

.signature-image {
	max-width: 200px;
	width: 100%;
}