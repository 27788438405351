.mb-item-name {
    @include font-size(17px, true);
    font-weight: 500 !important;
    color: #0d8aee !important;
}

.mb-item-brand-name {
    color: $charcoal-500;
    @include font-size(14px);
}

.mb-manage-og-link {
    color: $charcoal-900;

    &:hover {
        color: $charcoal-900;
    }
}

.mb-item-image {
    max-width: 96px;
    width: 100%;
}

.mb-item-meta {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        margin-right: 0.5rem;

        .item-label {
            font-weight: 500;
        }
    }
}

.emphasized-data {
    display: flex;

    .section {
        text-align: center;
    
        label {
            text-transform: uppercase;
            @include font-size(12px);
            font-weight: 600;
            line-height: 16px;
        }
    
        p {
            color: #202325;
            @include font-size(24px);
            font-weight: 600;
            line-height: 28px;
            margin : 0;
        }
    }
}
