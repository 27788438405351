.link-group {
    margin-bottom: 1rem;

    .link-group-header {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }

    ul {
        margin: 0;

        li {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}

.page-heading-wrapper,
.mb-page-heading-wrapper {
    display: flex;
    
    .page-heading {
        flex: 1;
        @extend .page-heading;
    }

    a.return-button, button.return-button {
        @extend .panel-button;
        border-right: solid 1px $gray;
    }

    &.dark-heading {
        .page-heading {
            background-color: $charcoal-750 !important;
            border-bottom: 1px solid $charcoal-700;
            color: $white;
        }

        a.return-button, button.return-button {
            background: $charcoal-700;
            border-color: $charcoal-700;
            border-right: solid 1px $charcoal-600;
            color: $white;
        }
    }
}

.mb-panel {
    &:not(.open) {
        + .container-with-panel-content .page-heading {
            padding-left: 70px !important;
        }
    }

    @include media-breakpoint-down(md) {
        &.open {
            + .container-with-panel-content .page-heading {
                padding-left: 70px !important;
            }
        }
    }
}

.page-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
    padding: 0 1rem;

    &:not(.page-heading-plain) {
        border-bottom: 1px solid $gray;
        background-color: $surface-base;
    }

    &.no-filter {
        #mbGlobalOverlayContainer & {
            @include media-breakpoint-down(md) {
                height: auto !important;
                padding: 1rem;
            }
        }
    }

    h1, h2, p {
        margin: 0;
        padding: 0;

        &.responsive-page-heading-text {
            @include media-breakpoint-down(xs) {
                @include font-size(14px);
            }
        }
    }

    .page-heading-actions {
        align-self: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .dropdown-menu {
            z-index: 10000;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }

}

.main-content-margin-fix {
    margin: -20px -15px 0 -20px;
}

.container-with-panel {
    display: flex;
    margin: -20px -15px 0 -15px;

    .container-with-panel-content {
        overflow: auto;
        width: 100%;
        flex: 1;
    }
}
