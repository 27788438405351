/* videojs custom css
----------------------------------------------------------*/
.video_content {
	padding: 20px;
}
.video_wrapper {
	float:left;
	width:70%;
}
.video_container {
	text-align: left;
	margin-top: -2em;
	height: 70em;
}

.vjs-menu li {
    text-transform: initial;
}
.video-js .vjs-big-play-button {
 	font-size: 5em;
 	color: #FFEA00;
    border: .04em solid #fff;
}

.video_info {
	float:right;
	width: 30%;
	padding:0 2%;
}
.video_info img {
	max-width: 100%;
}
.vid_player_category {
	font-size: 12px;
	margin: 25px 0 0;
	font-weight: bold;
	color: gray;
}
.vid_player_title {
	font-size: 18px;
	margin: 8px 0 0;
	font-weight: bold;
	color: yellow;
}
.vid_player_credit {
	font-size: 14px;
	margin: 6px 0 0;
	font-weight: bold;
	color: #fff;
}
.vid_player_time {
	font-size: 10px;
	margin: 10px 0 0;
	color: white;
}
.vid_player_description {
	font-size: 12px;
	margin: 10px 0 0;
	color: white;
}
.vid_player_hyperlink {
	color: yellow;
	font-weight: bold;
	font-size: 12px;
}

.video_gallery .row {
	padding:15px 0;
}
.video_gallery h3 a {
	color:inherit;
}

.resource_tags {
	list-style-type: none; padding: 0;
}
.resource_tags li {
	display: inline-block;
}
.resource_tags li a {
	display: inline-block; background: #ccc; padding: 2px 6px; margin: 0 5px 0 0; font-size: 10px; color: white; font-weight: bold; border-radius: 2px;
}
.resource_tags li a:hover {
	background: #6666cc;text-decoration:none;
}
.video_ceu_note {
	font-size: 12px; color: #ccc; font-style: italic;
}
.tags_list {
	background: #FAFAFB;
	border: 1px solid #C1C7CC;
	padding: 16px;
	margin: 0 0 20px;
}
.tags_list h2 {
	font-size: 16px;
	margin: 0 0 14px;
}
.tags_list ul {
	margin: 0;
}
.tags_list .resource_tags li a {
	font-size: 12px; border-radius: 3px; margin: 0 10px 0 0;
}
.tags_list .resource_tags li.tag_selected a {
	background:#fbbf0e;
}
@media only screen and (min-width: 768px) {
	.tags_list {
		margin: 15px 0 20px;
	}
	.tags_list .resource_tags li {
		display: block;
	}
	.tags_list .resource_tags li a {
		display: block; margin: 10px 0 0; font-size: 14px; border-radius: 4px;
	}
}