a.checkbox-list-select-link, 
        button.checkbox-list-select-link,
        button.checkbox-list-reset-default {
    text-transform: none !important;
    color: $blue !important;
    cursor: pointer;
}

.filter-tag-list {
    @extend .list-unstyled;
    display: flex;
    flex-wrap: wrap;

    & > li {
        margin-right: 0.25rem;

        & > .filter-tag-link {
            @extend .badge;
            background-color: $gray;
            color: $white;
            padding: 0.25rem;

            &:hover {
                background-color: $charcoal-500;
            }

            &:focus {
                outline: none;
            }
        }
    }
}

.checkboxlist-invalid {
    label {
        color: $red;
    }
}