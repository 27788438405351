$report_container_width: 850px;

#reports ul {
	list-style-type: none;
}

#virtualDietModalToggle,
.virtual-diet-toggle {
	span {
		color: $blue;
		vertical-align: middle;
	
		&:hover {
			text-decoration: underline;
		}
	}

	&:hover em.material-icons {
		text-decoration: none;
	}
}

.report-item {
	max-width: $report_container_width;
	margin: 0 auto;
}

.page-heading-report-wrapper {
	max-width: $report_container_width;
	min-height: 200px;
	position: relative;
}

.report-parameters-wrapper {
	max-width: $report_container_width;
}

.mb-report-filters {
	min-height: 230px;
}