.mb-image-carousel-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .mb-image-carousel {
        flex: 1;
        position: relative;
    }

    .carousel-item {
        text-align: center;
        
        img {
            width: 70%;
            margin-right: auto;
            margin-left: auto;
            display: block;

            &.mb-image-cursor {
                cursor: pointer;
            }
        }
    }

    .mb-item-carousel-indicator-prev,
    .mb-item-carousel-indicator-next {
        display: none;
    }
}

.mb-image-carousel-modal {
    position: fixed;
    z-index: 2;
    top: 5%;
    right: 5%;
    left: 5%;
    bottom: 5%;

    .mb-image-carousel {
        height: 90%;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        max-width: 800px;

        .carousel {
            display: flex;
            align-items: center;
            height: 100%;

            .carousel-control-next,
            .carousel-control-prev {
                background: rgba(255,255,255,0.7);
                opacity: 1;
                width: 60px;
                height: 50px;
                top: calc(50% - 25px);
                display: none;
            }

            .carousel-control-prev {
                order: 0;
            }

            .carousel-inner {
                order: 1;
                flex: 1;
                background: $white;
                height: 100%;
                width: 100%;
            }

            .carousel-control-next {
                order: 2;
            }
        }

        .modal-image-close-btn {
            background: rgba(0,0,0,0.8);
            padding: 2px;
            top: 10px;
            right: 10px;
            z-index: 2;
            line-height: 0;
            display: none;
        }

        &:hover {
            .modal-image-close-btn,
            .carousel-control-next,
            .carousel-control-prev {
                display: flex;
            }
        }
    }

    .mb-image-carousel-modal-body {
        position: relative;
        z-index: 1;
        height: 90vh;

        .mb-image-carousel-container {
            height: 100%;
        }

        .carousel-item {
            height: 100%; 
            display: flex;
            align-items: center;

            img {
                margin-right: auto;
                margin-left: auto;
                display: block;
                height: max-content;
                width: 100%;
            }
        }
    }

    .mb-item-carousel-indicators {
        align-items: center;
        border-top: none;
        border-bottom: none;

        .mb-item-carousel-indicator {
            width: 80px;
            min-width: 80px;
            height: 100%;
            opacity: 0.8;
            border: solid 1px transparent;
            padding: 0;

            &.active-indicator {
                border-color: $white;
            }
        }

        .mb-item-carousel-indicators-wrap {
            white-space: nowrap;
            flex-wrap: nowrap;
            overflow: hidden;
            height: 80px;
        }

        &.has-scroll-buttons {
            .mb-item-carousel-indicator-prev,
            .mb-item-carousel-indicator-next {
                display: initial;
            }

            .mb-item-carousel-indicators-wrap {
                justify-content: start;
            }
        }
    }

    &::after {
        position: fixed; 
        background: $black;
        opacity: 0.5;
        content: "";
        top: 0;
        right:0;
        bottom: 0;
        left: 0;
    }
}

.mb-item-carousel-indicators {
    display: flex;
    border-top: solid 1px $surface-dark;
    border-bottom: solid 1px $surface-dark;

    .mb-item-carousel-indicators-wrap {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        gap: 0.5rem;
        flex: 1;
    }

    .mb-item-carousel-indicator {
        opacity: 0.5;
        width: 40px;
        height: 40px;
        overflow: hidden;

        &.active-indicator {
            opacity: 1 !important;
        }

        img {
            height: 100%;
        }
    }
}

.mb-item-suggestion-carousel {
    width: 99%;

    .mb-item-carousel-container {
        .carousel-inner {
            min-height: 190px;
        }
    }
}

.mb-item-carousel-container {
    .carousel-inner {
        min-height: 300px;

        .carousel-item {
            height: 100%;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 40px;
    }

    .carousel-control-next {
        right: -10px;
    }

    .carousel-control-prev {
        left: -10px;
    }
}

.carousel-item-start + .carousel-item-start:not(.active),
.carousel-item-start.carousel-item-next:first-child  {
    transform: none !important;
}

.carousel-item + .carousel-item-prev.carousel-item-end,
.carousel-item-end.carousel-item-prev:first-child  {
    transform: none !important;
}