#toast-container {
    > div {
        opacity: 0.95;
        min-width: 400px;
    }

    > .toast {
        background-image: none !important;
        position: relative;
        box-shadow: none;
    }

    > .toast.toast-info {
        background-color: #BBDFF9;
    }
    
    > .toast.toast-error {
        background-color: #FFCDD2;
    }
    
    > .toast.toast-success {
        background-color: #CBE9C5;
    }
    
    > .toast.toast-warning {
        background-color: #FFF7BE;
    }

    > .toast:before {
        position: absolute;
        font-family: "Material Icons";
        font-size: 24px;
        line-height: 18px;
        color: #FFF;
        top:0;
        bottom:0;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
    }
    
    > .toast-warning:before {
        content: "warning";
        background-color: #FFD200;
    }
    > .toast-error:before {
        content: "error";
        background-color: #C52424;
    }
    > .toast-info:before {
        content: "info";
        background-color: #0069BC;
    }
    > .toast-success:before {
        content: "check_circle";
        background-color: #2D851D;
    }
    
    .toast-message,
    .toast-title {
        color: #202325;
    }
    
}

.mb-toast-container {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 70200;
    min-width: 400px;

    .mb-toast-header {
        color: $body-color;
        padding: 0.5rem;
        font-weight: 500;
        gap: 0.5rem;

        &::before,
        .btn-close::after {
            font-family: "Material Icons";
        }

        &::before {
            @include font-size(24px);
        }

        .btn-close {
            margin-left: auto;

            &::after {
                content: "close";
            }
        }
    }

    .mb-toast-body {
        text-align: left;
    }

    &.mb-toast-success {
        .mb-toast-header {
            color: $black;
            background : $green-light-1;

            &::before {
                content : 'check_circle';
                color: $white;
            }
        }
        
        .mb-toast-body {
            background: $green-light-3;
        }
    }

    &.mb-toast-error {
        .mb-toast-header {
            color: $white;
            background: $red-light-1;

            &::before {
                content: "error";
            }
        }
        
        .mb-toast-body {
            background: $red-light-3;
        }
    }

    &.mb-toast-warn {
        .mb-toast-header {
            background: $yellow-light-2;

            &::before {
                content: "warning";
            }
        }
        
        .mb-toast-body {
            background: $yellow-light-3;
        }  
    }

    &.mb-toast-info {
        .mb-toast-header {
            background: $blue-light-2;

            &::before {
                content: "info";
            }
        }
        
        .mb-toast-body {
            background: $blue-light-3;
        }  
    }
}