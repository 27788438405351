.refinementTermText {
    margin: 2px;
    padding: 2px 5px;
    color: $white;
    background-color: $blue;
    border-radius: 10px;
    display: inline-block;
}

.itemsearch {
    @include transition(0.35s ease);

    .search-results-wrapper {
        display: -ms-flexbox;
        display: flex;

        #filtersContainer {
            margin-left: -16px;

            .left-panel.collapsed .left-panel-menu__filter {
                display: none;
            }
        }
    }

    &.main-right__itemsearch {

        .search-results-container {

            .search-results-wrap,
            #pagingWrap {
                @include media-breakpoint-up(lg) {
                    width: calc(100% + 60px);
                }
            }
        }

        &.active-action-panel {
            .search-results-container {
                .search-results-wrap,
                #pagingWrap {
                    @include media-breakpoint-up(lg) {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    &.main-left__itemsearch.main-right__itemsearch .search-results-container {
        .search-results-wrap,
        #pagingWrap {
            width: calc(100% + 110px);
        }
    }

    &.main-left__itemsearch [data-simplebar] {
        position: initial;
    }


    .thinResultsContainer {
        width: 100%;
        flex: 1;
        min-width: 0;
    } 

    .search-results-container {

        .heading, 
        .itemdetails .heading {
            @include font-size(28px);
            font-weight: lighter;
        }

        .heading .sortingContainer {
            @include font-size(12px);
            font-weight: 400;
            letter-spacing: 0.035em;
            line-height: 30px;
            color: $charcoal-600;
        }

        > .position-relative #searchResults {
            padding-right: 10px;
        }
    }

    #resultsContainer.analysis-panel-closed #searchResults {
        width: calc(100% + 15px);
    }

    .itemSearchPageSize {
        border: 0;
        font-weight: bold;
        background: $white;
        text-align-last: center;
    }
    
    #footerpaging {
        display: flex;
    }
    
    .resultPaging-left {
        padding: 8px;
        white-space: nowrap;
    }
    
    .resultPaging-right {
        text-align: right;
        padding: 8px;
        flex: 1;
        white-space: nowrap;

        > span {
            display: inline-block;
            padding-right: 8px;
            padding-left: 4px;
        }
    
    }
    
    .resultPaging__items-per-page {
        line-height: 22px;
    }
    
    .double-arrow {
        @include font-size(26px);
        font-weight: 600;
    }
    
    .itemsearch__heading {
        padding: 11px 16px;
        line-height: 17px;
        background-color: $surface-base;
        border-bottom: solid 1px $charcoal-300;

        h2 {
            line-height: 32px;
            margin: 0;
            @include font-size(24px);
        }

        &.filter-panel-offset {
            margin-left: 56px;
        }

        @include media-breakpoint-down(md) {
            margin-left: 56px; 
        }

        @include media-breakpoint-up(lg) {
            #clearCartBtnMobile {
                display: none !important;
            }
        }
    }

    #resultsContainer {

        &.analysis-panel-closed .itemsearch__heading  {
            margin-right: 40px;
        }
    
        .itemsearch__heading {
            @include media-breakpoint-down(md) {
                margin-right: 40px !important;
            }
        }
        
    }

    &.active-action-panel {
        
        .analysis-panel-closed {
            margin-right: 0 !important;
        }

        #resultsContainer.analysis-panel-closed .itemsearch__heading  {
            margin-right: 0px;
        }

        @include media-breakpoint-down(md) {
            .itemsearch__heading {
                margin-right: -15px !important;
            }

            #itemSearchActionPanel {
                margin-right: 0 !important;
            }
            
        }
    }
    
    .main-left__itemsearch {

        .itemsearch__heading {
            border-left: solid 1px $gray;

            .itemsearch__heading--left {
                left: 59px;
            }
        }

    }
    
    .filter-flyout__text {
        text-transform: uppercase;
        padding-bottom: 4px;
        border-bottom: 1px solid $charcoal-500;
        color: $charcoal-500;
        @include font-size(12px);
        cursor: pointer;
    
        &.flyout-slide:before {
            font-family: "fontawesome";
            display: inline-block;
            width: 10px;
            content: "\f0da";
            margin-right: 2px;
        }
    
        &.flyout-slide.flyout-open:before {
            content: "\f0d7";
        }
        
    }
    
    .filter-flyout__section-list {
        padding: 0;
        max-height: 200px;
        overflow-y: auto;
        border-bottom: solid 1px $light-gray;
    }
    
    .paginationButtons {
        width: 30px;
        height: 30px;
        border: 1px solid $gray;
        background-color: $surface-darker;
        padding: 0;
        vertical-align: middle;
        
        &:disabled {
            background-color: $surface-base;
            border: 1px solid $surface-darker;
    
            > i {
                color: $gray;
            }
    
        }
    }
    
    .catalog-results-header h4,
    .priority-results-header h4 {
        margin: 0;
        margin-right: 15px;
        @include font-size(20px);
        padding-top: 16px;
        padding-bottom: 16px;
    }
    
    .savedCartsBtn {
        border: 1px solid $charcoal-500;
        font-weight: 600;
    }
    
    button.listViewToggle.listActive,
    button.items-toggle.toggle-active {
        opacity: 1;
        background-color: $blue;
        border-color: $blue;

        em {
            color: $white;
        }
    }
    
    #emptyResults {
        display: none;
    }
    
    #resetBtnMobile {
        display: none;
    }
    
    .applyFilters {
        display: none;
    }
    
    .linecontainer {
        margin: 16px 16px 0 16px;
        overflow: auto;
    
        .left {
            width: 35%;
            float: left;
        }
    
        .right {
            width: 65%;
            float: right;
        }
    
        label {
            font-weight: bold;
            margin: 0;
        }
    }

    #cartPanel {
        .panel-header-internal {
            right: -15px;
        }
    }


    
    #cartPanelRecentItems {
    
        &.expanded {
    
            .hidden {
                display: table-row !important;
            }
    
            .display-condensed {
                display: none;
            }
    
            .display-expanded {
                display: inline-block;
            }
    
        }
    
        .display-expanded {
            display: none;
        }

        span.display-condensed,
        span.display-expanded {
            color: $charcoal-500;
            @include font-size(12px);
            line-height: 16px;
            margin: 0;
            text-transform: uppercase;
        }
    
    }

    .clear-cart-button {
        text-decoration: underline;
        font-weight: 500;
        color: $blue;
        cursor: pointer;
    }
    
    #cartPanelItemExpansion {
    
        cursor: pointer;
        padding-bottom: 16px;
        
        p {
            @include font-size(12px);
            line-height: 16px;
            color: $charcoal-500;
            margin: 0;
            padding: 0;
        }
    
        &.expanded {
    
            .display-condensed {
                display: none;
            }
    
            .display-expanded {
                display: block;
            }
            
        }
    
        .display-expanded {
            display: none;
        }
    }
    
    .summaryitem {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .itemlist table {
        width: auto;
    }
    
    .itemlist tr {
        position: relative;
    }
    
    .itemlist .quantity-status {
        right: 0 !important;
    }
    
    .order_item_name {
        width: 240px;
        color: $charcoal-900;
        @include font-size(13px);
        line-height: 16px;
        vertical-align: top;
    }

    .pricepack p {
        margin: 0 !important;
        white-space: nowrap;
        text-align: right;
    }
    
    .order_item_price {
        @include font-size(13px);
        font-weight: 600 !important;
        line-height: 16px !important;
    }
    
    .order_item_pack {
        @include font-size(11px);
        line-height: 16px !important;
    }

    .order-qty-container {
        min-width: 50px;
        line-height: 24px;
        cursor: pointer;
        text-align: center;
    }

    .quantity-input-group {
        display: inline-block;
        position: relative;
        min-width: 50px;
        height: 30px;
    }
    
    .order-item-quantity {
        text-align: center;
        @include font-size(18px);
        font-weight: 600;
        color: $charcoal-975;
    }

    .sortingContainer .sortBlock {
        position: absolute;
        right: 0;
        top: 64px;
        z-index: 1;
    }

    .item-toggle-wrapper {
        height: 100%; 
        position: relative;
        display: flex;
        align-items: center;
    }

}

.flag-wrapper {
    position: relative;
    z-index: 1;
    display: flex;

    &.left {
        padding-left: 0;
    }

    &.right {
        justify-content: flex-end;
        padding-right: 0;
    }
}

// Flag and Info styles that might be within a modal as well

.flag-list {
    padding: 0;
}

.info-list {

    > li {
        display: inline;
        margin-right: 8px;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
    }
}

.item-price-text {
    @include font-size(17px);
}

.item-catchweight-price-text {
    @include font-size(12px);
    display: block;
    margin-top: -4px;
}

.flagBlock {
    position: absolute;
    margin-top: 96px;
    width: 96px;

    @include media-breakpoint-down(sm) {
        
        #searchResults.listView & {
            margin-top: 64px;
            width: 64px;
        }

        .itemFlag {
            @include font-size(10px);
            white-space: inherit;
        }
    }
}

.itemDetailsFlag{
    float: left;
}

.item-detail-flags .itemDetailsFlag {
    float: none;
    width: 100%;
} 

#itemsearchSpinner {
    position: fixed;
    z-index: 10;
}


/* Toggle Switch Component */

input.toggle-switch {
	opacity: 0;
	margin: 0px;
	display: none;
}

.toggle-label {
	display: inline-block;
	vertical-align: 5px;

    &:not(.toggle-active) {
        color: $charcoal-600;
    }
}


.toggle-label.toggle-active {
	font-weight: 600;
}

input.toggle-switch ~ label {
	display: inline-block;
	width: 2em;
	height: 1.1em;
	border-radius: 1em;
	position: relative;
	cursor: pointer;
	background: $charcoal-700;
	font-size:24px;
	margin-bottom: 0px;
	margin-right: 5px;
	margin-left: 5px;
}

input.toggle-switch ~ label .switch {
	position: absolute;
	width: 1em;
	height: 1em;
	margin-top: 1px;
	margin-left: 1px;
	border-radius: 1em;
	background: #fff;
	box-shadow: 5px 0px 28px -9px rgba(0, 0, 0, 0.75);
	transition: transform .1s ease-in;
}

input.toggle-switch:checked ~ label {
	background: #007ACE;
}

input.toggle-switch:checked ~ label .switch {
	background: #fff;
	transform: translatex(0.92em);
	transition: transform .1s ease-in;
}

input.toggle-switch:disabled ~ label {
    background-color: #ddd !important;
}

.prioritizeOrderMeta {
	padding-top: 10px;
	padding-bottom: 10px;
}

.safetyLink {
	display: block;
	background-color: #6262C5;
	padding: 0px;
	margin: 5px;
	border: 1px solid #222;
	text-align: center;
	color: $white;
    text-decoration: none;
    
    &:hover {
        color: $white;
    }
}

.greySection,
.grey-section {
    @extend .card; // bootstrap card.scss
    background-color: $surface-dark;
    padding: 8px;

    > span {
        padding: 5px;
        font-size: 16px;
        color: $charcoal-975;
    }

    > ul {
        list-style-type: none;
        text-align: center;
        margin: 0;
        line-height: 1.2em;
        padding: 5px;

        li a {
            text-decoration: none;
            display: block;
            font-size: 14px;
            color: white;
            background-color: $blue-dark-2;
            padding: 4px 8px;
            margin: 4px 0 0;
            border-radius: 3px;
        }
    }

    .rightPanel table {
        border: 2px solid black;
        font-size: 12px;
        line-height: 1.7em;

        td {
            border: 1px solid black;
            padding: 1px 5px;

            p {
                margin-bottom: 0;
            }
        }
    }
}

/* Item Comparison Overlay
----------------------------------------------------------*/
.item-comparison-wrapper {
    div.col {
        padding: 0;
    }

    .item-comparison-list {
        & > li {
            border-top: 1px solid $black;
            padding: 4px;
        }
    }

    .item-comparison-table-info {
        ul > li {
            font-weight: bold;
        }
    }

}



/* Order Guide Print Modal Checkboxes
----------------------------------------------------------*/
#printDialog .mb-checkbox {
    margin-bottom: 0;

    > li {
        padding-bottom: 4px;

        &:last-child {
            padding-bottom: 0;
        }
    }
} 


/* CUSTOM CSS to remove squares from list and make list scrollable
----------------------------------------------------------*/
ul.filterList {
    list-style-type: none!important;
}
.filterListContainer {
    max-height: 200px;
    overflow: auto;
}
.filterListWrapper {
    padding-bottom: 10px;
    padding-top: 10px;
}
img.searchIcon {
    margin-left: 10px;
}

/* Gallery Styles */ 

#galleria {
	margin-bottom: 30px;
}

.product-gallery {text-align: center; }

.product-gallery .product-image {display: none;}
.product-gallery .product-image img {width: 65%; margin-right: auto; margin-left: auto; max-width: 350px;}

.gallery-thumb {display: inline-block;}

.product-gallery .slick-dots {
	bottom: inherit;
	position: relative;
	padding-top: 10px;
	padding-bottom: 5px;
	margin-top: 10px;
}

.product-gallery .slick-prev,
.product-gallery .slick-next {
	z-index: 100;
}

.product-gallery .slick-prev:before,
.product-gallery .slick-next:before {
	color: #333 !important;
}

.product-gallery .slick-prev:hover:before, 
.product-gallery .slick-next:hover:before {
	color: rgb(136, 136, 136)
}

.product-gallery .slick-dots {
	border-top: solid 1px #eee;
	border-bottom: solid 1px #eee;
}


.product-gallery .slick-prev {
	left: 5px;
}
.product-gallery .slick-next {
	right: 5px;
}

.product-gallery .slick-dots li {
	width: 40px;
	height: 40px;
	opacity: 0.5;
    margin: 3px;
    overflow: hidden;
}

.product-gallery .slick-dots li img {
	width: 100%;
}

.product-gallery .slick-dots li.slick-active {
	opacity: 1.0;
}

.break-item-link {
    color: $blue;

    &:hover {
        color: $blue-dark-3;
        cursor: pointer;
        text-decoration: underline;
    }
}

.item-purchase-wrapper {
    flex: 1;
}

.search-sort-selector {
    position: absolute; 
    top: 15px; 
    right: 15px;

    .analysis-panel-closed & {
        right: 0px;
    }
    

    @include media-breakpoint-down(md) {
        right: 0;
    }
}


@media (max-width: 460px) {
    .sort-select {
        width: 100px;
    }
}

.inactive-remaining-days {
    color: $charcoal-500;
    font-size: 12px;
}

.replacement-data-row {
    min-height: 40px;
}

@include media-breakpoint-down(md) {
    #itemSearchAlerts {
        margin-right: -14px;
    }
}
