.uploadButton {
	border: 1px solid #89959E !important;
	background: linear-gradient(to bottom, #f7f7f7, #e4e4e4) !important;
	margin-top: 4px !important;
	padding: 1px 6px !important;
}

.uploadButton:hover {
	border: 1px solid #737D85 !important;
}

/* tiny mce customized file browser css
----------------------------------------------------------*/
#fileUpload {
	line-height: 1.6 !important;
	font-weight: bold !important;
	height:410px; 
	overflow-y: auto;
}

#fileUpload thead tr, #fileUpload tfoot tr {
	background-color: #666 !important;
}

#fileUpload input[type="search"] {
	border: 2px inset #e8e6e6 !important;
}

#fileUpload select {
	border: 1px solid #89959E !important;
}

.fileUploadTableWrapper {
	width: 100% !important;
	padding-top: 20px !important;
}
.fileLink {
	display: block;
	width: 250px!important;
	overflow: hidden;
	white-space: nowrap !important;
	text-overflow: ellipsis;
}