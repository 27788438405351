.slick-slider.item-slider {

    .slick-arrow {

        &:before {
            color: $black;
        }
    }

    .slick-slide {
        padding: 0 8px;
    }

    > .slick-list > .slick-track {
        margin-left: 0;
    }
}