/* Styles for validation helpers
-------------------------------------------------------------------------------*/
.field-validation-error {
	color: #ff0000;
	display: block;
}

.field-validation-valid {
	display: none;
}

.input-validation-error, input.input-validation-error {
	border: 1px solid #ff0000;
	background-color: #ffeeee;
}

.message-summary {
	line-height: 120%;
	padding: 10px;
}

.validation-summary-errors {
	color: #ff0000;
	padding: 10px;
	line-height: 120%;
}

.validation-summary-errors span, .message-summary span {
	font-weight: bold;
}

.validation-summary-errors ul, .message-summary ul {
	margin: 0;
	padding-left: 0;
	list-style-type: none;
}

.validation-summary-errors li, .message-summary li {
	margin: 0;
	padding: 0;
}

.validation-summary-valid {
	display: none;
}
