#priceCalculatorContainer table tr td:first-child {
	padding-left: 15%;
}

#priceCalculatorContainer table tr td input[type="text"] {
	max-width: 80px;
}

#priceCalculatorContainer table tr td a {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}

.qtyWarning {
	font-size: 0.7em;
	color: red;
}

.showOrderFilterBtn, .columnOptionsBtn, #addToOrderBtn {
	min-width: 40px;
	font-weight: normal;
	padding: 2px 5px;
	background: #ccc;
	color: #333;
	font-size: 10px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
}

#addToOrderBtn {
	border: none;
	margin-bottom: 2px;
}

#columnFilterContainer {
	float: left;
	position: relative;
}

#columnFilterContainer ul {
	border: 1px solid #ccc;
	background-color: white;
	margin: 0;
	padding: 5px;
	width: 120px;
	position: absolute;
	z-index: 1000;
}

#columnFilterContainer ul li {
	list-style-type: none;
}

.mobileVisible {
	display: none;
}

.itemSearchResultsButtons{
	width: 66%;
}

.orderSearchItemsContainer {	
	overflow: auto;
	margin-bottom: 30px;
	float:left;
	width: 66%;
}

.oesearchtooltip {
	font-size: 10px;
}

.notSorted::after {
	content: url('/resources/images/sort_both.png');
}

.sortAsc::after {
	content: url('/resources/images/sort_asc_white.png');
}

.sortDesc::after {
	content: url('/resources/images/sort_desc_disabled.png');
}

#orderSearchItems tr td img{
	height: auto;
	width: auto;
	max-height: 50px;
	max-width: 80%;
}

.orderItemsContainer {
	float: right;
	margin-bottom: 30px;
	width: 33%;
}

#orderItems tbody tr:FIRST-CHILD {
	border-bottom: 1px solid #7d7d7d;
	color: blue;
	font-size: 12px;
}

#orderItems tbody tr:first-child span {
	min-width: 40px;
	font-weight: normal;
	padding: 2px 5px;
	background: #ccc;
	color: #333;
	font-size: 10px;
	border-radius: 5px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
}

#orderItems tr:not(:last-child ) {
	border-bottom: 1px solid #666;
}

.item-list {
	font-size: 13px;
}

.item-list .flags, .item-list .usage {
	line-height: 1.5em;
}

#oeFooter {
	display: none;
}

.deleteCopyToCust {
	float: left;
	margin-right: 3px;
	height: 18px;
	width: auto;
}