/* === Styles For Contact Us === */
#contactUs {
	margin: 0 auto;
	margin-top: 20px;
}

.contact-box {
	background-color: $surface-dark;
}

.contact-box table {
	border-collapse:separate; 
	border-spacing: 0 0.7em;
}

#contact-info h5 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	margin: 10px 0px;
}

.chat-btn {
	background-color: #50B83C !important;
	color: #fff !important;
	border: 1px solid #00a70c;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.15) 100%);
	padding: 10px 20px !important;
	border-radius: 3px;
	font-size: 14px;
}
.team-viewer {
	display: inline-block; 
	vertical-align: top; 
	margin-right: 5px;
}
.downloads {
	display: inline-block; 
	width: 80%;
}
