#foodshowbanner {
	margin-top: 10px;
	margin-bottom: 20px;
}

.homeleft, .menuleft {
	float: none;
	width: 100%;
	margin-top: 10px;
}

.homeleft p, .menuleft p {
	color: #c0c0c0;
	font-size: 1.25em;
	font-weight: bold;
	margin-bottom: 10px; 
}

.homeleft h1, .menuleft h1 {
	font-size: 32px;
	line-height: 110%;
	margin-bottom: 10px;
	text-align: center;
}

.menuleft h1 img {
	width: 250px;
}

.homeright, .menuright {
	float: none;
	width: 100%;
	padding-top: 10px;
}

.homeright p {
	font-size: 1em;
	font-weight: bold;
}

.menuright {
	padding: 0 0 10px;
}

.menuright p {
	font-weight: bold;
}

.menuright img {
	display: none;
}

.tools_intro {
	margin: -20px -15px;

	h2 {
		font-size: 1.6em;
	}
	h3 {
		font-size: 1.3em;
		margin-bottom: 0;
	}
	.menu-bg {
		background: url(https://cdn.martinbros.com/content/mpowermenus-background.jpg);
		background-size: cover;
		background-position: center center;
	}
	.menu-bg-center {
		color: $white;
	}
}

#menusPanel {
	margin-top: -10px;
}

.guestcards-bg {
	background: url(https://cdn.martinbros.com/content/mpowerguestcards-background3.jpg);
	background-size: cover;
	background-position: center center;
}

.guestcards-bg-center {
	color: $white;
}

.updates-and-news {
	background-color: #ffff99 !important;
}

.toolsright {

	width: 100%;
	margin: 0 0 10px 0;
	padding: 20px;
	border-radius: 8px;
	background-color: #eeeeee;
	min-height: 300px;
}

.invoptions {
	text-align: right;
	position: absolute;
	visibility: visible;
	top: 20px;
	right: 10px;
}

.paoptions {
	text-align: right;
	visibility: visible;
	top: 113px;
	right: 10px;
}

.paoptions img {
	cursor: pointer;
}

.pendingorderoptions img {
	cursor: pointer;
}

#pendingOrdersGrid button{
	margin: 0;
	padding: 3px;
}

#rapidupdate {
	background-color: #eeeeee;
	margin-bottom: 10px;
}

.invmgmtleft {
	width: 100%;
	padding: 5px;
	overflow: auto;
	background-color: #eeeeee;
	border: 1px solid #ccc;
	margin-bottom: 10px;
}

.invmgmtleft ul {
	list-style-type: none;
}

.invmgmtleft h3 {
	margin: 0;
	padding: 0;
}

.invmgmtleft p {
	font-size: 0.825em;
}

.invmgmtright {
	width: 100%;
}

table input[type=text]:not(.mb-form-control),
table input[type=number]:not(.mb-form-conrol),
textarea:not(.mb-form-control, .form-control) {
	width: 95%;
}

#orderEdit{
	min-height: 625px;
	overflow: auto;
}

#orderEdit .addRemoveOGBtn {
	min-width: 40px;
	font-weight: normal;
	background: #666;
	color: #fff;
	font-size: 9px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
}

#orderEdit .categoryHeader {
	background-color: #008ed2;
	color: white;
	font-size: 16px;
	padding: 7px 10px;
}

#ordersearch {
	float: left;
}

#ordersearch .searchBtnContainer {
	float: left;
	padding-left: 5px;
	line-height: 28px;
	vertical-align: middle;
}

#ordersearch #searchselect {
	max-width: 200px;
	outline: 0;
	background-color: #dadada;
}

#ordersearch #searchselect:focus {
	outline: 0;
}

#ordersearch .searchBtnContainer input {
	min-width: 40px;
	font-weight: normal;
	padding: 2px 10px;
	background: #ccc;
	color: #333;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	border: none;
	margin-top: 4px;
}

#ordersubmit {
	float: right;
}

#searchcontainer {
	float: left;
}

.order-button {
	min-width: 40px;
	font-weight: bold;
	padding: 10px 20px;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	color: #000;
}

.submit-button {
	background: #ffff00;
}

.scheduledDay a{
	background-color: #6fa3f7 !important;
}

.grey-background {
	background: #ccc;
}

.submit-button:hover {
	background-color: orange;
}

#profileform {
	margin: 0 auto;
	max-width: 400px;
	margin-top: 15px;
}

/* ========= Mpower tabs ======== */
#patabs_container, #menustabs_container, #inventoryTabs_container {
	border-bottom: 1px solid #ccc;
}

#oetabs_container {
	border-bottom: 1px solid #ccc;
	padding-right: 75px;
}

.patab_content {
	display: none;
}

#patabs_content_container, #menustabs_content_container {
	border-top: 1px solid #ccc;
	border-top: none;
	padding: 10px 0;
}

.menustab_content, .invtab_content {
	display: none;
}

li.menustab {
	font-size: 1em;
}

.recipe p {
	margin: 0;
}

.menuanalysis .formcontents {
	float: left;
	width: 50%;
	text-align: left;
	padding-bottom: 6px;
	font-weight: bold;
}

.nutrition-row {
	display: flex;
	align-items: center;
	width: 23%;


	@include media-breakpoint-down(lg) {
		width: 50% !important;
	}
	
	@include media-breakpoint-down(xs) {
		width: 100% !important;
	}

	&.empty-row {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

}

.nutrition-group {
	label {
		text-align: right;
		flex: 1;

		@include media-breakpoint-down(sm) {
			width: 130px;
			text-align: left;
		}
	}

	.nutrition-group-content {
		display: flex;
		align-items: center;
	}
}

.setupinput {
	padding: 0px;
	list-style: none;
	margin:0;
	padding: 0;

	input {
		width: 75px;
	}

	li {
		margin: 5px 0;
	}

	span.setuplabel {
		text-align: right;
		font-weight: 600;
		width: 50%;
		padding-right: 0.25rem;

		@include media-breakpoint-down(sm) {
			width: 130px;
		}
	}

	span.setupcontents {
		text-align: left;
		display: flex;
		align-items: center;
	
		input[type=text] {
			margin-right: 0.25rem;
		}
	}
}

.menu-item {
	width: 66px;
}

/* General Tabs - imported from screen.css
---------------------------------------------------------------*/
.tabs_container, .ntabs_container {
	border-bottom: 1px solid #ccc;
}

.tabs, .ntabs {
	list-style: none;
	padding: 6px 0 6px 0;
	margin: -10px 0px -9px;
	font-size: 0.875em;
	line-height: 3.0em;
}

.tabs li, .ntabs li {
	display: inline;
}

.tabs li a, .ntabs li a {
	border: 1px solid #ddd;
	padding: 8px 8px;
	text-decoration: none;
	background-color: #eee;
	border-bottom: none;
	outline: none;
	color: #000;
	border-radius: 5px 5px 0 0;
	white-space: nowrap;
}

.tabs li a:hover, .ntabs li a:hover {
	background-color: #E0E0E0;
	padding: 8px 8px;
}

.tabs li.active a, .ntabs li.active a {
	background-color: white;
	padding: 8px 8px 9px 8px;
	font-weight: bold;
	border-color: #ccc;
}

.tabs li.active a:hover, .ntabs li.active a:hover {
	background-color: #f8f8f8;
	padding: 8px 8px 9px 8px;
}


.tab_content, .ntab_content {
	display: none;
}

#menuRecipes .expand_collapse_all {
	background: #666;
	padding: 4px 8px;
	color: white;
	font-size: 11px;
	border-radius: 2px;
}

#menuRecipes .ui-icon-minusthick {
	background-image: url("/resources/images/collapse_all_button.png");
	width: 20px;
	height: 20px;
	background-position: inherit;
}

#menuRecipes #advancedSearchButton {
	padding: 1px 5px 2px 4px;
	background: #999;
	border-radius: 2px
}

/* Fields State
-----------------------------------------------------------*/

.hover {
	border: solid 1px #F90;
}

/* List multi columns
-----------------------------------------------------------*/
ul.columns {
	list-style-type: none;
	padding: 5px;
}

ul.columns li {
	float: left;
}

ul.columns4 {
	list-style-type: none;
}

ul.columns4 li {
	display: inline-block;
	width: 25%;
	padding: 4px;
}

.zebra div:nth-child(even), .zebra tr:nth-child(even) {
	background-color: #EEE;
}

.zebra div:nth-child(odd), .zebra tr:nth-child(odd) {
	background-color: #FFF;
}

.zebra div:hover, .zebra tr:hover {
	background-color: $surface-darker;
}

table.bor {
	width: 100%;
}

table.bor td {
	border: 1px solid #000;
}

/* MPower Insights
-----------------------------------------------------------------------------------------------------*/

#mpower-insights h2 {
	margin: 0;
	float: left;
}

#budget_graph, #ppd_graph {
	background-color: #EEE;
	height: 400px;
}

#insights #dashbody .budget-graph {
	width: 180px;
	float: right;
}

#insights #dashbody .ppd-graph {
	margin-right: 200px;
}

#insights #dashbody div.controls {
	padding-top: 10px;
}

#insights #dashbody button {
	border: solid 1px #999;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 4px 10px;
	-webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
}

#insights #dashbody button:hover {
	background-color: #EEE;
	-webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.75);
}

#insights #dashbody .ppd-graph button {
	float: right;
}

.residentFilter {
	display: inline-block;
	padding-right: 35px;
}

.tooltipLink {
	text-decoration: none;
	border-bottom: 1px #0066c0 dotted;
}

.weightTrackAlertTxt {
	color: red;
	font-style: italic;
	font-weight: bold;
}

.guestCardActionBtn {
	padding: 6px 12px;
}

.tooloptions {
	min-width: 50px;
	margin: 0 35px 0 0;
	font-weight: normal;
	padding: 2px 10px;
	background: #ccc;
	color: #333;
	font-size: 12px;
	border-radius: 5px;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
}

.photo-hover-button {
	background-color: $blue;
	z-index: 2;
	position: absolute;
	width: 34px;
	height: 34px;
	padding: 1px;

	> span {
		color: $surface-light;
	}
}

#editResidentPhotoBtn {
	right: 5px;
	top: 3px;
}

#deleteResidentPhotoBtn {
	left: 5px;
	top: 3px;
}

#residentPhoto {
	max-width: 300px;
}

/* right justify all the dropdowns under the resident form
----------------------------------------------------------*/
#resident-form button.ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
    float: right;
}

/* display text vertically
----------------------------------------------------------*/
.rotated-text {
	display: inline-block;
	overflow: hidden;
	text-transform: uppercase;
	text-align: center;
	font-size: 0.625em;
	font-weight: bold;
	line-height: 110%;
	width: 3em;
}

.rotated-text__inner {
	display: inline-block;
	white-space: nowrap;
	/* this is for "non IE" browsers
	that don't support writing-mode */
	-webkit-transform: translate(1.1em, 0) rotate(90deg);
	-moz-transform: translate(1.1em, 0) rotate(90deg);
	-o-transform: translate(1.1em, 0) rotate(90deg);
	transform: translate(1.1em, 0) rotate(90deg);
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-o-transform-origin: 0 0;
	transform-origin: 0 0;
	/* IE9+ */
	-ms-transform: none;
	-ms-transform-origin: none;
	/* IE8+ */
	-ms-writing-mode: tb-rl;
	/* IE7 and below */
	*writing-mode: tb-rl;
}

.rotated-text__inner:before {
	content: "";
	float: left;
	margin-top: 100%;
}

.enableContent {
	cursor: pointer;
}

.dt-button-collection {
	z-index: 3000!important;
}

#guidesIndex {
	margin-top: 8px;
}

table.vw_table .textInput{
	width: 21% !important;
}

table.vw_table input[type=file]{
	width: 95% !important;
}

.file_container {
	width: 48%;
	float: left;
	margin: 5px 5px;
}

#purchaseHistory {
	display:none;
}
.editInventoryItem { 
	cursor:pointer;
}
#managementOverlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
  display:none;
  z-index:100;
}
.dspNone {
	display:none;
}
.adjustLabel {
	padding: 1px 0;
}

.loadingSub {
	margin: auto;
	width: 66px;
    height: 66px;
}
#loading h3{
	margin-left:-12px;	
}

.mb-preferred-highlight, li.mb-preferred-highlight a {
	color : #00b300;
}

.mpower-list  {

	.delete-icon {
		opacity: 0;
		transition: opacity 0.25s;
		line-height: 19px;

		i:hover {
			color: $red-dark-1 !important;
		}

	}

	li:hover .delete-icon,
	li.checked .delete-icon {
		opacity: 1;

	}

}

.groupSection .groupTable .sorting {
	background-image: none !important;
}

/* Mpower Menus
-------------------------------------------- */ 

.menu-field-group {
	display: flex;
}

.menu-field-group-lg {
	width: 600px;

	@include media-breakpoint-down(lg) {
		width: auto;
	}
}


.menu-field-read,
.menu-field-edit,
.menu-field-label {
	display: inline-block;
	padding:2px;
}

.expired-menu:after {
	content: "Expired";
	background: $red;
	display: inline-block;
	margin-left: 5px;
	margin-top: 1px;
	padding: 2px 4px;
	color: $white;
	@include font-size(11px);
	align-self: center;

}

.daterange-edit input {
	width: 90px;
}


.menu-field-label {
	margin-right: 0.25rem;
}

.allow-edit .menu-field-group:hover {
	cursor: pointer;
	background: $charcoal-200;
}

.allow-edit .menu-field-group.active-edit:hover {
	background: none;
}

.show-toggle .menu-field-read.truncate {
	@include text-truncate();
}


.allow-edit .has-edits .menu-field-read {
	color: $primary;

	&:after {
		margin-left: 5px;
		color: $primary;
		font-weight: 700;
		content: "*";
	}
}

.disabled-menu {
	color: $charcoal-500;
	cursor: default;

	&:hover {
		text-decoration: none;
		color: $charcoal-500;
	}
}

.facility-link-icon {
	font-size: 18px;
}

#menuHeaderFacilityGroup {
	cursor: pointer;
}

/* Mpower Menu select menu dropdown
-------------------------------------------- */

#menusList > li {
	padding: 0;
}

#mpowerMenuSelect .dropdown-menu {
	width: 400px;

	@include media-breakpoint-down(sm) {
		width: 325px;
	}
}

.multiselect-dropdown {
	//min-width: 400px;
	width: 100% !important; 
	max-width: 400px;
}
	
.active-menu label,
.active-menu label.ui-state-hover {
	font-weight: 700;
}

.active-menu label span:before {
	font-family: 'Material Icons';
	content: "font_download";
	display: inline-block;
	margin-right: 5px;
	font-weight: normal !important;
}

/* Facilities
---------------------------------------------*/

.facility-card {
	border: 1px solid #000;
	margin: 0.5rem auto;
	padding: 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.facility-card-name {
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
}

/* Insights Responsive Table 
---------------------------------------------*/ 

#insightsReportTable.fixed-dates {

	padding-top: 38px;

	.rTable.insight-row-dates {
		position: fixed;
		top: 104px;
		background: $surface-dark;
		width: calc(100% - 30px);
		z-index:2;

		@include media-breakpoint-down(md) {
			top: 56px;
		}
	}
}

.chart-legend {

	text-align: center;
	padding: 0.5rem;

	@include media-breakpoint-down(md) {
		text-align: left;
	}
	
	[class="0-legend"],
	.custom-legend .chart-legend & {
		cursor: pointer;
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		@include media-breakpoint-down(md) {
			max-height: 160px;
			overflow: auto;
		}
	}

	[class="0-legend"] li,
	li {
		display: inline-block;
		font-size: 12px;
		font-weight: 500;
		color: $charcoal-700;
		margin-right: 10px;
		margin-bottom: 10px;

		@include media-breakpoint-down(md) {
			display: block;
		}

		&.legend-break {
			display: block;
			margin-top: 10px;
		}
	}



	[class="0-legend"] li.legend-hidden,
	li.legend-hidden {
		text-decoration: line-through;
		opacity: 0.6;
	}

	[class="0-legend"] li span,
	li span {
		display: inline-block;
		width: 45px;
		height: 17px;
		vertical-align: text-top;
	}
}

.rTable-wrap {
	background: $surface-dark;
	position: relative;

	.rTable {

		position: relative;
		overflow: hidden;
		border-bottom: solid 4px #fff;
		padding: 0.25rem 0;

		.layer-indent .rLabel {
			padding-left: 18px;
		}
		
		[inert] {
			opacity: 0.5 !important;
		}

		.rRow,
		.rRow-group,
		.rCell-wrap {
			display: flex;
			flex-flow: row nowrap;
		}

		.rRow-bold,
		.rRow-bold button {
			@extend .fw-700;
		}

		.open-monthly-details:hover span {
			text-decoration: underline;
		}
		
		.rCell-wrap {
			flex: 1;
			position: absolute;
			z-index: 0;
			right: 0;
			
			.rCell {
				text-align: right;
			}
		}


		.rRow.open {
			> .rCell-wrap {
				visibility: hidden;
			}

			.rLabel.rToggle button.toggle-data:before {
				content: "arrow_drop_down" !important;
			}

			+ .rRow-group {
				max-height: 100%;
				opacity: 1;
			}
		}
		

		.rRow-group {

			flex-basis: 100%;
			flex-direction: column;
			@include transition(all 0.2s ease-out);

			> .rRow {
				
				position: relative;

				> .rCell.rLabel:not(.rToggle) {
					
					&.fw-700 {
						@extend .fw-700;
					}
				}
			}

			&.toggle-group {
				max-height: 0;
				overflow: hidden;
				opacity: 0;
			}
			
			&.layer-2 {
			
				> .rRow > .rCell:first-child > .cell-content {
					padding-left: 20px;
				}
			}

			&.layer-3 {
				
				> .rRow > .rCell:first-child > .cell-content {
					padding-left: 50px;
					
					&.indent-sm {
						padding-left: 60px;
					}
				}
				
			}
			
			&.layer-4 {
				
				> .rRow > .rCell:first-child > .cell-content {
					padding-left: 70px;
				}
				
				.rCell:not(.rLabel) {
					color: #999;
				}
				
				
			}
			
		}

		.rCell {
			flex: 1;
			box-sizing: border-box;
			list-style: none;
			min-width: 115px;
			padding: 0px 3px;
			
			.cell-content {
				padding: 0.15rem 0.25rem;
				display: block;
				
				&.indent-reg {
					padding-left: 25px;
				}

				&[data-amount^="-"] {
					color: red;
		
					&:before {
						content: "(";
					}
		
					&:after {
						content: ")";
					}
				}
			}

			@at-root .rRow-bold .rCell {
				@extend .fw-700;
			}

			&.rLabel {
				flex: 0 0 250px;

				@media (max-width: 400px) { 
					flex: 0 0 200px;
				}

				border-right: 1px solid rgba(153, 153, 153, 0.2);
				background: $surface-dark;
				position: relative;
				z-index: 1;

				&.include-dash > .cell-content:before {
					content: "-";
					display: inline-block;
					vertical-align: text-bottom;
				}

				&.rToggle button.toggle-data:before {
					font-family: 'Material Icons';
					display: inline-block;
					font-weight: normal !important;
				}

				&.rToggle a,
				&.rToggle button.toggle-data {
					font-weight: 700;
					display: flex;
					align-items: center;
					color: $charcoal-900;

					&:hover span {
						text-decoration: underline;
					}

					&:before {
						color : $charcoal-700;
						content: "arrow_right";
						display: inline-block;
						border-radius: 3px;
						font-size: 24px;
						width: 22px;
					}
				}
			}

		}
	}
}

#outsideFoodCostMyCategories .subgroup-purchases-row {
	display: none;

	+ .rCell-wrap {
		display: none;
	}
}

.chart-instructions {
	@include font-size(10px);
	margin-left: auto;
	padding: 0.5rem;

	@include media-breakpoint-up(md) {
		position: absolute;
		right: 10px;
		top: 0;
	}
}

@media (max-width: 1600px) { 

	.rTable .rCell-wrap {
		width: auto !important;
	}
}

@media (max-width: 1400px) { 

	.rTable .rCell-wrap {
		max-width: inherit;
	}
}

@media (max-width: 550px) { 
	.rTable .rCell.rLabel {
		flex: 0 0 180px;
	}
}









/* MPower Admin
-------------------------------------------- */

#admin_content_container {

	.loaderWithSpinner {
		left: 247px;
		right: 0;
		width: auto;
	}
}

.insights-chart-wrap {
	display: flex;

	.insights-chart-left {
		flex: 0 0 calc(100% - 350px);
		max-width: calc(100% - 350px);

		#insightMainChart {
			min-height: 375px !important;
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;

		.insights-chart-left, .insights-chart-right {
			max-width: 100%;
		}
		
	}
}

.insights-chart-right {
	flex: 0 0 350px;
	max-width: 350px;

	#insightSideChart {
		min-height: 375px !important;
	}

	&.has-meta-info #insightSideChart {
		min-height: 271px !important;
		max-height: 271px !important;
	} 
}

#recipeView {
	flex: 1;

	> div {
		margin-bottom: 0.5rem;
	}

	> table {
		margin-bottom: 0.5rem;
	}
}

#recipeOptionsSection {
	background-color: #FAFAFA;
	padding: 1rem;
	border-right: solid 1px #CACFD2;
	border-bottom: solid 1px #CACFD2;
	margin-top: -1rem;
	margin-bottom: 1rem;
	height: min-content;
}

.recipe-dialog {
	min-height: 680px;
	padding: 0 !important;
}

.recipe-info {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: baseline;
}

.recipe-table {
	border-color: #000;
	border-style: solid;
	border-width: 1px 0 0 1px;
}

.recipe-table-cell {
	padding: 5px;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	min-height: 30px;
}

.alternate-yield-column {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.recipe-checkbox-options-container {
	margin-bottom: 0.5rem;
}

.image-file {
	cursor: pointer;
}

.servings-column {
	width: 15%;
}

.servings {
	width: 40px;
}

#analysisContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	span.recipe-nutrient {
		margin-right: 0.5rem;
		margin-bottom: 0.25rem;
	}
}

.preferred-container {
	height: 22px;
	max-width: 40px;
}

#ingredientsGrid {
	tbody > tr > td {
		max-width: 350px;
	}
}

// Point of Service
#recipeItems {
	@media(max-width: 576px) {
		padding-bottom: 150px;
	}
}

.menu-item-section {
	
	margin-bottom: 1.5rem;

	.menu-items {
		display: flex;
		flex-wrap: wrap;
	}

	.menu-item {
		width: 200px;
		min-height: 90px;
		display: flex;
		justify-content: space-between;
		padding: 6px 5px;
		border: solid 1px $charcoal-300;
		margin-right: 1rem;
		margin-bottom: 1rem;
		text-align: center;
		font-weight: 400;
		cursor: pointer;
		flex-direction: column;
		word-break: break-word;

		> .favorite-icon {
			@include font-size(14px);
			position: absolute;
			bottom: 5px;
			left: 5px;
			color: $charcoal-400;
		}

		.recipe-name-row {
			display: flex;
			flex-wrap: nowrap;

			.recipe-size {
				min-width: fit-content;
				flex-grow: 1;
				text-align: right;
			}
		}

		.servings-row {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;

			.servings-container {
				display: flex;
				align-items: center;

				.servings-count {
					margin-right: 0;
					margin-left: 0.5rem;
					word-break: normal;
				}
			}
		}

		&:hover {
			background: $blue-light-4;
			border-color: $blue-light-2;
		}

		&.selected {
			background: $blue;
			color: $white;
			border-color: $blue-dark-1;

			> .favorite-icon {
				color: $white;
				opacity: 0.9;
			}
		}

		&.details-open {
			outline: 3px solid $orange;
			border: none;
		}
	}
}

.pos-overlay-content-panel {
	flex-wrap: nowrap;

	@media (max-width: 576px) {
		flex-wrap: wrap;
	}
}

.pos-recipe-details-panel {
	border-left: 1px solid $gray;
	max-width: 325px;

	@media (max-width: 576px) {
		max-width: none;
		width: 100%;
		border-bottom: 1px solid $gray;
	}

	.page-heading {
		border-top: 1px solid $gray;
		border-bottom: 1px solid $gray;

		#recipeDetailsName {
			margin: 0;
		}

		.close-button {
			.material-icons {
				color: $charcoal-400;
			}
		}
	}
}

.serving-size-button {
	border-radius: 50%;
	border: 1px solid $gray;
	background-color: $white;
	color: $black;
	width: 42px;
	height: 40px;
	font-weight: bold;
	margin-right: 1rem;
}

.serving-size-button.selected {
	background-color: $blue !important;
	color: $white;
	border: 1px solid $blue;
}

// Resident Menu Recipes

.resident-menu-recipe-group {
	flex-basis: calc(33.333333% - 2rem);
	margin-right: 2rem;
	margin-bottom: 2rem;

	@include media-breakpoint-down(md) {
		margin-right: 0;
		flex-basis: 100%;
	}

	&:nth-child(3n) {
		flex-basis: 33.333333%;
		margin-right: 0;
	}

	li.alternate-separator {
		border-top: solid 1px $charcoal-400;
		margin-top: 20px;

		&:before {
			content: " OR ";
			text-align: center;
			display: block;
			background: $white;
			width: 40px;
			margin-right:auto;
			margin-left: auto;
			margin-top: -11px;
			margin-bottom: 10px;
			color: initial;
			font-weight: 400;
		}
	}
}

.scale-and-print-image-row {
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.insight-subplot-selector-container {
	border-bottom: solid 4px $white;
}

