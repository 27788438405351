.product-row {

    .img-holder {
        float: left;
        margin-bottom: 15px;
        width: 15%;

        img {
            display: block;
            height: 100%;
            max-width: 175px;
        }
    }

    .desc-holder {
        float: left;
        width: 85%;
        margin-bottom: 20px;
    }

    a {
        color: $primary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

a.coupon-link {
    color: $primary-color;
    
    &:hover {
        text-decoration: underline;
    }
}

.coupon-details {
    height: 465px;
    
    div {
        float: left;

        &.img-holder {
            margin: 0 30px 30px 0
        }
    }
}

.coupon-detail-data {
    label {
        font-weight: 600;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 5px;
    }
}

.coupon-contact {
    min-width: 300px;
}

.product-wrap {

    .product-banner {
        position:relative;
        overflow: hidden;
        margin-right: -15px;
        margin-left: -15px;
        margin-top: -70px;
        padding-bottom: 40px;

        h1 {
            color: #fff;
            border: 3px solid #fff;
            font-size: 40px;
            font-weight: 500;
            padding: 5px 10px;
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                @include font-size(32px);
            }
        }

        &.banner-1:before {
            background-image: url(/resources/images/productbanner-1.jpg);
        }

        &.banner-2:before {
            background-image: url(/resources/images/productbanner-2.jpg);
        }

        &.banner-4:before {
            background-image: url(/resources/images/homebanner-4.jpg);
        }

        &.banner-5:before {
            background-image: url('https://cdn.martinbros.com/content/SeniorLivingSolutions.jpg');
        }

        > .container-fluid {
            position: relative;
            padding-top: 70px;
        }

        &:before {
            background-size: cover;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .right-panel {
        margin-top: 0px;
    }
}

.category-group-wrapper {
    
    .category-container,
    .subcategory-container {
        @include transition(0.5s ease);
    }

    .category-container {
        display: flex;
        flex-flow: row wrap;
        flex: 1;
    }

    .category-list {
        display: flex;
        flex-flow: row wrap;

        .category-label {
            color: $charcoal-900;
            font-weight: 600;
            height: 100%;
            text-decoration: none;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            cursor: pointer;
        }

        a {
            color: $charcoal-900;
            font-weight: 600;
            height: 100%;
            text-decoration: none;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }

        li {
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            width: 210px;
            height: 200px;
            box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.4);
            background-size: cover;
            background-repeat: no-repeat;

            &:hover {
                box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.7);

                .category-title {
                    background: rgba(0,0,0,0.6);
                }
            }

            &.selected-category {
                box-shadow: 0px 0px 4px 2px rgba(13,138,238,0.8);
            }

            @include media-breakpoint-down(md) {
                width: 150px;
                height: 160px;
            }

            .category-image {

                overflow: hidden;
                width: 100%;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-down(md) {
                    height: 100px;
                }

                img {
                    width: 100%;
                }

            }

            .category-title {
                display: flex;
                width: 100%;
                padding: 0.5rem;
                background: rgba(0,0,0,0.5);
                color: #fff;
            }

            .category-sub-preference-status-band {
                display: flex;
                width: 100%;
                padding: 0.5rem;
                background: $white;
                font-weight: 500;
                text-align: center;
            }
        }
    }

    .subcategory-container {
        width: 0;
        overflow: hidden;
        height: 0;
        align-self: stretch;
        background: $surface-base;
        margin-top: -20px;
        margin-right: -15px;
        padding-top: 20px;

        li {
            margin-bottom: 0.25rem;
        }
    }

    &.category-selected {

        .subcategory-container {
            display: block;
            overflow: inherit;
            height: auto;
            width: 300px;

            @include media-breakpoint-down(md) {
                width: 200px;
            }

            .subcategory-list {

                li {
                    padding: 0.25rem;
                }
            }

        }

        .subcategory-container {
            border-left: solid 1px $charcoal-300;
            border-bottom: solid 1px $charcoal-300;
            padding-right: 1rem;
            padding-left: 1rem;


        }


    }


}
