/* jQuery UI Override
-----------------------------------------------------------*/
ul.sortable {
	list-style-type: none;
	margin: 0;
	padding: 0 0 2.5em;
	float: left;
	margin-right: 10px;
}

ul.sortable li {
	margin: 0 5px 1px 5px;
	padding: 5px;
	font-size: 0.75em;
	width: 180px;
	cursor: pointer;
}

ul.sortableTarget {
	background-color: #DFD;
	border: solid 1px #696;
	min-width: 180px;
	min-height: 80px;
	padding-top: 10px;
}

.ui-widget {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.1em;
}

.ui-widget .ui-widget {
	font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button
	{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
}

.ui-sortable:not(.handle-only) td {
	cursor: move;
}

.ui-sortable .handle {
	cursor: move;
}

.dialog {
	display: none;
}

.ui-dialog {
    @include font-size(12px,true);
	z-index: 70100 !important;

	&.z-raise {
		z-index: 70300 !important;

		&+ .ui-widget-overlay {
			z-index: 70299 !important;
		}
	}
}

.ui-dialog .ui-dialog-buttonpane button {
	padding: 4px;
	min-width: 50px;
}

.ui-button {
	font-size: 12px;
}

/*Autocomplete
-----------------------------------------------------------------------------------------------------*/
.ui-autocomplete {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}

.ui-menu-item-wrapper {
	display: block;
}

.ui-menu-item-wrapper.ui-state-active {
	margin: 0 !important;
}

body.modal-open .ui-autocomplete,
.mb-modal .ui-autocomplete {
	z-index: 85000 !important;
}

.autocomplete-spinner {
	
	img {
		position: absolute;
		margin-left: -17px;
		margin-top: -7px;
	}
}

/* Jquery selectable
-----------------------------------------------------------------------------------------------------*/
.ui-selected {
	background: #FFFF00;
	color: #000;
}


.page-container {
	display: none;
}

/* for the tinymce dialogs appearing behind the edit view dialogs */
.mce-container {
	white-space: inherit !important;
}

/* Select Filter */
.ui-multiselect-filter input {
	width: calc(100% - 60px);
}

.ui-autocomplete.customer-search {
	width: 235px !important;

	@include media-breakpoint-down(md) {
		width: 100% !important;
	}
}
