#subnav {
    transition: 0.3s;
    
    &.transp {
        background: rgba(0,0,0,0.3); 
        box-shadow: none;
    }

    .main-nav-2 {
        .nav {
            .nav-item.dropdown {
                ul {
                    @media only screen and (max-height: 620px) {
                        max-height: 400px;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}

.disabled-nav {
	cursor: default;
	opacity: 0.5;
}

body.collapse.show {
    overflow: hidden;
    
    header {
        z-index: 70203;
    }
}

body.collapse.show .main-nav {
	overflow: auto;
	background-color: $charcoal-900;
}

.accountIcon {
	float: right;
	display: inline-block;
	line-height: 26px !important;
	color: $black !important;
}

.mobile-scroll-nav, 
.mobile-scroll-panel {
    overflow: auto;
}

.accountSelect {
	margin-bottom: 10px;
	line-height: 26px;
    display:block;
    
    .ui-multiselect {
        width: auto !important;
        min-width: 150px;
    }
}

.accountSelectOptions {
	width: auto !important;
	min-width: 150px;
}

#mobilenav,
#mobile-menu-toggler {
    min-height: 56px;
}

.navbar {

    margin-bottom: 0 !important;
    padding: 0;
    
    .nav-image {
        height: 32px;
    }

    &.navbar-inverse {
        background-color: $charcoal-900;
        border: transparent;
        position: relative;

        a.main-logo {
            display: block;
        }

        .navbar-nav {
            #customerAccountSelect {
                a:hover {
                    color: initial !important;
                }
            }
            
            > li > a {
                color: $charcoal-300;
            }

            > .show > a, 
            > .show > a:focus, 
            > .show > a:hover {
                border-bottom: 4px solid $mb-gold-2 !important;
                background-color: $charcoal-800;
                color: $surface-dark;
            }
        }

    }

    &.navbar-default {
        background-color: $charcoal-800;
        border: transparent;
        margin-bottom: 0;
        position: relative;

        .navbar-nav {
            > li > a,
            > li > button {
                color: $charcoal-300;

                &:focus {
                    color: $charcoal-300;
                }
            }

            > .show > a, 
            > .show > button {
                border-bottom: 4px solid $mb-gold-2 !important;
                background-color: $charcoal-800;
                color: $white !important;

                .material-icons {
                    color: $white !important
                }
            }
        }

    }

    .navbar-inverse .navbar-nav > .show >a, 
    .navbar-inverse .navbar-nav > .show > a:focus, 
    .navbar-inverse .navbar-nav > .show > a:hover {
        color: $white;
        background-color: $charcoal-dark;
    }

    .navbar-left {
        padding: 12px 0;
    }

    .navbar-right {
        margin-right: 8px;
    }

    &.tabs_navbar,
    &.tabs-navbar {
        background-color: $surface-darker;
        width: 100%;
        border-radius: 0;
        box-shadow: 0 1.4px 0 0 rgba(0, 0, 0, 0.2);
    }

    &.tabs_navbar,
    &.container-navbar {
        position: fixed;
        left: 0;
        top: 104px;
        z-index: 2001;
    }
}

.nav-tabs,
.navbar,
.nav {

    .nav-buttons, 
    .nav-buttons-lighter,
    .nav-buttons-right {
        border-bottom: 4px solid transparent !important;
    }

    .nav-buttons-right {
        font-weight: 600;
    }

    .nav-buttons-lighter {
        color: $charcoal-700 !important;
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        
        &:hover {
            border-bottom: 4px solid $charcoal-500 !important;
            color: $charcoal-800 !important;
            transition: 0.4s;
        }
    }

    .nav-buttons:hover, 
    .nav-buttons-right:hover {
        border-bottom: 4px solid $mb-gold-2 !important;
        color: $white !important;
        transition: 0.4s;

        .material-icons {
            color: $white !important;
        }
    }

    .active-black, 
    .nav-item .active,
    .active-black:hover {
        background-color: inherit;
        border: none;
        color: $black !important;
        @include media-breakpoint-up(lg) {
            border-bottom: 4px solid $black !important;
        }
        
    }

    &.nav-sm .nav-link {
        padding: 0.25rem 0.5rem;
        border-bottom-width: 3px !important;
    }

    .dropdown-toggle::after {
        margin-left: 0.4rem;
    }
}

.sidebar-nav {
    flex-wrap: nowrap;
    
    .nav-link {

        &.active {
            border: none !important;
            background: $blue;
            color: #fff !important;
        }
    }
}

.tabs_content_container,
.tabs-content-container {
    margin-top: 30px;
    position: relative;
}


.dropdown-menu {
    background-color: $surface-darker;
    overflow: hidden;
    width: 250px;
	border-radius: 0;
	@include font-size(14px);

    > li > a,
    > li > button {
        padding: 10px;
    }

    > li > button {
        @include font-size(14px);
    }

    a {
        color: $charcoal-925 !important;

        &:hover {
            background-color: $surface-base;
            color: $black !important;
            transition: 0.4s;
        }
    }
}


#accountDropdown {
    overflow: hidden;
    width: 325px;
}


.nav-search {
    border: transparent;
    background-color: transparent;
    border-bottom: 1px solid $mb-gold-2;
    border-radius: 0;
    color: $white;
    width: 100%;
    @include font-size(18px);
    font-weight: 300;
    line-height: calculateRem(24px);
    padding: 0 27px 5px 3px;
    margin-right: -30px;

    &:focus {
        outline: none;
    }
}

.search-results-item-mobile,
.search-results-item-main {
    background-color: $surface-light;
    text-align: left;

    .search-suggestion-item-link {
        display: flex;
        color: $charcoal-925 !important;
        
        &:hover {
            text-decoration: none;

            .search-suggestion-item-name {
                text-decoration: underline;
            }
        }
    }

    .suggestions-view-all-results {
        margin-top: 1rem;
        background: $light-gray;
        text-align: center;
        padding: 0.5rem;
        color: $primary-color !important;
        font-weight: 500;

        &:hover {
            background: $gray;
        }

    }

    @include media-breakpoint-up(sm) { 
        .search-results-items {
            border-left: solid 1px $surface-darker;
        }
    }
}


#itemResultDropdown {
    width: 150%;
    display: inherit !important;
    left: -25%;
    padding-top: 0;
    padding-bottom:0;
    border-color: $yellow !important;

    .dropdown-header {
        color: $charcoal-975;
        font-weight: 600;
        padding: 4px !important;
        margin-bottom: 0.5rem;
    }

    .dropdown-content {
        max-height: 525px !important;
        overflow-y: initial;

        li {
            background-color: inherit !important;
            min-height: 32px;

            a {
                padding: 4px !important;

                &:hover {
                    background-color: $surface-darker;
                }
            }
        }
    }
}

.search-results-item-mobile {

    li {
        min-height: 32px;
        background-color: inherit !important;

        &.suggestion-section-header:not(:first-child) {
            margin-top: 4px;
        }

        a {
            padding: 4px !important;
            &:hover {
                background-color: $surface-darker !important;
            }
        }
    }

    .dropdown-header {
        color: $charcoal-975;
        font-weight: 600;
        padding: 4px !important;
        margin-bottom: 0.5rem;
    }
}

.searchItemsBtn {
    display: inline-block;
    position: relative;
    left: 0px;
    bottom: 4px;
    color: $white;
    border-radius: 50%;
    background-color: transparent;
}

.account-search-icon {
    position: absolute;
    top: 6px;
    right: 10px;
}

.dropdown-search-icon {
    position: relative;
    bottom: 9px;
    right: 10px;
}

#menu-dropdown-search-icon {
    right: 18px;
    top: 14px;
}

.dropdown-search {
    border: 2px solid $charcoal-300;
    @include font-size(15px);
    font-weight: bold;
}

.dropdown-item.active, 
.dropdown-item:active {
    color: $charcoal-900 !important;
    background: none;
}

.rsMinW .rsBullet span {
    display: none !important;
}

#userActiveMenuSearch {
    width: 100%;
}

.dropdown-search:focus {
    outline: none;
    border: 2px solid $mb-gold-2;
}

.badge {
    line-height: 0.99 !important;

    &.badge-yellow {
        color: $black !important;
        margin-left: 3px;
        background-color: $mb-gold-2;
        border-radius: 10px;
        padding: 3px 7px;
    }

    &.badge-blue {
        color: $white;
        margin-left: 3px;
        background-color: $primary-color;
    }
}

.mb-account-selector li > a,
.mb-account-selector li > button {
    @include font-size(14px);
    color: $charcoal-300;

    &:hover,
    &:active {
        color: $charcoal-200 !important;
    }
}


#topnav a em {
    color: $charcoal-300;
}

.navbar-toggler {

    .currentPage {
        text-transform: capitalize;
        }

    &:not(.collapsed) {
        color: rgba(255,255,255,1);
    }
}


.navbar .nav li.nav-item > a,
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 15px;
    line-height: calculateRem(24px);
}

.mb-search {
    text-align: center;
    justify-content: center;

    > .flexwrap {
        max-width: 600px;
    }
}


.mb-search-icon {
    position: relative;
    top: 5px;
}


.active-yellow {
    color: $surface-dark !important;
    border-bottom: 4px solid $mb-gold-2 !important;
    background-color: $charcoal-800 !important;

    &:hover {
        color: $surface-dark !important;
        border-bottom: 4px solid $mb-gold-2 !important;
        background-color: $charcoal-800 !important;
    }
}

.active-dropdown {
    background-color: $charcoal-300;

    &:hover {
        @extend .active-dropdown;
    }
}


.dropdown-content {
    overflow-y: scroll;
    max-height: 400px !important;
    z-index: 2;
    overflow-x: hidden;
    margin-top: 4px;

    div {
        a {
            &.dropdown-item {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    li {
        cursor: pointer;
        height: 40px;
        background-color: $surface-darker;

        a {
            margin-bottom: 0;
            float: left;
            width: 100%;
            height: 100%;
            padding: 5px 6px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $black;

            &:hover,
            &:focus {
                text-decoration: none !important;
                background-color: $surface-base;
                transition: 0.4s;
            }
        }
    }
}

.divider-dark {
    background-color: $charcoal-300 !important;
}

/* ----- Mobile Nav ----- */

#mobilenav {
    .navbar-toggler {
        &:not(.collapsed) em {
            color: $white;
        }

        em {
            color: $charcoal-300;
        }
    }

    .mobile-toggles {
        button:focus,
        button:hover {
            .material-icons {
                color: $white !important;
            }
        } 
    }
}

button#mobile-menu-toggler {
    padding: 14px;
    background-color: $charcoal-975;
    margin: 0 !important;
}

.page_nav {

    li {
        padding: 4px;
    }

    .nav_link {
        display: block;
        padding: 10px;
        @include font-size(14px);
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .nav_item {

        &.active {
            color: $white;
            background-color: $primary-color;

            a {
                color: $white;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.mobile-toggles .navbar-toggler {
    @media (max-width: 400px) {
        padding: 0.25rem 0.5rem;
    }
}

// Chrome/Safari autofill fix for header search

@-webkit-keyframes autofill {
    0%,100% {
        color: $white;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s; // Safari
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}


// Menu Select Component 

.mpower-menu-selector {
    width: 100%;

    .dropdown-content {
        width: 100%; 
        max-width: 400px;
        max-height: 250px !important;

        .dropdown-item {
            white-space: initial !important;
        }
    }
}

.mpower-menu-select {
    
    .dropdown-content {
        max-height: 250px !important;
        overflow: auto !important;
    }

    .mpower-menu-dropdown {
        min-width: 150px;
        border: 1px solid $charcoal-300;
        white-space: nowrap;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        position: relative;

        .selected-menu-name {
            margin-right: 0.5rem;
            padding-right: 0.5rem;
            display: inline-block;
        }

        &:after {
            position: absolute;
            right: 10px;
            top: 13px;
        }
    }

    .dropdown-menu li {
        height: auto; 

        > a {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    .dropdown-search-icon {
        top: 9px;
        position: absolute;
        right: 17px;
    }

}

.mb-tabs .nav-link {
    color: $charcoal-700 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    
    &:hover {
        border-bottom: 4px solid $charcoal-500 !important;
        color: $charcoal-800 !important;
        transition: 0.4s;
    }
}