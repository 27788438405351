// b2b/modules/home/#!/historicalOrders/invoices Left Menu
// https://projects.invisionapp.com/share/J3LIFJPNX9U#/screens/347122317_Side_Menus

.sidebar_left {
	float: left;
	overflow: auto;
	margin: 0 16px 0 0;
	min-width: 200px;
}

.sidebar_left_flex {
	flex: 0 0 180px;
}


.sidebar_left ul,
.sidebar_left_flex ul {
	list-style-type: none;
	margin: 16px 0 0 0;
	padding: 0;
}

.sidebar_left input[type=text],
.sidebar_left select,
.sidebar_left_flex input[type=text],
.sidebar_left_flex select  {
	width: 100%;
	line-height: 20px;
	font-size: 14px;
}

.sidebar_content {
	overflow: auto;
	padding-right: 4px;
}