/* CONTENT PAGES
----------------------------------------------------------*/
.width800 {
	max-width: 800px;
	margin: 0 auto;
}

.width1000 {
	max-width: 1000px;
	margin: 0 auto;
}

.width1200 {
	max-width: 1200px;
	margin: 0 auto;
}

.img_border {
	border: 1px solid #ccc;
}

.two_column_150_left, .two_column_250_left {
	margin-bottom: 10px;
}

.two_column_350_left, .two_column_450_left {
	margin-bottom: 15px;
}

.iframecontainer {
	padding: 10px 1%;
	width: 98%;
	height: 100%;
}

#iframe {
	height: 100%;
	width: 100%;
	display: block;
}


#products-container {
	margin-top: 8px;
}

#formContainer {
	margin-bottom: 24px;
}

.dishContainer {
	width: 90%;
	margin: 8px auto 0 auto;
}

/* Required to view paging arrows at bottom of datatables */
.dataTables_info {
	margin-bottom: 30px;
}

.welcome {
	float: left;
	width: 29%;
	padding-left: 1%;
}

.welcome p {
	font-weight: bold;
	font-size: 0.5625em;
	color: #fff;
	margin: 0;
}

.welcome a {
	font: bold 11px 'Arial Black', Helvetica, Arial, sans-serif;
	color: yellow;
	text-decoration: none;
}

.copyright {
	float: left;
	width: 69%;
}

.copyright p {
	font-weight: bold;
	font-size: 0.5625em;
	color: #fff;
	margin: 0;
	text-align: right;
}

.copyright a {
	font: bold 9px 'Arial Black', Helvetica, Arial, sans-serif;
	color: #666666;
}

.copyright span {
	font: bold 11px 'Arial Black', Helvetica, Arial, sans-serif;
	color: yellow;
}

img.bg {
	width: 100%;
	height: 70px;
	position: absolute;
	top: 0;
	left: 0;
}

#newsfeed {
	color: white;
}

#newsfeed ul {
	margin: 0 0 12px;
	padding: 0;
}

#newsfeed li {
	list-style-type: none;
	list-style-position: outside;
	padding: 0;
	margin-top: 8px;
	margin-left: 0;
	cursor: pointer
}

.news_title {
	font: bold 12px/14px "Arial Black", Helvetica, Arial, sans-serif;
	color: yellow;
}

.news_teaser {
	font-size: 0.6875em;
	line-height: 14px;
}

.news_DL_title {
	font: bold 10px "Arial Black", Helvetica, Arial, sans-serif;
	color: yellow;
	margin-top: 10px;
}

.news_howto {
	color: silver;
	font-size: 0.625em;
	margin-top: -8px;
}

.news_listlink a {
	color: silver;
	font-size: 0.75em;
	font-style: italic;
}

.news_listlink a:hover {
	color: yellow;
	font-size: 0.75em;
}

/* products/browsecatalog start*/
#browse_catalog_image_container {
	width: 80%;
	margin: auto;
	height: 400px;
	background: url('/resources/images/salmonandoil.jpg') no-repeat center;
	overflow: auto;
}

#browse_catalog_image_container h1 {
	text-align: center;
	color: rgba(255,255,255,.7);
	font-size: 9em;
	border: none !important;
	padding: 16px;
}
/* products/browsecatalog end */

/* CMS - imported from screen.css
-----------------------------------------------------------*/
.content-page {
	position: relative;
	padding: 10px;
}

.content-page textarea {
	min-height: 440px;
}

.container.catalogs-flyers {

	h3 {
		margin-top: 1.25em;
		margin-bottom: 0.5rem;
		font-size: 20px;
	}
	
	.col p:last-child {
		margin-bottom: 0;
	}
}

.balanced-rows {

	.row {
		border-bottom: solid 1px $gray-300;
		margin-bottom: 1.25rem;
		padding-bottom: 1.25rem;
	}


	ul {
		margin-bottom: 0;
	}

	p + ul,
	p + ol {
		margin-top: 1rem;
	}

	p {
		margin-bottom: 0;
	}

	p + p {
		margin-top: 1rem;
	}
}

.img-stretch {

	img {
		width: 100%;
	}
}


/* ---- PAGE EDITOR ---- */

#idPageView {

    .search-item-list-wrapper {
        
        .search-item-placeholder .itemContainer {
			margin-bottom: 8px !important;
			position: relative;
        }
    }
}

.cms-edit-page-icon {
	position: absolute;
	right: 10px;
	top: 10px;
}