
html, body {
	font-size: 100%;
	margin: 0;
	padding: 0;
	height: 100%;
}

body {
	font-size: 0.875em;
	line-height: 1.5;
	display: block !important;
	position: inherit !important;
	font-weight: 400;
}

body td {
	font-weight: 400;
}

body thead td {
	font-weight: 500;
}

header.mb-top-header {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: $headerZIndex;
}

.fullwidth {
	width: 100%;
	height: auto !important;
	height: 100%;
}

.mw-1920 {
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
}

a[href^="tel"] {
	color: inherit;
	text-decoration: none;
}

p {
	margin: 0 0 0.8em 0;
}

b, strong {
	font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 10px 0;
	line-height: 1.1;
	font-weight: 400;
}

h1:not(
	.page-heading h1, 
	.product-banner h1, 
	.menu-bg h1, 
	.recipes-bg h1, 
	.guestcards-bg h1,
	.content-heading) {
	@include font-size(32px);
	font-weight: 100;
	text-transform: uppercase;
	padding-bottom: 4px;
	border-bottom: 2px solid $mb-gold-2;

	@include media-breakpoint-down(md) {
		@include font-size(28px);
	}
}
h2, .page-heading h1 {
	@include font-size(24px);

	@include media-breakpoint-down(md) {
		@include font-size(21px);
	}
}

aside h2 {
	@include font-size(18px);
	font-weight: 600;
}

h3,
h1.content-heading {
	@include font-size(22px);
}

h1.content-heading {
	font-weight: 500;
}

h4 {
	@include font-size(20px);
}

h5 {
	@include font-size(18px);
}

h6 {
	@include font-size(16px);
}

hr {
	color: #ccc;
	background: #ccc;
	border: none;
	height: 1px;
	margin: 20px 0;
}
button {
	border: none;
	background-color: Transparent;
}

ul {
	list-style-type: square;
}


button, input, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: 18px;
}

button, input, optgroup, select, textarea {
	color: black;
}

div:focus {
	outline: 0;
}

#mainView {
	height: 100%;
	width:100%;
}

/* CMS Content */ 

#idPageView {
	padding-top: 20px;
}

#idPageView img {
	max-width: 100%;
	height: auto;
}


/* End CMS Content */

.loaderWithSpinner,
.loaderWithSpinnerCore {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: url('/resources/images/loader2.gif') no-repeat center 50%
		rgba(255, 255, 255, 0.5);
	z-index: 9999;
	display: none;
	background-size: 80px;

	&.spinner-top-positioning {
		background: url('/resources/images/loader2.gif') no-repeat center 100px
		rgba(255, 255, 255, 0.5) !important;
		background-size: 80px !important;
	}

	&.panel-top-position {
		background-position: center 50px;
	}
}


.ui-dialog .loaderWithSpinner {
	position: absolute;
}

.loadinggif {
	background: url('/resources/images/ajax-loader.gif') no-repeat right center;
}

/*Google Translate CSS
----------------------------------------------------------*/
html.translated-ltr .primary, 
html.translated-ltr .primary {
	padding-top: 39px;
}
html.translated-ltr #header_profile_toggle,
html.translated-ltr #header_profile_toggle { 
	top: 79px; 
}

.stop-scrolling { 
	height: 100%;
	overflow-y: hidden;
}

body.flyout-open {
	height: 100%;
	overflow-y: hidden !important;
}

a.button_back {
    width: 80%;
    background: #ccc;
}

.applyChanges {
	position: fixed;
	display: flex;
	bottom: 0;
	left:0;
	right:0;
	height: 100px;
	z-index: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: $surface-darker;
	padding: 0.5rem;
	border-top: solid 1px $charcoal-500;
}

/* Footer Styles */ 

footer {
    clear: both;

    .navbar-bottom {
        background-color: $surface-dark;
        color: $charcoal-800;
        box-shadow: 0 -0.6px 0 0 rgba(0, 0, 0, 0.2);
        height: 48px;


        .footer-text-left {
            position: relative;
            height: 100%;
            width: 60%;
            float: left;
            padding: 0 16px;
            line-height: calculateRem(48px);
        }

        .footer-text-right {
            position: relative;
            height: 100%;
            width: 40%;
			float: right;
			padding: 0 16px;
        }

        .footer-list-right {
            list-style: none;
            float: right;
            margin: 0;
            height: 100%;
            
            li {
                display: table-cell;
                float: left;
            }
        }

        #supportChat {
            display: block;
            background: #009900;
            color: $white;
            text-align: center;
            font-weight: bold;
            height: 48px;
            padding: 3px 10px;
            margin: 0 5px 0 10px;
            @include font-size(11px);
            line-height: calculateRem(20px);
            border-radius: 0;
            width: auto;

            &:hover {
                background: #00bb00;
                text-decoration: none;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                color: #99ff99;
                display: block;
                border-top: 1px solid #00cc00;
                margin: -2px 0 0 6px;
            }
        }
    }
}

/* End  Footer Styles */ 

/* Misc Items */ 

.loadingDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	opacity: 0.6;
	flex-direction: column;
	height: 100%;
}


.dynamic-link-title {
	border-top: solid 1px rgba(255,255,255,0.7);
	padding-top: 6px;
}


.shade {
	position: fixed;
	background: rgba(0,0,0,0.5);
	top:0;
	right: 0;
	bottom:0;
	left: 0;
	display: none;
	z-index: 72199;

	&#mbGlobalOverlayShade {
		z-index: 70100;
	}
}

/* Close button styling for modals, flyouts, and overlays */

.close-button {
    
    .material-icons {
        color: $charcoal-200;
		font-weight: bold;
		
        &:hover {
			color: $white;
        }
    }
}

/* Pagination */

.mb-pagination {
	display: inline;
	padding: 0;

	button.dropdown-toggle {
		font-weight: bold;
	}
}

.pagination-dropdown {
	max-height: 400px;
	overflow-y: scroll;
	min-width: 75px;
	width: 75px;

	> li {
		text-align: center;
		padding: 4px 0;

		&:hover {
			background-color: $gray;
			cursor: pointer;
		}
	}
}

.mb-page-heading-spacing {
	margin-top: 0;

	&.mb-page-heading-negative-top {
		@extend .page-heading-spacing;
	}
}

.mb-page-content-no-header {
	padding-top: 1.6rem;
}

.mb-page-heading-wrapper + .mb-page-content {
	margin-top: 0.5rem;
}

.page-heading-spacing {
	margin: -20px -15px 20px -15px;
}

.disable-scrollbars {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* IE 10+ */
  
	&::-webkit-scrollbar {
	  background: transparent; /* Chrome/Safari/Webkit */
	  width: 0px;
	}
}

.drag-btn {
	cursor: move !important;
	max-height: 44px;
}