#admin_content_container {
	width: 100%;
    padding: 1rem;
    margin-top: -20px;
}

.admin-page-container {
    margin-top: -16px;

    &.m-neg {
        margin-left: -16px;
    }
}

/*Dashboard*/
.heading-dashboard {
	color: $charcoal-500;
	margin-top: 5px;
	text-transform: none;
}

.summary-info {
	float: left;
	margin-right: 220px;
	margin-bottom: 20px;
}

.summary-number {
	@include font-size(26px);
	color: $charcoal-700;
	margin-right: 5px;
}

.summary-label {
	@include font-size(22px);
	color: $cyan;
}

.admin-dashboard-pages {

    border-width: 0px !important;
    --bs-table-striped-bg: $white !important;
    
    tr {
        background-color: $white !important;
        border-style: none;
        border-bottom: 1px solid $charcoal-300;
        color: $charcoal-700;
        font-weight: bold;
        padding: 10px;
    }

    th {
        background-color: $white;
        color: $black;
        border-bottom: 3px solid $charcoal-300;
        padding-bottom: 2px;
    }
	
}

.admin-dashboard-link {
	color: $charcoal-700;
	font-weight: bold;
    float: right;
    
    &:hover {
        text-decoration: underline;
    }
}


.admin-dashboard-chart {
    margin-right: 50px;
    
    &.left {
        float: left;
    }

    &.right {
        float: right;
    }
}

.admin-dashboard-datepicker {
    top: 30px !important; 
    right: 5px !important;
}

#userGroups + button {
	float: right;
	width: 155px !important;
}

.dashboard-dialog {
	position: absolute;
	top: 10%;
	left: 10%;
}

.admin-nutrition-dropdown:nth-child(odd) {
    background-color: $surface-light-grey;

    div {
        background-color: $surface-light-grey !important;
    }
    span {
        background-color: $surface-light-grey !important;
    }
}

.coupon-grid-list-container {

    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 10px;

    &.small-list {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
    }
}