// Fix for jquery UI Dialog close buttons 

.ui-dialog-titlebar-close {
    border: 1px solid $charcoal-400;
    border-radius: 2px;

    &:after {
        content: 'x';
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
        color: $charcoal-700;
        display: block;
    }

    &:hover {
        background: $surface-dark;
    }
}

/* Container for modal */
.mb-modal {
    @extend .modal, .fade; // bootstrap4 modal.scss
    z-index: $modalContainerZIndex;

    &.mce-child {
        z-index : 83000;
    }
}

/* Shell to position modal. Used by bootstrap to open and close modals */
.modal-dialog {
    @extend .modal-dialog-centered; // bootstrap4 modal.scss
}

/* Actual modal */
.mb-modal-content {
    @extend .modal-content; // bootstrap4 modal.scss
}

.mb-modal-header {
    @extend .modal-header; // bootstrap4 modal.scss
    background-color: $blue;
    color: $white;
    border-radius: 0;

    .close {
        color: $white;
        @include font-size(24px);
    }
}

.modal-image-close-btn {
    position: absolute;
    top: -25px;
    right: -20px;
    color: $white;
    opacity: 1;

    &:hover {
        color: $white;
    }
}

.mb-modal-title {
    @extend .modal-title; // bootstrap4 modal.scss
    font-weight: 400;
}

.mb-modal-body {
    @extend .modal-body; // bootstrap4 modal.scss
    max-height: calc(100vh * .8);
    overflow-y: auto;
    min-height: 100%;
}

.mb-modal-footer {
    @extend .modal-footer; // bootstrap4 modal.scss
    background-color: $surface-dark;
    border-top: 1px solid $light-gray;
}

body.modal-open {
	overflow: hidden;
}

.modal_autocomplete {
	z-index: 1000000 !important;
}

body.flyout-open.modal-open .modal-backdrop {
    z-index: 70201 !important;
}

.openModalButton {
    float: right;
    background-color: $gray;
    color: $white;
    padding: 6px;
}
.dishlogindataimg {
	height: 150px;
	width: auto;
}

.modal-side-300 {
	-webkit-box-flex: 0 0 300px;      /* OLD - iOS 6-, Safari 3.1-6 */
	-moz-box-flex:  0 0 300px;         /* OLD - Firefox 19- */
	-webkit-flex: 0 0 300px;          /* Chrome */
	-ms-flex: 0 0 300px;              /* IE 10 */
	flex: 0 0 300px;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
	max-width: 300px;
}

.media-body {
    .item-meta {
        display: inline-block;
        white-space: nowrap;

        &:after {
            color: $charcoal-600;
            display: inline-block;
            margin-right: 5px;
            margin-left: 10px;
            font-weight: 300;
            content: " | "
        }
        &:last-child:after {
            display: none;
        }
    }
}

.ui-dialog .item-flyout-wrap .heading {
	display: none !important; /*OG Dialog modal*/
}

#orderHistoryFlyout.flyout-dialog,
#nutrientDialog.flyout-dialog {
	max-width : 60%;
}


/* Item Search Dialog start */
#orderReviewDialog.dialog {
    padding: 0;
}

#mb-is-review-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.summary-data {
	background-color: $surface-dark;
	box-shadow: 0 0.5px 0 0 $charcoal-500;
	overflow: auto;
}

#review-header {
    padding: 16px;
    

    label {
        display: block !important;
        color: $charcoal-600;
        font-size: 12px;
        line-height: 16px;
    }

    p {
        margin: 0;
        padding: 0;	
        font-size: 12px;
        line-height: 16px;
    }

    i {
        position: relative;
        left: -20px;
        color: $charcoal-500; 
    }
}

.mb-is-dialog .col-sm i {
	position: relative;
	left: -20px;
	color: $charcoal-500;
}

#review-items {
    
    thead,
    thead tr {
        background-color: transparent;
        border: none;
    }

    th {
        color: $charcoal-600;
        font-size: 12px;
        line-height: 16px;
        background-color: transparent !important;
        border: none !important;
    }

    tr {
        color: $charcoal-900;
        font-size: 14px;
        line-height: 20px;
    }

    th,
    td {
        padding: 6px 2px !important;
    }

    tbody tr:hover {
        background-color: $surface-darker;
    }
}

#save-order-content {
    max-width: 500px;
    
    p {
        margin: 0;
        padding: 0;
    }
    
    i.fa-chevron-down {
        margin-left: -23px;
    }
}

/* Item Search Dialog end*/

.ui-dialog.ogdialog {
	width: 95% !important;
	height: 85% !important;
	overflow-y: auto;
	margin: auto;
}

#guideItemsDialog {

    .tableHeader {
        font-size: 22px !important;
        line-height: 1.0;
        font-weight: 300;
        margin: 0 0 7px 0 !important;
    }

    .tableHeaderContainer {
        background-color: yellow;
        padding: 5px 20px;
    }

    label {
        font-weight: 800;
    }

}

/* Item Pricing */
#modifyItemPriceOptionsTable,
.modify-item-price-options {
    td {
        width: 50% !important;
        vertical-align: top;
    }
}

.modify-item-price-options {
    input[type="text"] {
        max-width: 80px;
    }

    .row .col:first-child {
        padding-left: 15%;
    }
}

@media only screen and (min-width: 576px) {
    .modal-xl  {
        max-width: 90%;
    }
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px;
    }
    
    .modal-xxl {
        max-width: 85%;
    }
}

@media (max-width: 820px) {
    #orderHistoryFlyout.flyout-dialog,
    #nutrientDialog.flyout-dialog {
		max-width : 100%;
	}
}

/* JQuery Modal */

.mce-container.mce-floatpanel {
    z-index: 83000 !important;
}

.modal-backdrop {
    z-index: $modalBackdropZIndex;

    & ~ .modal-backdrop {
        z-index: 82100;
    }

    &.mce-child {
        z-index: 83000 !important;
    }
}

.mb-modal-backdrop {
    @extend .modal-backdrop;
}

.mb-modal-wrapper {
    .mb-modal-container {
        z-index: $mbModalContainerZIndex !important;
    }

    .mb-modal-backdrop,
    body.flyout-open & .mb-modal-backdrop {
        z-index: $mbModalContainerZIndex!important;
    }
}

/* ------- REACT MIGRATION MODAL ------ */



/* ----------------------------------------------- */

/* Menu Modals */

.review-changes-container {
    padding: 0.25rem;

    .review-item {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        align-items: center;
        border-bottom: solid 1px $charcoal-300;

        button.revert-change i {
            color: $charcoal-600;
        }

        .current-change {
            color: $red-dark-1;
            text-decoration: line-through;
            padding-left: 2rem;
            font-size: 0.85rem;
        }
    }
}

.full-size-image-dialog {
    max-width: max-content;
    padding: 0 2rem;
    margin-right: auto;
    margin-left: auto;
    container: none !important;

    .mb-modal-body-content {
        padding: 0;
        position: relative;
        background: transparent !important;
        border: none;
    }

    .full-size-image {
        width: 100%;
        max-height: inherit;
        padding: 0.5rem;
    }

    .full-size-image-close-button {
        position: absolute; 
        right: 10px; 
        top: 3px; 
        z-index: 2;
    }
}

.orderguide-copy-customer-select .auto-complete-items-container {
    max-height: 250px;
}