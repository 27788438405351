.toggle-component-container {
	overflow: hidden; 

	.no-wrap & {
		@include media-breakpoint-down(md) {
			width: auto !important;
		}
	}
}

.has-toggle-edit,
.toggle-component-container {
	.toggle-input {
		width: 100%;
	}

	.toggle-input-text {
		display: block;
		color: $body-color;
		text-decoration: none;
		min-height: 21px;
		font-size: inherit;
		padding-right: 0;
		padding-left: 0;
		flex: 1;
	}

	.truncate-text-parent {
		white-space: nowrap;
	}
}

.has-toggle-edit {

	.toggle-checkbox-group {

		.toggle-checkbox-read {
			margin: 0.2rem 0;
			padding: 0.2rem;
		}

		label {
			padding-top: 0.3em !important;
		}

		.material-icons {
			font-size: 21px !important;
		}

	}

	.toggle-input-text-container,
	.toggle-checkbox-group  {
		border-bottom: dotted 2px $charcoal-400;
		cursor: pointer;
	}
}

.toggle-input-text-container {
	display: flex;
	align-items: center;
}

.text-right .toggle-input {
	text-align: right;
}

.toggle-input,
.toggle-input-text {
	opacity: 1 !important;

	&.disabled,
	&:disabled {
		color: $charcoal-500;
		cursor: default;
		text-decoration: none;
	}
}

.toggle-component-container {
	&.disabled .toggle-input-text{
		color: $charcoal-500;
		cursor: default;
		text-decoration: none;
	}
}

.has-undo-option {
	display: flex;

	.has-toggle-edit {
		flex: 1;
		width: calc(100% - 36px); // width of undo icon
	}
}

// Styles for Toggle Auto Complete Customer Search
.auto-complete-items-container {
	position: absolute; 
	top: 5px; 
	max-height: 300px; 
	overflow-y: auto;
	z-index: 2;

	&.flipped {
		bottom: 40px;
		top: initial;
	}

	.list-group {
		border-radius: 0;
	}

	.list-group-item {
		
		padding: 0;
		border: none;

		button {
			padding: 0.25rem;
			text-align: left;
			border: none;
			
			&:hover,
			&:focus {
				background-color: $surface-dark;
				outline : 0;
			}
		}
	}
}

.dropdown-menu .dropdown-item:hover {
	background-color: var(--bs-dropdown-link-hover-bg) !important;
}