.mb-item {
    border-top: 1px solid $gray;

    &:last-child {
        border-bottom: 1px solid $gray;
    }

    .mb-item-image {
        max-width: 60px;
    }

    .mb-item-info-wrapper {
        flex: 5 0 350px;
    }

    .mb-item-pricing-wrapper {
        flex: 2 0 200px;
    }

    .mb-item-columns-wrapper {
        flex: 2 0 350px;
        align-items: center;
    }

    .serving-column {
        align-self: center;
        text-align: right;

        @include media-breakpoint-up(lg) {
            width: 120px;
        }

        @include media-breakpoint-down(lg) {
            width: 90px;
        }
    }
}

.brandName {
    color: $charcoal-500;
    @include font-size(14px);
}

.item-search-item-container {
    border-top: 1px solid $gray;
    justify-content: space-between;
    position: relative;

    [class*="col-"]:not(.flag-wrapper) {
        padding: 8px 4px;

        @include media-breakpoint-down(md) {
            padding: 8px;
        }
    }

    &:last-child {
        border-bottom: 1px solid $gray;
    }

    &.item-search-item-container--in-cart {
        background-color: $green-light-5;
    }

    .item-search-item-container__image-block {
        width: 115px;
        margin-right: 0.5rem;
    }

    .item-search-item-container__image {
        height: 115px;
        width: 115px;
        max-height: 115px;
    }

    .d-flex.item-search-item-container__left {

        flex: 2;

        @include media-breakpoint-down(sm) {
            flex: initial;
        }

        .item-search-item-container__image-block {
            width: 96px;
        }

        .item-search-item-container__image {
            width: 96px;
            height: 96px;
            max-height: 96px;
        }

        .flagBlock .itemFlag {
            width: 100%;
            margin: 0;
        }

    }

    .sub-item-image-container {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .text-block__list--inline {
        margin: 0;
    }
    
    .text-block__list--inline > li {
        display: inline;
        padding: 4px 0px;
        white-space: nowrap;

        @include media-breakpoint-down(xs) {
            display: inline-block;
            padding: 0;
            white-space: initial;
        }
    }
    
    .itemsearch-label {
        font-weight: 600;
        @include font-size(14px);
    }
    
    
    .itemName {
        cursor: pointer;
    }
    
    .item-name-text {
        @include font-size(17px);
        font-weight: 500;
    }

    .itemDesc {
        max-height: 62px;
        overflow: hidden;
    }
}

ul.add-to-og-list {
    list-style: none;
    padding: 0;
    text-align: right;
    margin-bottom: 0;
}

.item-search-item-container__right-inner {
    width: 100%;
}

.list-view {
    .item-search-item-container__image-block {
        width: 60px !important;
    }

    .item-search-item-container__image {
        width: 60px !important;
        height: 60px !important;
        max-height: 60px;
    }
    
    .brandName {
        display: inline-block;
        vertical-align: middle;
    }
    
    .item-name-text {
        display: inline-block;
        margin-right: 4px;
    }
    
    .item-search-item-container__right-inner ul.add-to-og-list {
        list-style: none;
        margin: 0;
    }
}