@each $color, $value in $mb-primary-colors {
    
    .bg-#{$color} {
        background-color: $value !important;
    }

    .bg-#{$color}-dark-1 {
        background-color: darken($value, 7.5%) !important;
    }

    .bg-#{$color}-dark-2 {
        background-color: darken($value, 17.5%) !important;
    }

    .bg-#{$color}-dark-3 {
        background-color: darken($value, 29%) !important;
    }

    .bg-#{$color}-dark-4 {
        background-color: darken($value, 32%) !important;
    }
    
    .bg-#{$color}-light-1 {
        background-color: lighten($value, 7.5%) !important;
    }

    .bg-#{$color}-light-2 {
        background-color: lighten($value, 35%) !important;
    }

    .bg-#{$color}-light-3 {
        background-color: lighten($value, 48%) !important;
    }

    .text-#{$color} {
        color: $value !important;
    }

    .border-#{$color} {
        border-color: $value !important;
    }

    .border-#{$color}-dark-1 {
        border-color: darken($value, 7.5%) !important;
    }

    .border-#{$color}-dark-2 {
        border-color: darken($value, 17.5%) !important;
    }

    .border-#{$color}-light-1 {
        border-color: lighten($value, 7.5%) !important;
    }
    
    .border-#{$color}-light-2 {
        border-color: lighten($value, 35%) !important;
    }
}

@each $color, $value in $mb-tertiary-colors {

    .bg-#{$color} {
        background-color: $value !important;
    }

    .bg-#{$color}-dark-1 {
        background-color: darken($value, 7.5%) !important;
    }

    .bg-#{$color}-dark-2 {
        background-color: darken($value, 20%) !important;
    }
    
    .bg-#{$color}-light-1 {
        background-color: lighten($value, 7.5%) !important;
    }

    .bg-#{$color}-light-2 {
        background-color: lighten($value, 30%) !important;
    }

    .bg-#{$color}-light-3 {
        background-color: lighten($value, 41%) !important;
    }
}

@each $color, $value in $mb-charcoal-colors {
    .text-#{$color} {
        color: $value !important;
    }

    .bg-#{$color} {
        background-color: $value !important;
    }
    
    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color, $value in $mb-surface-colors {
    .bg-#{$color} {
        background-color: $value !important;
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}