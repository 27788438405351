/** MB Global Overlay **/

#mbGlobalOverlayContainer {
    position: fixed;
    background-color: $white;
    width: 100%;
    height: 0;
    top: 5000px;
    z-index: 70101 !important;
    @include transition(all 0.5s ease);
    
    &.open {
        top: 50px;
        height: calc(100% - 50px);

        #overlayContentViewContainer {
            height : calc(100% - 56px) !important;
        }
    }

    #overlayHeader {
        display: flex;
        justify-content: space-between;
        color: $white;
        background-color: $charcoal-600;
        padding: 10px;
        line-height: 1.5;
        border-bottom: 1px solid $charcoal-500;
        
        h2 {    
            margin: 0;
            line-height: 1.5;
            font-weight: 600;
        }
    }

    #overlayContentViewContainer:not(.no-overlay-scrolling) {
        height: 0;
        overflow: auto !important;
    }
}

/* ------- REACT MIGRATION OFFCANVAS OVERLAY ------ */

#mbOverlay.mb-overlay-container {
	width: 100%;
    height : calc(100% - 50px) !important;

    .mb-overlay-header {
        background-color: $charcoal-600;
        line-height: 1.5;

        button {
            background-color: $charcoal-600;
            border-color: $charcoal-600;
        }

        h2 {    
            margin: 0;
            line-height: 1.5;
            font-weight: 600;
        }
    }

	+ .mb-modal-backdrop {
		z-index: 82100;
	}

	.overlay-content {
		padding: 1rem 0;
	}

	@include media-breakpoint-down(md) {
		.tabs-navbar {
			top: 0;
		}
	}

	&.mb-overlay-with-header {
		.offcanvas-body {
			padding: 0;
		}
	}
}